import { useState, useEffect } from 'react'
const BlockCart = ({prod,handleAumentarCantidad,methodShopping}) =>{
	const [cantVal, setCantVal] = useState(prod.cantidad_inicial)

    useEffect(() => {
    	setCantVal(prod.cantidad_inicial)
    }, [methodShopping]);


	const handleClickMinius = () => {
	    let value = parseFloat( cantVal );
	    const interval = parseFloat( prod.intervalo );
	    const min = parseFloat( prod.cantidad_inicial );

	    if( (value-interval) < min){
	        value = min;
	    }else{
	        value = value-interval;
	    }

	    setCantVal(value.toFixed(2))
	    handleAumentarCantidad(value.toFixed(2))
	}

	const handleClickPlus = () =>{
	    const interval = parseFloat( prod.intervalo );
	    const maxi = parseFloat( prod.cantidad_maxima );
	    let value = parseFloat( cantVal );

	    if( (maxi > 0) && ( (value+interval) > maxi )  ){
	        value = maxi;
	    }else{
	        value = value+interval;
	    }
	    setCantVal(value.toFixed(2))
	    handleAumentarCantidad(value.toFixed(2))
	}

	return (
		<div className="js-eko-qty">
			{
				parseFloat(cantVal) !== prod.cantidad_inicial ?
				<button type="button" className="eko-minus-bloque js-product-block-cart-minus icon-fallback-text"  onClick={handleClickMinius}>
					<span className="fa fa-minus"></span>
				</button>							
				: 
				<button type="button" className="eko-minus-bloque js-product-block-cart-minus icon-fallback-text no-active"  onClick={handleClickMinius}>
					<span className="fa fa-minus"></span>
				</button>			
			}
			
			<input disabled="" type="text" value={cantVal} className="eko-input-block js-qty__num" readOnly />
			{
				parseFloat(cantVal) !== prod.cantidad_maxima ?
				<button type="button" className="eko-minus-plus js-product-block-cart-plus icon-fallback-text" onClick={handleClickPlus}>
					<span className="fa fa-plus"></span>
				</button>				
				:
				<button type="button" className="eko-minus-plus js-product-block-cart-plus icon-fallback-text no-active" onClick={handleClickPlus}>
					<span className="fa fa-plus"></span>
				</button>

			}
		</div>
	)
}

export default BlockCart