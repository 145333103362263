const Select = ({label,validated,...rest}) =>{

	return (

		<div className="ms-item">
			<label htmlFor={rest.name}>{label}</label>
			{
				validated=="yes" ?
					rest.value == "" ?
					<select className="fm-form-input disabled" {...rest} id={rest.name} ></select>
					:
					<select className="fm-form-input" {...rest} id={rest.name} ></select>
				:
				<select className="fm-form-input" {...rest} id={rest.name} ></select>
			}			
		</div>
	)
}

export default Select;