import FormChangePassword from './FormChangePassword'

const PageCambiarClave = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	return (
		<div>

				<main  className="wrap-content page-interna" style={{ backgroundColor : "rgb(229, 229, 229)"}}>
					<div className="shop-container-register">
						<div id="ms-profile">
						  <div className="ms-section">
						    <div className="ms-tab-profile ms-payment">
						      <div className="ms-lateral-b">
						        <div className="ms-body-menu-profile">
						          <div className="ms-info-menu-selected">
						            <div className="ms-title">Cambiar mi clave</div>
						            <FormChangePassword/>
						          </div>
						        </div>
						      </div>
						    </div>
						  </div>
						</div>
					</div>
				</main>

	    </div>	
	)
}
export default PageCambiarClave;