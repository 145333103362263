import { useState } from 'react'

const MenuItem = ({href,title,ico_class,counter=0,has_submenu=false,list_sub_menu}) =>{
	const [visible, setVisible ] = useState(false)

   const activeMenu = (e) =>{
   	setVisible(visible=>!visible)
   }


	if (has_submenu) {
	   	return (
         <li className="js-has-submenu" onClick={(e)=>activeMenu(e) }>
            <a href="#" className={"item_nav sub-items "+ico_class}>
            {title}
            
            {
            	visible ? 
            		<span className="gb arrow-menu fa-sort-asc"></span>        
            	:
            	<span className="gb arrow-menu fa-sort-desc"></span>        
            }
            </a>
            {
            	visible ? 
	            <ul className="sub_item_nav visible_x">
	            	{
	            		list_sub_menu.map((x,index) => 
	            			<li key={index}>
				                  <a href={x.href} title={x.title}>
				                  {x.title}
				                  </a>
				            </li>            			
	            		)
	            	}                 
	            </ul>
            	:
	            <ul className="sub_item_nav">
	            	{
	            		list_sub_menu.map((x,inde2) => 
	            			<li key={inde2}>
				                  <a href={x.href} title={x.title}>
				                  {x.title}
				                  </a>
				            </li>            			
	            		)
	            	}                 
	            </ul>
            }
         </li>		
	   		)
	}else{

	   	return (
	   		<li>
		   		<a className={"item_nav "+ico_class} href={href} title={title} >
		   			{title}
		   				{		   					
		   					counter >= 0 ?
		   					<span className="gb">
		   					{counter}
		   					</span>
		   					: 
		   					null
		   				}
		   			
		   		</a>
	   		</li>   		
	   		)
	}

}

export default MenuItem;