import FormNoEncontre from './FormNoEncontre'

const Footer = () =>{
	
	const cerrarModalNoEncontre = () =>{
		window.$("#modalNoEncontre").modal("hide");		
	}	
	const handleOpenImg = () =>{
		window.$("#modalNoEncontre").modal("show");		
	}
	return(

        <footer>
        </footer>
	)
}
export default Footer;