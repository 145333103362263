import { useState, useEffect } from 'react'
import HeaderTop from './HeaderTop'
import Menu from './Menu'
import FormFilter from './FormFilter'


const Header = ({user,handleMiproductos,handleSubTotal,expressActive,changeHasExpress,methodShopping,changeMethodShopping,subTotal,miproductos,loadMenu,loadTransversal,transversal,menus,myLazyLoad,list_cart,removeProduct}) =>{
	const [hasDistrito,setHasDistrito] = useState(false)

	const closeModal = () =>{
		window.$("#modalSelectionDistrito, #modalNoPointsFreshclub").modal("hide")
	}

	useEffect(() => {	

	}, []);


return(
	<div>
  <HeaderTop />


  <div className="wrap-body">

    <Menu />

    <main className="wrap-content page-interna">
      <div className="page-content">

      <FormFilter />
        <div className="list-products">
          <h5>Mis Productos</h5>
          <table className="table" id="myFilters">
             <thead>
                <tr>
                   <th className="ms-item-1">
                      <input type="checkbox" id="checkall" className="crm-lead-row" />
                      <label for="checkall">Seleccionar Todo</label>
                   </th>
                   <th className="ms-item-11">SKU</th>
                   <th className="ms-item-2">Costo</th>
                   <th className="ms-item-2">CREADO</th>
                   <th className="ms-item-2">Stock</th>
                   <th className="ms-item-5">Precio Lista</th>
                   <th className="ms-item-6">Descuento</th>
                   <th className="ms-item-7">Venta</th>
                   <th className="ms-item-9">Editar</th>
                   <th className="ms-item-10">Eliminar</th>
                </tr>
             </thead>
             <tbody className="js-list-building">
                <tr id="item_204264">
                   <td className="filterMore ms-item-1 ico-my-buildings ms-icon no-border -chk-active">
                      <a className="js-get-building-detail ms-sc-title" id_building="204264">1 Hotel &amp; Residences</a><button className="ms-show-more-info">Show more</button>
                      <div className="ms-chk"><input type="checkbox" id="chk_204264" name="item" className="crm-lead-row" /><label for="chk_204264"></label></div>
                   </td>
                   <td className="ms-item-11 ms-full"><span>SKU</span>102</td>
                   <td className="ms-item-2 ms-md"><span>COSTO</span>20</td>
                   <td className="ms-item-2 ms-md"><span>CREADO</span>20-10-2023 18:06:07</td>
                   <td className="ms-item-5 ms-md"><span>Stock</span>25</td>
                   <td className="ms-item-5 ms-md"><span>PRECIO</span>25</td>
                   <td className="ms-item-6 ms-md"><span>DESCUENTO</span>1</td>
                   <td className="ms-item-7 ms-md"><span>VENTA</span>24</td>
                   <td className="ms-item-9"><span>Edit</span><a title="Edit" className="ms-edit-mx" id_building="204264"><span>Edit</span></a></td>
                   <td className="ms-item-10 no-border"><span>Delete</span><a href="#" className="ms-delete-mx flex-filter-confirm-delete" data-toggle="modal" data-target="#modal-delete" data-url="/buildings/delete" id_building="204264"><span>Delete</span></a></td>
                </tr>


             </tbody>
          </table>


        </div>


      </div>
    </main>

    
  </div>
      
    </div>
	)

}

export default Header;