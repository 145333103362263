import { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Producto from './Producto'
import useInfinityBuscar from './useInfinityBuscar'

const PageBuscar = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	const buscar= window.search
	const [ordenar,setOrdenar] = useState("default-desc")
	const [itemxhr, more ] = useInfinityBuscar({methodShopping,buscar,ordenar})

	useEffect(() => {
		window.addEventListener("scroll", listenScrollEvent);
	    return () => {
	        window.removeEventListener("scroll", listenScrollEvent);
	    }	
	}, []);

	const changeOrder = (e) =>{
		setOrdenar(e.target.value)
	}

	const listenScrollEvent = () => {
		let scrollHeight = document.body.scrollHeight*0.49;

		if (typeof myLazyLoad !== "undefined") { myLazyLoad.update(); }

		if (window.scrollY > scrollHeight) {
			window.$(".btn-add-more").click();
		}
	};

	return (
		<div>
	        <Header
	        	user = {user}
	        	myLazyLoad = {myLazyLoad}
	        	loadMenu = {loadMenu}
	        	loadTransversal = {loadTransversal}
	        	menus = {menus}
	        	transversal = {transversal}
	        	changeHasExpress = {changeHasExpress}
	        	changeMethodShopping = {changeMethodShopping}
	        	methodShopping = {methodShopping}
	        	expressActive = {expressActive}
		        subTotal = {subTotal}
		        miproductos = {miproductos}
		        list_cart = {myListCart}
		        handleMiproductos = {handleMiproductos}
		        handleSubTotal = {handleSubTotal}
		        removeProduct = {removeProduct}
	        />

			<main  className="wrap-content page-interna">
				<div className="list-product-info-filter">
				    <div className="container container-info-response">
				        <div className="margin-info-filter-left">
				            <div className="info-filter-count info-filter-left js-text-filter-cat">
				                <h3><span></span> Buscando {buscar} </h3>
				            </div>
				        </div>
				        <div className="margin-info-filter-rigth">
				            <div className="info-filter-order info-filter-rigth">
				                <select className="js-sort-products eko-filter-order" onChange={changeOrder}>
				                    <option value="">Ordenar</option>
				                    <option value="name-asc">A - Z</option>
				                    <option value="name-desc">Z - A</option>
				                    <option value="price-asc">Menor precio</option>
				                    <option value="price-desc">Mayor precio</option>
				                </select>
				                <i className="fa fa-angle-down"></i>
				            </div>          
				        </div>
				    </div>
				</div>
				  <div className="container-interna">
				      <div className="section-list-products-search">
				        <div className="container-asxl eko-container-default">
				        {
				        	itemxhr.length > 0 ?
					            <div className="list-section-response-product js-search-product-block">
					            	{
					            		itemxhr.map(
					            			x =>
					            			<Producto key={x.id} 
					            				methodShopping = {methodShopping}
												prod = {x} 
												showImage = {true}
												list_cart = {myListCart} />
						            		)
					            	}
					            </div>
				        	: 
				        	<div className="list-section-response-product js-search-product-block">
				        		<span>No hay Resultados encontrados</span>
				        	</div>

				        }
				          </div>
				      </div>   

			      <button className="btn-add-more" onClick={more} style={{display:"none"}}>
			        cargar  más
			      </button>

				  </div>
			</main>

	        <Footer />

	    </div>
	)

}
export default PageBuscar;