import Header from './Header'
import Footer from './Footer'

const Pagequienessomos = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>
            <Header
                user = {user}
                myLazyLoad = {myLazyLoad}
                loadMenu = {loadMenu}
                loadTransversal = {loadTransversal}
                menus = {menus}
                transversal = {transversal}
                changeHasExpress = {changeHasExpress}
                changeMethodShopping = {changeMethodShopping}
                methodShopping = {methodShopping}
                expressActive = {expressActive}
                subTotal = {subTotal}
                miproductos = {miproductos}
                list_cart = {myListCart}
                handleMiproductos = {handleMiproductos}
                handleSubTotal = {handleSubTotal}
                removeProduct = {removeProduct}
            />
            
            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm">
                                <a className="links-breadcrumb" href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                Quienes somos
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-2">Quienes somos</h2>
                        <p className="color-green">¿Quienes somos?</p>
                        <p>
                            Somos un supermercado online. Funcionamos a través de la modalidad de delivery. Vendemos abarrotes, frutas, verduras, lácteos, carnes, pescados, aves, licores & bebidas, artículos de cuidado personal, artículos de limpieza, panadería & pastelería, accesorios para bebés, productos para mascotas, útiles de librería y bazar. 
                        </p>
                        <hr/>
                        <p className="color-orange"><b>Nuestros productos</b></p>
                        <p>
                            Tenemos más de 7,000 productos en diversas categorías: abarrotes, frutas, verduras, lácteos, carnes, aves, pescados, licores, bebidas, cuidado personal, limpieza, artículos para bebés y mascotas, librería y bazar.
                        </p>
                        <hr/>
                        <p className="color-orange"><b>Por qué somos diferentes de otros supermercados</b></p>
                        <ol type="1">
                            <li>
                                Seleccionamos rigurosamente nuestros insumos para llevar <span className="color-green">la frescura y calidad</span> de ellos hasta la puerta de tu casa. Si no te convencen, te los cambiaremos al día siguiente.
                            </li>
                            <li>
                                Entregamos el 99.1% de los productos solicitados en tus pedidos.
                                <span className="color-green"> Reducimos al máximo el número de faltantes</span> para
                                que compres con la tranquilidad de que todo llegará a tu casa.
                            </li>
                            <li>
                                Contamos con un equipo muy amable en nuestra área de <span className="color-green"> Atención al Cliente</span>,
                                quienes son personas muy atentas que te ayudarán en lo que necesites. Para nosotros,
                                eres una prioridad.
                            </li>
                            <li>
                                Tenemos una web que es fácil, amigable e intuitiva, para que puedas hacer tus pedidos cuando y desde donde quieras
                            </li>
                            <li>
                                Paga a través de la web con tarjeta de crédito o contraentrega con crédito, débito,
                                efectivo o Yape.
                            </li>
                            <li>
                                Pide <span className="color-green"> hasta las 11:45 p.m. y recibe al día siguiente desde las 9:00 a.m.</span> Ampliamos
                                capacidad para llegar hasta tu hogar lo más pronto posible.
                            </li>
                        </ol>
                        <p className="color-orange"><b>Cuál es nuestra historia</b></p>
                        <p>
                            Somos una empresa con cinco años de experiencia, 100% peruana, que nació de la necesidad actual de consumir productos frescos y más saludables en casa sin tener que ir al mercado a conseguirlos.
                        </p>
                        <p>
                            Nuestros clientes llevan una vida muy agitada y es complicado encontrar el tiempo para ir a
                            hacer la compra fresca del día como lo hacían nuestros padres y abuelos. Es por esto que
                            decidimos ofrecer un servicio que combine lo bueno de antaño (la cercanía, calidez y
                            selección del mercado de barrio), con la comodidad y eficiencia que nos permite la
                            tecnología.
                        </p>
                        <p>
                            Nuestros clientes llevan una vida muy agitada y es complicado encontrar el tiempo para ir a hacer la compra fresca del día como lo hacían nuestros padres y abuelos. Es por esto que decidimos ofrecer un servicio que combine lo bueno de antaño (la cercanía, calidez y selección del mercado de barrio), con la comodidad y eficiencia que nos permite la tecnología.
                        </p>
                        <p>
                            Trabajamos día a día para garantizar la frescura de nuestros productos gracias a nuestro servicio de compra diaria. Nuestros expertos profesionales compran, seleccionan y siguen procesos rigurosos establecidos para encontrar la calidad óptima.
                        </p>
                        <p>
                            Qué mejor que poder tener la refri siempre llena con ingredientes y alimentos seleccionados, realmente frescos, que no pasen por tantos intermediarios… de mayorista a minorista, al repartidor, al verdulero, almacenado días hasta su venta y finalmente a su destino final? En ese camino, las frutas, verduras, carne, aves, pescados y mariscos son sometidos a procedimientos que alteran su frescura y sabor natural intenso.
                        </p>
                        <p>
                        Con el paso del tiempo, notamos que nuestros clientes necesitaban hacer su compra completa en un solo lugar, y es por eso que ampliamos nuestra oferta a categorías como Abarrotes, Aseo y Limpieza, Licores y Bebidas, Delicatessen… y seguimos alimentando nuestro surtido con sus pedidos.
                        </p>
                        <p className="color-orange">
                            <b>
                                Te invitamos a probar nuestro servicio y a ser parte de nuestra visión.✨
                            </b>
                        </p>
                    </article>
                </div>
            </main>
            <Footer/>
        </div>
    )
}

export default Pagequienessomos;
