import { useState } from 'react'
import InputEmail from './InputEmail'
import InputPassword from './InputPassword'
import InputDni from './Input/InputDni'
import InputPhone from './Input/InputPhone'
import InputName from './Input/InputName'


const FormRegister = () =>{

	const [value, setValue ] = useState(
	{
		name:"",
		lastname:"",
		email:"",
		clave:"",
		dni:"",
		phone:"",
		check1:false,
		check2:false,
		check3:false
	}
		)

	const handleChangePhone = (e) => {
		value.phone = e;
	}

	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const submit = async(event) =>{
		event.preventDefault();
		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });
		  			
			if ( 
				//["",undefined,"undefined"].includes(window.getCookie("commerce_lead_token")) 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {

			    const dataq = new FormData();
				const datavalue = Array.from(new FormData(event.target))
				//console.log(Object.fromEntries(datav));
			    for(let [key, val] of datavalue) {
			    	dataq.append(key, val );
			    }		    
				const response = await fetch(window.path_service+"/admin/add",{method:'POST',
					body: JSON.stringify(value) })
				const data = await response.json()

				if ( data.hasOwnProperty("status") && data.status ) {
			        //document.cookie='commerce_lead_token='+data.lead_token;
			        localStorage.setItem('token_authlead', data.lead_token );

					if ( typeof fbq === "function" ) {
						window.fbq('track', 'CompleteRegistration');
					}

			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          });         					
					//window.location.href = "/confirmacion?process=finish&checkout=simple&orden="+id_orden;
					window.location.href = "/";
					modaLoading.close();
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 3500
				    }); 
				    modaLoading.close();					
				}
				

			}			
		
	}	

	return (
				<form className="js-form-register" onSubmit={submit}>
					{/*<hr/>*/}
					<div className="form-group-flex">
					<InputName 
						label = "Nombres*"
					    validated="yes"
					    name="name"
					    value = {value.name}
					    classWrap="form-group"
					    
					    onChange = {handleChangeForm}
					    />


					<InputName 
						label = "Apellidos*"
					    validated="yes"
					    name="lastname"
					    value = {value.lastname}
					    classWrap="form-group"
					    
					    onChange = {handleChangeForm}
					    />

					<InputEmail 
						label = "Correo*"
					    validated="yes"
					    name="email"
					    value = {value.email}
					    classWrap="form-group"
					    
					    onChange = {handleChangeForm}
					    />

					<InputPassword 
						label = "Contraseña*"
					    validated="yes"
					    name="clave"
					    value = {value.clave}
					    classWrap="form-group"
					    
					    onChange = {handleChangeForm}
					    />

					<InputDni 
						label = "DNI*"
					    validated="yes"
					    name="dni"
					    value = {value.dni}
					    classWrap="form-group"
					    maxLength="8"
					    onChange = {handleChangeForm}
					    />

					<InputPhone 
						label = "Teléfono*"
					    validated="yes"
					    name="phone"
					    value = {value.phone}
					    classWrap="form-group"
					    onChange = {handleChangePhone}
					    />

			            <div className="form-check">
			                <input className="form-check-input" type="checkbox" id="terminos-1" 
			                onChange={handleChangeForm} name='check1' checked={value.check1}
			                 />
			                <label htmlFor="terminos-1" className="form-check-label check-label">
			                    <a target="_blank" href="/terminos-y-condiciones">Acepto Términos y Condiciones</a>
			                </label>
			            </div>
			            <div className="form-check mt-3">
			                <input className="form-check-input" type="checkbox" id="politicas-1" 
			                onChange={handleChangeForm} name='check2' checked={value.check2}
			                />
			                <label htmlFor="politicas-1" className="form-check-label check-label">
			                    <a target="_blank" href="/politica-de-privacidad">Acepto Política de Privacidad</a>
			                    <span>y</span>
			                    <a target="_blank" href="/politicas-de-cookies">Política de Cookies</a>
			                </label>
			            </div>
			            <div className="form-check mt-3 mb-4">
			                <input className="form-check-input" type="checkbox" id="tratamiento-1"
			                onChange={handleChangeForm} name='check3' checked={value.check3}
			                />
			                <label htmlFor="tratamiento-1" className="form-check-label check-label">
			                    <a target="_blank" href="/politica-de-privacidad#consentimiento">
			                        Acepto tratamiento de datos personales para fines adicionales a los esenciales del servicio
			                    </a>
			                </label>
			            </div>
					</div>

					<div className="form-group-flex">

		                   {
						    (
						    	value.name !== "" && 
							    value.lastname !== "" && 
							    value.phone.length !== "" && 
								value.dni.length === 8 && 
							    value.clave !== "" && 
							    (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value.email) ) &&
							    value.check1 &&
							    value.check2 &&
							    value.check3
							)
		                   	?

		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">COMPLETAR REGISTRO</button>
		                   </div>

		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">COMPLETAR REGISTRO</button>
		                   </div>		                   
		                   }
					</div>



				</form>		
	)
}

export default FormRegister;