const RawPoliticadeprivacidad = () => {
return(
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-5">Política de Privacidad </h2>
                        <ol>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Protección de la información, privacidad y
                                    datos financieros de nuestros clientes</h5>

                                <p>La presente “Política de Privacidad” (en adelante, la “Política”) se aplica a toda
                                    actividad de tratamiento de datos personales realizada por parte de Freshmart S.A.C.
                                    (en adelante, “Freshmart” o “nosotros”). Esta Política tiene por objeto hacer de
                                    conocimiento del público nuestro compromiso con la protección de datos personales,
                                    los lineamientos bajo los cuales realizamos el tratamiento de los mismos en
                                    ejercicio de nuestras actividades comerciales y, la finalidad del tratamiento de
                                    datos personales por parte de nosotros. Asimismo, hacemos de conocimiento los
                                    procedimientos para que los titulares de los datos personales puedan ejercer los
                                    derechos de acceso, rectificación, cancelación y oposición previstos en la Ley 29733
                                    – Ley de Protección de Datos Personales, su Reglamento, aprobado por Decreto Supremo
                                    003-2013-JUS, y sus normas complementarias y modificatorias (todas éstas en adelante
                                    definidas como la “Normativa de Protección de Datos Personales”).
                                </p>
                                <p>
                                    Por favor, lea detalladamente esta Política para entender los tipos de información
                                    que recolectamos de usted, cómo usamos esa información y las circunstancias bajo las
                                    cuales la compartiremos con compañías vinculadas a nosotros, así como a terceros
                                    considerando que: (i) el servidor se encuentra el Extrajero; y, (ii) las cookies
                                    utilizadas son de terceros y recopilan datos personales de los clientes, conforme a
                                    lo descrito en las Políticas de Cookies. Al visitar nuestra página web usted acepta
                                    y consiente las prácticas descritas en esta Política.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Información General</h5>

                                <p>Freshmart es la empresa titular y administradora de todos y cada uno de los derechos
                                    de la Página Web: <a href="https://www.freshmart.pe/">https://www.freshmart.pe/</a>
                                    (en adelante, la “Página Web”).
                                    Freshmart es un supermercado online peruano, que comercializa productos frescos y
                                    saludables. Se encarga de seleccionar rigurosamente y hacer la entrega de los
                                    productos solicitados por los clientes (los “Servicios”).</p>
                                <p>
                                    Como parte de nuestra actividad, Freshmart trata datos personales en cumplimiento
                                    con lo dispuesto en la Normativa de Protección de Datos Personales.
                                </p>

                                <p>
                                    Los datos personales cuyo tratamiento realizamos son almacenados en bancos de datos
                                    personales de titularidad de Freshmart.
                                </p>

                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Marco Normativo</h5>
                                <p>
                                    Comprende las siguientes normas, así como sus modificaciones en el tiempo:
                                </p>

                                <ul>
                                    <li>Constitución Política del Perú.</li>
                                    <li>
                                        Ley de Protección de Datos Personales, aprobada por la Ley N° 29733 (la
                                        <u> “Ley de Protección de Datos Personales”</u>).
                                    </li>
                                    <li>
                                        Reglamento de la Ley Nº 29733, Ley de Protección de Datos Personales, aprobado
                                        por Decreto Supremo N° 003-2013-JUS (el <u> “Reglamento de la Ley de Protección
                                        de Datos Personales”</u>).
                                    </li>
                                    <li>
                                        Directiva de Seguridad de la Información, aprobada por Resolución Directoral N°
                                        019-2013-JUS/DGPDP.
                                    </li>
                                </ul>

                                <p className="mt-4">
                                    De acuerdo con la Ley de Protección de Datos Personales y el Reglamento de la Ley de
                                    Protección de Datos Personales, se entiende por datos personales toda información
                                    sobre una persona natural que la identifica o hace identificable a través de medios
                                    que pueden ser razonablemente utilizados.
                                </p>

                                <p>
                                    Por otro lado, por tratamiento de datos personales se entiende como cualquier
                                    operación o procedimiento técnico, automatizado o no, que permite la recopilación,
                                    registro, organización, almacenamiento, conservación, elaboración, modificación,
                                    extracción, consulta, utilización, bloqueo, supresión, comunicación por
                                    transferencia o por difusión o cualquier otra forma de procesamiento que facilite el
                                    acceso, correlación o interconexión de los datos personales.
                                </p>

                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">
                                    Principios rectores del tratamiento de datos personales
                                </h5>
                                <p>La Política de Freshmart, se rige por los principios rectores establecidos en la Ley
                                    de Protección de Datos Personales y el Reglamento de la Ley de Protección de Datos
                                    Personales, conforme se detallan a continuación:</p>
                                <ul>
                                    <li>
                                        Principio de legalidad: se rechaza la recopilación de los datos personales de
                                        nuestros usuarios por medios fraudulentos, desleales o ilícitos.
                                    </li>
                                    <li>
                                        Principio de consentimiento: en el tratamiento de los datos personales de
                                        nuestros usuarios mediará su consentimiento.
                                    </li>
                                    <li>
                                        Principio de finalidad: Los datos personales de nuestros usuarios se recopilarán
                                        para una finalidad determinada, explícita y lícita, y no se extenderá a otra
                                        finalidad que no haya sido la establecida de manera inequívoca como tal al
                                        momento de su recopilación.
                                    </li>
                                    <li>
                                        Principio de proporcionalidad: todo tratamiento de datos personales de nuestros
                                        usuarios será adecuado, relevante y no excesivo a la finalidad para la que estos
                                        hubiesen sido recopilados.
                                    </li>
                                    <li>
                                        Principio de calidad: Los datos personales serán veraces, exactos y, en lo
                                        posible, actualizados, necesarios, pertinentes y adecuados respecto de la
                                        finalidad para la que fueron recopilados.
                                    </li>
                                    <li>
                                        Principio de seguridad: se conservarán de forma tal que se garantice su
                                        seguridad y solo por el tiempo necesario para cumplir con la finalidad del
                                        tratamiento.
                                    </li>
                                </ul>
                                <p className="mt-4">
                                    Freshmart declara que cuentan con las medidas de seguridad apropiadas y acordes con
                                    el tratamiento que se vaya a efectuar, así como adoptarán las medidas técnicas,
                                    organizativas y legales necesarias para garantizar confidencialidad de los datos
                                    personales.
                                </p>
                            </li>

                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">
                                    Tiempo de conservación de sus datos personales
                                </h5>
                                <p>
                                    Los datos personales recopilados por Freshmart serán conservados exclusivamente por
                                    el tiempo necesario para cumplir con la finalidad para lo cual fueron recopilados,
                                    respetando el Principio de Calidad, establecido en el artículo 8 de la Ley de
                                    Protección de Datos Personales y las demás disposiciones del Reglamento de la Ley de
                                    Protección de Datos Personales.
                                </p>
                                <p>
                                    Freshmart no almacenará los datos personales cuando ocurra cualquiera de los
                                    siguientes eventos:
                                </p>
                                <ul>
                                    <li>
                                        Cuando tenga conocimiento del carácter inexacto o incompleto de los datos
                                        personales recopilados. Sin perjuicio de los derechos del titular al respecto,
                                        Freshmart suprimirá y sustituirá o, en su caso, completará los datos personales
                                        objeto de tratamiento.
                                    </li>
                                    <li>
                                        Cuando los datos personales hayan dejado de ser necesarios o pertinentes para la
                                        finalidad para la cual hubiesen sido recopilados o hubiese vencido el plazo para
                                        su tratamiento, salvo que medie procedimiento de anonimización o disociación.
                                    </li>
                                    <li>
                                        Cuando el titular de los datos personales haya solicitado su cancelación en
                                        cumplimiento con la Ley y normativa.
                                    </li>
                                </ul>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">
                                    Información que podríamos recolectar de nuestros clientes
                                </h5>
                                <ol type="a">
                                    <li>
                                        <p><b>Información proporcionada por nuestros clientes:</b></p>
                                        <p className="mt-3">
                                            Usted podría proporcionarnos información, que nosotros podríamos recolectar
                                            y procesar, cuando llena los formatos en nuestra Página Web y/o interactuar
                                            con nosotros vía correo electrónico o a través de otro medio. Esto incluye,
                                            sin limitarse, a: información proporcionada al solicitar los Servicios a
                                            través de la página web, realizar operaciones utilizando la página web o
                                            reportar algún problema en la Página Web. La información que nos brinda
                                            puede incluir su nombres y apellidos, dirección, número de documentos de
                                            identidad, carné de extranjería, pasaporte o del registro único de
                                            contribuyentes, teléfonos, dirección de correo electrónico, imagen, voz,
                                            firma, firma electrónica y otros documentos requeridos para proveer nuestros
                                            Servicios. Asimismo, la información puede incluir sus datos bancarios o de
                                            tarjetas de crédito, al momento de realizar el pago de nuestros Servicios.
                                        </p>
                                    </li>
                                    <li>
                                        <p><b>Información que recolectamos sobre nuestros clientes:</b></p>
                                        <p className="mt-3">
                                            Con respecto a cada visita que realiza a nuestra Página Web, podríamos
                                            recolectar automáticamente la siguiente información:
                                        </p>
                                        <p>
                                            Ubicación técnica, incluyendo la dirección IP utilizada para conectarse
                                            desde tu dispositivo a nuestra Página Web, la información de ingreso a tu
                                            cuenta de usuario, tipo de navegador y versión, configuración horaria,
                                            plug-ins asociados, sistema operativo, etc.
                                        </p>
                                        <p>
                                            Información sobre su visita, desde nuestra Página Web; lo que se vio y las
                                            búsquedas que se realizaron, tiempos de respuesta de la Página Web, errores
                                            de descarga, información de interacción con la Página Web y métodos usados
                                            para salir de la Página Web, así como cualquier número de teléfono utilizado
                                            para contactar a servicio al cliente.
                                        </p>
                                        <p>
                                            Información que recibimos de otras fuentes: Freshmart puede recibir
                                            información acerca de nuestros clientes si utiliza cualquier otra web
                                            operada por nosotros o asociada a cualquier otro servicio provisto por
                                            nosotros.
                                        </p>
                                    </li>
                                    <li>
                                        <p><b><u>Cookies</u></b></p>
                                        <p className="mt-3">
                                            Nuestra Página Web utiliza cookies para gestionar las solicitudes de los
                                            usuarios, recopila datos y tiene otras finalidades que son descritas en la
                                            Políticas de Cookies. En el siguiente&nbsp;
                                            <a href="https://www.freshmart.pe/page/politicas-de-cookies">enlace</a>
                                            &nbsp;se encontrarán las Políticas de Cookies. Las cookies nos ayudan a
                                            brindarle una buena experiencia al navegar por nuestra Página Web y, al
                                            mismo tiempo, nos permite mejorarla. Esto es una práctica común en todos los
                                            sitios web. Conforme se detalla en las Políticas de Cookies, las Cookies
                                            pueden ser por los clientes.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Usos de la información recolectada</h5>
                                <p>Utilizamos la información de las siguientes formas:</p>
                                <ol type="a">
                                    <li>
                                        <p><b>Información proporcionada por nuestros clientes</b></p>
                                        <p>
                                            Para realizar nuestras obligaciones adquiridas en cualquier contrato entre
                                            nuestro cliente y nosotros (incluyendo los Términos y Condiciones) y
                                            proveerle información, productos y/o servicios que requiera.
                                        </p>
                                        <p>Para notificarle sobre cambios en nuestros Servicios.</p>
                                        <p>
                                            Para asegurarnos que el contenido de la Página Web sea presentado de la
                                            manera más efectiva para usted y su computadora o dispositivo móvil.
                                        </p>
                                        <p>
                                            Para cumplir con todos los requerimientos legales y regulatorios impuestos
                                            por los entes relevantes
                                        </p>
                                    </li>
                                    <li>
                                        <p><b>Información que recolectamos sobre nuestros clientes:</b></p>
                                        <p>
                                            Para administrar nuestra Página Web y para operaciones internas como
                                            solución de problemas operativos, análisis de datos, pruebas, así como fines
                                            estadísticos y de investigación.
                                        </p>
                                        <p>
                                            Para mejorar nuestra Página Web y asegurarnos que su contenido sea
                                            presentado de la manera más efectiva para usted y su computadora o
                                            dispositivo móvil.
                                        </p>
                                        <p>Para asegurar, mantener y mejorar la seguridad de la Página Web.</p>
                                    </li>
                                </ol>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">
                                    Finalidad del tratamiento de los datos personales
                                </h5>
                                <p>
                                    El tratamiento de los datos personales de nuestros clientes o usuarios de la web,
                                    que son recopilados por el envío de los mismos a través de los formularios de la
                                    Página Web, redes sociales, comunicaciones telefónicas se orientan a gestionar las
                                    ventas de nuestros productos, absolver sus consultas, peticiones, reclamos, quejas y
                                    la atención de cualquier otro tipo de información que nos requieran a través de
                                    éstos. Respecto de los trabajadores, la finalidad es única y exclusivamente para la
                                    ejecución del vínculo laboral que mantiene con Freshmart. Del mismo modo, con
                                    relación a los proveedores, la finalidad es única y exclusivamente para la ejecución
                                    del vínculo contractual que mantenga con Freshmart. Por último, los datos personales
                                    recopilados a través del libro de reclamaciones, tendrá como finalidad exclusiva
                                    absolver las quejas y reclamos que pudieran tener los clientes o usuarios de la
                                    Página Web de Freshmart.
                                </p>
                                <p>
                                    Todos los datos, salvo las excepciones previstas anteriormente, que son brindados a
                                    Freshmart son almacenados en los bancos de datos personales de su titularidad y el
                                    tratamiento de los datos tiene por finalidad lo señalado en el párrafo precedente.
                                </p>
                                <p>
                                    Los bancos de datos de Freshmart se encuentran inscritos ante la Autoridad de
                                    Protección de Datos Personales y son los siguientes:
                                </p>
                                <ul>
                                    <li>CLIENTES, con código RNPDP-PJP N°19064.</li>
                                    <li>TRABAJADORES, con código RNPDP-PJP N°19065.</li>
                                    <li>USUARIOS DE LA PÁGINA WEB, con código RNPDP-PJP N°19062.</li>
                                    <li>PROVEEDORES, con código RNPDP-PJP N°19066.</li>
                                    <li>QUEJAS Y RECLAMOS, con código RNPDP-PJP N°19063.</li>
                                </ul>
                                <p>Esta sección se actualizará conforme se modifiquen los bancos de datos.</p>
                                <p>
                                    La dirección del titular de los bancos de datos (Freshmart) es la siguiente: Av.
                                    Nicolás Ayllón N°2625, distrito de El Agustino, provincia y departamento de Lima.
                                </p>
                                <p>
                                    Los datos personales que nos faciliten sólo podrán ser conocidos y manejados por el
                                    personal de Freshmart que necesite conocer dicha información para los fines
                                    descritos anteriormente. Los datos personales recopilados por Freshmart serán
                                    tratados conforme a ley y no serán utilizados para otras finalidades incompatibles
                                    con las establecidas.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">
                                    ¿Compartimos sus datos personales a nivel nacional?
                                </h5>
                                <p>
                                    A nivel nacional, nosotros podríamos compartir su información personal con las
                                    compañías que se detallan a continuación, exclusivamente con la finalidad de cumplir
                                    con el servicio contratado por Usted:
                                </p>
                                <ul>
                                    <li>
                                        <p><u>Empresas Logísticas:</u></p>
                                        <ul>
                                            <li>
                                                Operaciones de Carga SAC, con RUC N° 20550954932, con dirección en Av.
                                                Guanay Sur Mza. F-6 Lote. 288 Urb. Punta Negra Sur, Provincia de Lima;
                                            </li>
                                            <li>
                                                Calper Logística SAC, con RUC N° 20601259070, con dirección en Av. Del
                                                Pinar Nro. 373 Urb. Chacarilla, distrito de San Borja, Provincia de
                                                Lima;
                                            </li>
                                            <li>
                                                99 Minutos Peru SAC, con RUC N° 20606739193, con dirección en Av.
                                                Primavera Nro. 517 Int. 406 Urb. Chacarilla Del Estanque, distrito San
                                                Borja, Provincia de Lima;
                                            </li>
                                            <li>
                                                Maxi Mobility Peru SAC, con RUC N° 20548704261, con domicilio en Calle
                                                Elias Aguirre N° 605 Int. 901 distrito de Miraflores, Provincia de Lima.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><u>Empresas Logísticas:</u></p>
                                        <ul>
                                            <li>
                                                TCI SAC , con RUC N° 20481139075, con domicilio en Av. Huaman Mza. A
                                                Lote. 2 Urb. Los Portales Del Golf La Libertad – Trujillo.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p className="mt-4">
                                    En el caso que se venda o compre cualquier unidad de negocio o activo y sea
                                    necesario revelar su información personal a potenciales compradores o vendedores de
                                    dichas unidades de negocio o activos. En caso Freshmart o todos sus activos son
                                    adquiridos por un tercero, caso en el cual la información personal recolectada sería
                                    uno de los activos a transferir. En un escenario de una fusión, escisión o
                                    reorganización societaria de Freshmart, puede ser necesario que divulguemos datos
                                    personales a prospectos o compradores propiamente dichos y a los asesores de éstos o
                                    que recibamos datos personales que entreguen los vendedores y los asesores de éstos.
                                </p>
                                <p>
                                    Si nos encontramos bajo obligación de revelar o compartir su información personal
                                    para cumplir con cualquier obligación legal o para poder aplicar los Términos y
                                    Condiciones o cualquier documento al cual se haga referencia en estos, o para
                                    proteger los derechos, propiedad y seguridad de Freshmart, nuestros usuarios u
                                    otros. Esto incluye intercambiar información con otras compañías y organizaciones
                                    con objetivos de protección frente a fraudes, investigación relacionada crímenes
                                    financieros o proveer datos para asistir al cumplimiento de la ley.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">¿Freshmart realiza Flujo Transfronterizo?</h5>
                                <p>
                                    Los datos personales recopilados por Freshmart son transferidos con empresas del
                                    extranjero, conforme se detalla a continuación:
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <u>Respecto a Clientes:</u> Se comparten los datos con las siguientes
                                            empresas dedicadas a la publicidad o marketing, prestaciones de servicios de
                                            alojamiento web, empresas que brindan soluciones integrales a las páginas
                                            web (crean los usuarios de los clientes). Para compartir sus datos con fines
                                            distintos a los esenciales del negocio de Freshmart, se requiere el
                                            consentimiento expreso distinto a la contratación del servicio.
                                        </p>
                                        <ul>
                                            <li>
                                                <p>Estados Unidos de América</p>
                                                <ul>
                                                    <li>Digital Ocean LLC, Registro Tributario: 384017666</li>
                                                    <li>Sumo Group Inc., Registro Tributario: 27-4152712</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <p>India</p>
                                                <p>
                                                    Tagnpin Web Solutions LLP (invitereferrals), Código de Registro:
                                                    07AAHFT5463H1ZX.
                                                </p>
                                            </li>
                                            <li>
                                                <p>Malta</p>
                                                <p>
                                                    Hotjar Ltd, Registro Tributario: 996834623
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><u>Respecto a Proveedores:</u> Se comparten los datos con la siguiente
                                            empresa que brinda servicios de alojamiento web.</p>
                                        <ul>
                                            <li>
                                                <p>Estados Unidos de América</p>
                                                <p>Digital Ocean LLC, con Registro Tributario: 384017666</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><u>Respecto a Quejas y Reclamos:</u> Se comparten los datos con la siguiente
                                            empresa que brinda servicios de alojamiento web.</p>
                                        <ul>
                                            <li>
                                                <p>Estados Unidos de América</p>
                                                <p>Digital Ocean LLC, con Registro Tributario: 384017666</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><u>Respecto a Trabajadores:</u> Se comparten los datos con la siguiente
                                            empresa que brinda servicios de alojamiento web.</p>
                                        <ul>
                                            <li>
                                                <p>Estados Unidos de América</p>
                                                <p>Digital Ocean LLC, con Registro Tributario: 384017666</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><u>Respecto a los Usuarios de la Web:</u> Se comparten los datos con las
                                            siguientes empresas dedicadas a la publicidad o marketing, prestaciones de
                                            servicios de alojamiento web, empresas que brindan soluciones integrales a
                                            las páginas web (crean los usuarios de los clientes). Para compartir sus
                                            datos con fines distintos a los esenciales del negocio de Freshmart, se
                                            requiere el consentimiento expreso distinto a la contratación del servicio.
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Estados Unidos de América
                                                    <ul>
                                                        <li>Digital Ocean LLC, con Registro Tributario: 384017666</li>
                                                        <li>Sumo Group Inc., Registro Tributario: 27-4152712</li>
                                                    </ul>
                                                </p>
                                            </li>
                                            <li>
                                                <p>India</p>
                                                <p>Tagnpin Web Solutions LLP (invitereferrals), Código de Registro:
                                                    07AAHFT5463H1ZX.</p>
                                            </li>
                                            <li>
                                                <p>Malta</p>
                                                <p>Hotjar Ltd, Registro Tributario: 996834623</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">
                                    ¿Dónde almacenamos la información personal de nuestros clientes?
                                </h5>
                                <p>
                                    Los datos personales son almacenados en los servidores contratados por Freshmart.
                                    Dichos servidores son de la compañía estadounidense DigitalOcean LLC, que se
                                    encuentra alojado en 101 Avenue of the Americas, Piso 10, Nueva York, NY 100013,
                                    Estados Unidos de Norteamérica.
                                </p>
                                <p>
                                    Al enviar su información personal usted acepta estar de acuerdo con esta
                                    transferencia y almacenamiento. Nosotros tomaremos todas las medidas necesarias para
                                    asegurarnos que su información sea tratada de forma segura y de acuerdo con la
                                    Política de Privacidad.
                                </p>
                                <p>
                                    No obstante, debe tener en cuenta que la transmisión de información a través de
                                    internet no es completamente segura. A pesar de que haremos el mayor esfuerzo
                                    posible para proteger su información, no podemos garantizar la seguridad de la
                                    información transmitida a nuestra Página Web; cualquier transmisión es bajo su
                                    propio riesgo. Una vez recibida la información, utilizamos procedimientos estrictos
                                    y procesos de seguridad para evitar cualquier acceso a esta sin autorización.
                                </p>
                                <p>
                                    Nosotros restringimos el acceso a su información personal a empleados de Freshmart
                                    que tengan necesidad de conocerla por razones asociadas a los servicios brindados.
                                    Continuamente capacitamos a nuestros trabajadores sobre la importancia de la
                                    confidencialidad y privacidad de la información de los usuarios. Mantenemos procesos
                                    que cumplen con los requerimientos legales para proteger su información personal de
                                    cualquier acceso no autorizado.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Derechos de nuestros clientes</h5>
                                <p>
                                    De acuerdo con la Normativa de Protección de Datos Personales, nuestros clientes
                                    podrán revocar su consentimiento o ejercer sus derechos como titular de datos
                                    personales. Para este fin, usted deberá presentar una solicitud y/o consulta al
                                    siguiente correo: <a href="mailto:general@freshmart.pe">general@freshmart.pe</a>
                                </p>
                                <p>
                                    Nuestra Página Web podría contener links a otras páginas web de empresas aliadas,
                                    afiliadas o publicitarias. Si usted sigue un link hacia cualquiera de estas páginas,
                                    por favor tenga en cuenta que éstas tienen sus propias políticas de privacidad y que
                                    nosotros no aceptamos ninguna responsabilidad por ellas. Se recomienda revisar
                                    dichas políticas antes de enviar información a cualquiera de esas páginas.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Acceso a la información</h5>
                                <p>
                                    En su condición de usuario, usted tiene el derecho a acceder a la información
                                    relativa a sus datos personales que Freshmart tenga sobre usted, así como a todas
                                    las condiciones y generalidades del tratamiento de los mismos. Su derecho al acceso
                                    podrás ser ejecutado de acuerdo a la Normativa de Protección de Datos Personales.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Selección de personal</h5>
                                <p>
                                    Los datos personales que puedan ser enviados por aquellas personas interesadas, a
                                    través del formulario indicado en la sección de “Contacto/Trabaja con
                                    nosotros/RRHH”, serán tratados por nosotros mismos. Por lo que se respetará todo lo
                                    dispuesto en la presente Política.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Cambios a la Política de Privacidad</h5>
                                <p>
                                    Freshmart podrá modificar esta Política de Privacidad si así lo considera necesario.
                                    Cualquier cambio que se realice a dicha política en el futuro será publicado en esta
                                    Página Web y notificada a usted vía correo electrónico, de ser apropiado. Se
                                    recomienda revisar frecuentemente esta página para ver cualquier actualización o
                                    cambios a nuestra política de privacidad.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Legislación aplicable y Jurisdicción</h5>
                                <p>
                                    Esta política de privacidad y cualquier disputa o reclamo que surjan como
                                    consecuencia de la misma se encuentra normada por la ley peruana.
                                </p>
                            </li>
                            <li className="color-green-li">
                                <h5 className="color-green fw-bolder">Contacto</h5>
                                <p>
                                    Preguntas, solicitudes y comentarios asociados a esta política de privacidad son
                                    bienvenidos y deberán ser dirigidos al siguiente correo:
                                    <a href="mailto:general@freshmart.pe">general@freshmart.pe</a>
                                </p>
                            </li>
                        </ol>

                        <h5 className="color-green fw-bolder text-center mt-5 mb-5">
                            CONSENTIMIENTO PARA OTROS FINES
                        </h5>
                        <p>Tratamiento de Datos para fines publicitarios</p>
                        <p>
                            Los usuarios/clientes otorgan su consentimiento y reconocen que FRESHMART podrá tratar sus
                            datos personales con fines de publicidad y prospección comercial. Toda información que el
                            usuario/cliente brinde podrá usarse para enviarle mensajes personalizados, por ejemplo, en
                            correo electrónico, texto y otros. Estos mensajes pueden contener información sobre otros
                            productos y servicios de FRESHMART tales como consejos y ofertas comerciales. Usted puede
                            cancelar la suscripción a estos mensajes en cualquier momento, por ejemplo, siguiendo la
                            instrucción del mensaje que reciba después de poner en uso su dispositivo, o las
                            instrucciones del dispositivo o de los mensajes, mediante las herramientas de gestión de los
                            perfiles que tenga a su disposición o comunicándose con la atención al cliente FRESHMART, al
                            siguiente correo electrónico:
                            <a href="mailto:atencionalcliente@freshmart.pe">atencionalcliente@freshmart.pe</a>.
                            La información recopilada puede usarse además para mostrar contenido personalizado al igual
                            que para mejorar los productos y servicios de FRESHMART. La información anterior puede
                            aplicarse también en caso de que usted actualice el software de su dispositivo.
                        </p>
                        <p>
                            Asimismo, otros usos previstos para los datos personales recopilados de los usuarios son:
                            análisis de los perfiles de los clientes y fines estadísticos.
                        </p>
                        <p>
                            Salvo por lo descrito en esta sección, el tratamiento de los datos personales recopilados
                            para estos fines, se realizará conforme a lo descrito en la Política de Privacidad que
                            podrás encontrar en el siguiente enlace:&nbsp;
                            <a href="https://www.freshmart.pe/page/politica-de-privacidad">
                                https://www.freshmart.pe/page/politica-de-privacidad
                            </a>
                        </p>
                    </article>
                </div>
    )
}
export default RawPoliticadeprivacidad;