import { useState,useEffect } from 'react'
import Input from '../Input'
import InputPrice from '../Input/InputPrice'
import Select from '../Select'
import Autocomplete from '../Form/Autocomplete'
import MapaLeaflet from '../MapaLeaflet'
import DireccionFiscal from '../DireccionFiscal'
import AutoCompleteProducts from '../AutoComplete'
import CambioMoneda from '../Form/CambioMoneda'
import InputFile from '../Input/InputFile'

const Pedido = ({type_page}) =>{

    //const [pasarelas, setPasarelas] = useState([])
    const [subTotal, setSubTotal] = useState("0.00")
    const [itemshopping, setItemshopping] = useState([])
    const [resumen, setResumen] = useState([])
    const [methodShopping, setMethodShopping] = useState("schedule")
    const [metodoPago, setMetodoPago] = useState("")
	
	let xhrgallery = null;
	let modaLoading;
	const [constanciatransferencia, setConstanciatransferencia] = useState("")   

	const [addressFiscal, setAddressFiscal] = useState(false)


    const [despacho, setDespacho] = useState({
    	price:0,
    	fecha: new Date().toLocaleDateString("fr-CA")
    })

	const [typepayment, setTypepayment] = useState("")

	const [vieworder,setVieworder] = useState("userinfo")
	
	const [value, setValue ] = useState( 
		{
			user:null
		}
	)

	const [currenmoneda, setCurrenmoneda ] = useState({
		envio:1000,
		recibo:0,
		rate : {label:"compra",value: 0 }
	})	

console.log(currenmoneda)

	const showModalDeposito = (item) => {
		setMetodoPago("manual")
		window.$("#modalConfrmpay").modal("show")
	}

	useEffect(() => {
			window.$("body").addClass("page-orders");	
	}, []);


	const handleChangeFile = (e,keyname = null) => {
		if (e === null) {
			setConstanciatransferencia("")
			/*
			setConstanciatransferencia((state) => ({
		      ...state,
		      [keyname]: ""
		    }))	*/

		}else{
			setConstanciatransferencia(e.target.files[0])
			/*
			setValue((state) => ({
		      ...state,
		      [e.target.name]: 
		    }))		
		    */	
		}

	}

	const nextPage = (view) =>{
		if ( ["userinfo","carrito","despacho","payment","confirmacion"].includes(view) ) {
			
			if (view === "userinfo"  ) {
				setVieworder(view)
			}

			
			if (view === "carrito" && value.user != null ) {
				setVieworder(view)
			}

			if (view === "despacho" && value.user != null && currenmoneda.envio > 0) {
				setVieworder(view)
			}

			 if (view === "payment" && currenmoneda.envio > 0) {
			 	setVieworder(view)
			}


			 if (view === "confirmacion" && currenmoneda.envio > 0) {
			 	setVieworder(view)
			}
			
		}
		
	}

	const canjearCupon = async() =>{
	  if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
	  	localStorage.cupon = document.getElementsByClassName("js-input-cupon")[0].value;
	  	localStorage.tempcupon = "1";
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'Añadiendo cupón...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});
	    //myListCart();
	  }
	}

	const QuitarCupon = async() =>{
	  if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
	  	localStorage.removeItem("cupon");
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'Quitando cupón...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});
	    //myListCart();
	  }
	}
	
/*
    const handleSubTotal = (data) =>{
        setSubTotal(data)
    }
*/

	const handleDocfactura = () =>{
		setAddressFiscal(document.getElementById("docfactura").checked)
		if (document.getElementById("docfactura").checked === false) {
			localStorage.removeItem("facturacion");
		}
	}


	const changeAddressFiscalDefaul = (item) => {
		localStorage.facturacion = JSON.stringify(item)
	}

	const cerrarModalPayu = () =>{
		window.$("#modalCheckoutPayu, #modalMisTarjetas, #modalConfrmpay").modal("hide");
	}

	const pagarButton = () => {
		pagar(metodoPago)
	}


	const [search, setSearch ] = useState( 
		{
			clientname: ""
		} 
	)


    const myListCart = async () =>{
        /*
        */
		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
			var myHeaders = new Headers();
		    myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
		    myHeaders.append("Content-Type", "application/json");            
		    
	        const response = await fetch(window.path_service+"/api/list/cart", { method:'POST',body:  JSON.stringify(
		    {
			    "envio": currenmoneda.envio,
			    "recibo": currenmoneda.recibo,
			    "rate": currenmoneda.rate,
			    "fecha_despacho": despacho.fecha,
			    "precio_envio": despacho.price
		    }
	        ) , headers: myHeaders})
	        const data = await response.json()

	        let message_status_cupon = "error";
	        if (data.status && data.hasOwnProperty("tiene_cupon") && data.tiene_cupon ) {
	            message_status_cupon = "success";
	        }else{
	            localStorage.removeItem("cupon");
	            if (document.getElementsByClassName("js-input-cupon").length > 0) {
	                document.getElementsByClassName("js-input-cupon")[0].value = ""
	            }
	        }

	        if (![undefined,"undefined",""].includes(localStorage.tempcupon)) {

	            window.Swal.fire(
	                {
	                    icon: message_status_cupon,
	                    title: data.info_cupon.message_coupon,
	                    showConfirmButton: false,
	                    timer: 4500
	                }
	            );

	            localStorage.removeItem("tempcupon");
	        }

	        //handleSubTotal(data.sub_total_pay.toFixed(2))

	        var is_active_discount = false;
	        var mount_discount = 0;          
	        var totalPay = 0;
	        var subtotal = 0;
	        var is_active_shipping = false;
	        var is_next_step = true;
	        var shipping_price=0;
	        var txt_cupon= "";
	        var nombre_cupon = "";
	        var sub_total_pay = 0;

	        

	        if ( data.hasOwnProperty("data") ) {

	            var content_shopping = [];
	            

	            totalPay = data.total;
	            subtotal = data.sub_total_pay;            
	            sub_total_pay = data.sub_total_pay;


	            if (data.free_shipping) {
	                shipping_price = 0;
	            }

	            totalPay = sub_total_pay + despacho.price - mount_discount;

	            if (totalPay<35) {
	                is_next_step = false;
	            }

	            var contentData_resument = {
	                items:data.data,
	                tiene_cupon: data.tiene_cupon,
	                infocupon: data.info_cupon,
	                item_shopping:content_shopping,
	                activedescuentoCupon: is_active_discount,
	                mountdescuento: mount_discount.toFixed(2),
	                textocupon: txt_cupon,
	                free_shipping: data.free_shipping,
	                type_free_shipping: data.type_free_shipping,
	                shipping_price: shipping_price,
	                nombrecupon: nombre_cupon,
	                total_descuentos_productos: data.total_con_descuentos,
	                totalPrice: totalPay.toFixed(2),
	                subtotal:subtotal.toFixed(2),
	                nextstep:is_next_step
	            };

	            console.log(data)


	            if (![undefined,"undefined",""].includes(localStorage.paymnet_shipping)) {
	                var paymnet_shipping = JSON.parse( localStorage.paymnet_shipping );
	                paymnet_shipping.price = shipping_price;
	                localStorage.paymnet_shipping = JSON.stringify(paymnet_shipping);
	            }


	            setItemshopping(content_shopping)
	            setResumen(contentData_resument)
	        }

	        if( (typeof window.modaLoadingcart) === "object" ){
	            window.modaLoadingcart.close();
	        }

        }


        


        


/*
        setTimeout(function(){
            if (typeof myLazyLoad !== "undefined") { myLazyLoad.update(); }
        }, 1500);   
*/
        return {"success":true}
    }


	const handleLocation = (item) =>{

	}	

	const checkoutSet = (item,xcard = null) => {
	  setMetodoPago(item)
	  if (["niubiz","payu","payu-card","manual"].includes(item)) {
	  		pagar(item,xcard)
	  }
	}


	const pagar = async(item,xcard = null) =>{
	  //let is_free = 0;

	  modaLoading = 
	  window.Swal.fire({
	  		imageUrl: window.path_service+'/images/loading.png',
	  		title: 'Cargando datos...',
	      showConfirmButton: false,
	      customClass: 'ms-loading-modal',
	      allowOutsideClick: false
	  });

	  	/*
		let obj_shop_checkout = {
			id_orden:0,
			direccion:{},
			has_invoice:0,
			facturacion:{},
			has_complement:0,
			complement:{},
			shipping: {
				"date":"",
				"horario":"",
				"dt":"",
				"id":0,
				"price":0
			}
		};	  

	  	if (![undefined,"undefined",""].includes(localStorage.paymnet_shipping)) {
	        var paymnet_shipping = JSON.parse( localStorage.paymnet_shipping );
	        obj_shop_checkout.shipping = paymnet_shipping
	    }

	  	if (![undefined,"undefined",""].includes(localStorage.facturacion)) {
	        var facturacion = JSON.parse( localStorage.facturacion );
	        obj_shop_checkout.facturacion = facturacion
	        obj_shop_checkout.has_invoice =1;
	    }

	  	if (![undefined,"undefined",""].includes(localStorage.address)) {
	        var address = JSON.parse( localStorage.address );
	        obj_shop_checkout.direccion = address
	    }      
	    */

		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

		   /*
		   const dataq = new FormData();
		   dataq.append("type", item );
		   dataq.append("device", "desk" );
		   var reducirBolsa = "no";
		   if (![undefined,"undefined",""].includes(localStorage.reducirBolsa)) {
		    reducirBolsa = localStorage.reducirBolsa;
		   }

		   var moreComment = "";
		   if (![undefined,"undefined",""].includes(localStorage.moreComment)) {
		    moreComment = localStorage.moreComment;
		   }
		    dataq.append("type_order", localStorage.methodshopping );
		    dataq.append("reducirBolsa", reducirBolsa );
		    dataq.append("mas_comentarios", moreComment );

		    dataq.append("info_checkout",JSON.stringify( obj_shop_checkout) );
		    */

		    setTypepayment(item)

			const response_service = await fetch(window.path_service+"/orders/add", { method:'POST',dataType: "json",body:  
			JSON.stringify({
				"user_id" : value.user.id,
				"datos_complementarios" : "",
				"type_payment": item,
				"shipping" : despacho,
				"facturacion" : "",
				"user": value.user,
				"fecha_despacho": despacho.fecha,
				"productos": currenmoneda,
				"total" : resumen.totalPrice,
				"cupon" : localStorage.cupon
			})
				 , headers: myHeaders })
		   	const response = await response_service.json()
		   if (response.status) {
		   		
				window.payment = {
					numero_pedido: response.pedido_id,
					total_pedido_con_descuentos_productos : resumen.totalPrice,
					fecha_hora_envio :despacho.fecha,
					envio: despacho.price,
					total	:resumen.totalPrice
				}

		   		if (metodoPago === "transferencia") {
			   		if ( typeof constanciatransferencia !== 'object') {
			   			window.Swal.fire({
					        icon: 'success',
					        title: response.message,
					        showConfirmButton: false,
					        timer: 3500
					   });         					
					}
					uploadFile(response.operacion);

		   		}else{
		   			modaLoading.close();
		   			nextPage("confirmacion")
		   		}
		   				   		
		   		//modaLoading.close();
		   		//nextPage("confirmacion")
		   }
      }

	}

	const getinfoPay = (item) => {
		console.log("item de pago", item )
		currenmoneda.rate =item.rate
		currenmoneda.envio =item.envio
		currenmoneda.recibo =item.recibo
		setSubTotal(item.sub_total.toFixed(2))

		let totalPay = item.total;
	    let subtotal = item.sub_total;            
	    let sub_total_pay = item.sub_total;
	    let shipping_price = 0;
	    let mount_discount = 0;
	    let nombre_cupon = "";
	    let free_shipping = false;
	    let is_next_step = true;

	    if (free_shipping) {
	    	shipping_price = 0;
	    }

	    totalPay = sub_total_pay + shipping_price - mount_discount;

	    if (totalPay<35) {
	    	is_next_step = false;
	    }

		var contentData_resument = {
			tiene_cupon: "",
	        infocupon: [],
	        activedescuentoCupon: "0",
	        mountdescuento: mount_discount.toFixed(2),
	        textocupon: "",
	        free_shipping: free_shipping,
	        shipping_price: shipping_price.toFixed(2),
	        nombrecupon: nombre_cupon,	        
	        total_descuentos_productos: item.total_descuento,
	        totalPrice: totalPay.toFixed(2),	        
	        subtotal:subtotal.toFixed(2),
	        nextstep:is_next_step
	    };
	    setResumen(contentData_resument)
	
	

		setCurrenmoneda(item)
		//myListCart()
		//nextPage("despacho")
	}

	const getinforuser = (item) => {
		setValue((state) => ({
	      ...state,
	      ["user"]: item
	    }))		
	    setSearch((state) => ({
	      ...state,
	      ["clientname"]: item.name+" "+item.lastname
	    }))	    
	}

	const handleChangeDespacho = (e) => {
	    if (e.target.name === "fecha")  {
	    	despacho.fecha = e.target.value
	    }

	    if (e.target.name === "price")  {
	    	despacho.price = e.target.value
	    }

		setDespacho((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))

	    myListCart()
	}

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}

	}, []);


	const uploadFile = async(idfile) =>{
//		uplloadGallery = null;
	    //var uplloadGallery = setInterval(function () {
	        if (constanciatransferencia !== null && constanciatransferencia !== '' && typeof constanciatransferencia === 'object') {
	            var formdata = new FormData();
	            formdata.append("type", constanciatransferencia );
	            formdata.append("id_file", idfile );
	            formdata.append("constancia", 'pago');

		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

	            if (xhrgallery == null) {
	                xhrgallery = window.$.ajax({
	                    url: window.path_service+"/orders/upload_file",
	                    type: "POST",
	                    async: true,
	                    processData: false,
	                    contentType: false,
	                    data: formdata,
	                    dataType: "json",
	                    headers : {"Authorization": "Bearer "+ localStorage.token_authlead  }, 
	                    success: function (response1) {
	                        if ( response1.hasOwnProperty("status") ) {
	                        	if (response1.status) {
	                            	setConstanciatransferencia(response1.path)
	                            	nextPage("confirmacion")	                            	
	                        	}
								
								window.Swal.fire({
						            icon: response1.status?'success':'error',
						            title: response1.message,
						            showConfirmButton: false,
						            timer: 3500
						        });  	                            

	                        }
	                        modaLoading.close();		
	                    }
	                });
	            }else{
	            	modaLoading.close();		
	            }
	        }
	        
	        
	}

	return(
		<div>

			  <div className="container-interna ms-payment ms-carrito">

			  	<div className="ms-section">
			          
					<div className="ms-payment-steps">
					      <ul className="ms-content-steps">
					        <li className= {
					        	"userinfo" !== vieworder ? "item-step check" : "item-step active"
					        }>
					          <div className="head-step">
					            <div className="icon-step" onClick={() => nextPage("userinfo")}><i className="fa fa-user"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Usuario</span>
					          </div>
					        </li>					      
					        <li className={
					        	"carrito" === vieworder ? 
					        	"item-step active" 
					        	: 
					        	["despacho","payment","confirmacion"].includes(vieworder) ? 
					        	"item-step check"
					        	:
					        	"item-step"
					        }>
					          <div className="head-step">
					            <div className="icon-step" onClick={() => nextPage("carrito")}><i className="fa fa-shopping-cart"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Mi Carrito</span>
					          </div>
					        </li>
					        <li className={
					        	"despacho" === vieworder ? 
					        	"item-step active" 
					        	: 
					        	["payment","confirmacion"].includes(vieworder) ? 
					        	"item-step check"
					        	:
					        	"item-step"
					        }>
					          <div className="head-step">
					            <div className="icon-step" onClick={() => nextPage("despacho")}><i className="fa fa-user"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Despacho</span>
					          </div>
					        </li>
					        <li className={
					        	"payment" === vieworder ? 
					        	"item-step active" 
					        	: 
					        	["confirmacion"].includes(vieworder) ? 
					        	"item-step check"
					        	:
					        	"item-step"
					        }>
					          <div className="head-step">
					            <div className="icon-step" onClick={() => nextPage("payment")}><i className="fa fa-credit-card"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Metodo de pago</span>
					          </div>
					        </li>
					        <li className={
					        	"confirmacion" === vieworder ? 
					        	"item-step check" 
					        	:
					        	"item-step"
					        }>
					          <div className="head-step">
					            <div className="icon-step" onClick={() => nextPage("confirmacion")}><i className="fa fa-file-text-o"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Confirmación</span>
					          </div>
					        </li>
					      </ul>
					</div>

					{
						vieworder === "userinfo" ?

							<div className="ms-wizard-wrapper">

								    <div className={value.user !== null ? "ms-lateral-a" : "ms-lateral-a hide" }>
								        <div className="ms-wizard-item">
								          
								          	<div className="ms-body-wizard info-user-orders">

										        {
										        value.user !== null ?
										        <Input 
										        disabled={true}
										        label="Dni de cliente"
										        value={value.user.dni}
										        />						        	
										        	:
										        	null
										        }

										        {
										        value.user !== null ?
										        <Input 
										        disabled={true}
										        label="Nombre de cliente"
										        value={value.user.name}
										        />						        	
										        	:
										        	null
										        }

										        {
										        value.user !== null ?
										        <Input 
										        disabled={true}
										        label="Apellido de cliente"
										        value={value.user.lastname}
										        />						        	
										        	:
										        	null
										        }

										        {
										        value.user !== null ?
										        <Input 
										        disabled={true}
										        label="Telefono de cliente"
										        value={value.user.phone}
										        />						        	
										        	:
										        	null
										        }						        
										       
										       
										        {
										        value.user !== null ?
										        <Input 
										        disabled={true}
										        label="Dirección de cliente"
										        value={
											        	!["",undefined,"undefined","null",null].includes( value.user.address ) ?
											        		value.user.address
											        	:
											        	null						        		
										        	}
										        
										        />						        	
										        	:
										        	null
										        }

										        {
										        value.user !== null && 
										        !["",undefined,"undefined","null",null].includes( value.user.lat )  && 
										        !["",undefined,"undefined","null",null].includes( value.user.lng )   
										       ?
									                 <div className="ms-item ms-full">              
									                 <label htmlFor="mapGoogle">Ubición de la dirección por mapa.</label>
									                   <MapaLeaflet
									                   handleLocation ={handleLocation }
									                   vlat={value.user.lat}
									                   vlng={value.user.lng}
									                   showbutonLookup = "no"
									                   vmarker={"yes"}
									                    />
									                    </div>

										        	:
									                 <div className="ms-item ms-full" style={{display:"none"}}>              
									                   <label htmlFor="mapGoogle">Ubición de la dirección por mapa.</label>
									                   <MapaLeaflet
									                   showbutonLookup = "no"
									                    />
									                    </div>
										        }

								          	</div>
								       	</div>
								    </div>
								    


							    <div className="ms-lateral-b">
								    <div className="ms-wizard-item">
								    	<div className="ms-wrap-item">
								    		
								    		<span className="ms-title">Buscar Cliente</span>
									            <div className="fm-form-user">
									              <Autocomplete 
											        validated="yes"
											        name="clientname"
											        classWrap = "ms-item form-autocomplete"
											        value = {search.clientname}
											        getinforuser = {getinforuser}
											      />
									            </div>	
									            {
									            	value.user !== null && value.user.hasOwnProperty("dni") ?
										            <div className="ms-item-form js-block-cupon">
										            	<button className="ms-btn green js-btn-next-payment" onClick={()=>nextPage("carrito")}>Continuar</button>
										            </div>
									            	:
									            	null
									            }
									    </div>
								    </div>
								</div>




							</div>
						:
						null
					}



					{
						["carrito","despacho"].includes(vieworder) ?						

						<div className="ms-wizard-wrapper">
					{
						vieworder === "carrito" ?						
						    <div className="ms-lateral-a">

						        <div className="ms-wizard-item">


						        <div className="ms-header-wizard">
						        	<CambioMoneda getinfoPay={getinfoPay} myListCart={myListCart} nextPage={nextPage} fechadespacho={despacho.fecha} pricedespacho={despacho.price} currenmoneda={currenmoneda} />
						          </div>
						        </div>
						      </div>
						    :
						    null
						}						      


					{
						vieworder === "despacho" ?						
						    <div className="ms-lateral-a">

					            <div className="ms-wizard-item js-shopping-defined-invoice">
					               <h3 className="ms-form-title">
					                  <i className="fa fa-file-text" aria-hidden="true"></i> Facturación
					               </h3>
					               <div className="ms-form-description">
					                  <p>Activa esta opción para solicitar factura.</p>
					               </div>
					               <div className="ms-checbox-swipe">
					                  <input type="checkbox" name="swipe" id="docfactura" className="showhide js-checkout-factura" data-id="registerFactura" onClick={handleDocfactura} />
					                  <label htmlFor="docfactura">Solicitar Factura
					                  <span>
						                  <i className="fa fa-check" aria-hidden="true"></i>
						                  <i className="fa fa-times" aria-hidden="true"></i>
					                  </span>
					                  </label>
					               </div>
					               <div className="ms-tab-content">
					                  {
					                  	addressFiscal ? 
						                  <div className="ms-tab active" id="editinvoice">
						                     <DireccionFiscal visual="payment" changeAddressFiscalDefaul = {changeAddressFiscalDefaul} />
						                  </div>
						                  : null
					                  }
					               </div>
					            </div>

						        <div className="ms-wizard-item">
						        <div className="ms-header-wizard">
						            <Input
							            type="date" 
							            label="Fecha Despacho"
							            id="despachofecha" 
							            name="fecha" 
							            onChange = {handleChangeDespacho}							            							            
							            value={despacho.fecha}
							            min={new Date().toLocaleDateString("fr-CA")}
							            max="2040-12-31"
						             />

								    <InputPrice
								        label="Precio despacho"
								        id="despachoprice" 
								        name="price"
								        min="0"
								        value={despacho.price}
								        onChange = {handleChangeDespacho}
								        readonly = "readonly"
								    />
						            
						          </div>
						          
						          	<div className="ms-body-wizard js-list-product-cart">
						          	{
						          		itemshopping.length> 0 ?
						          			itemshopping.map(
						          				(cart,keycar) => 
								              <div className="ms-table-item" key={keycar}>
								                <div className="ms-table-title">{cart.name}</div>
								                <table className="fm-detail-prod">
								                  <tbody>      
								                  </tbody>
								                </table>
								              </div>
										)
						          			: null
						          	}
									</div>
						        </div>
						      </div>
						    :
						    null
						}						      


						      <div className="ms-lateral-b">
						        <div className="ms-wizard-item">
						          <div className="ms-wrap-item">
						            <span className="ms-title">Cupón de descuento</span>

						            {
						            	"tiene_cupon" in resumen && resumen.tiene_cupon ?
							            <div className="js-block-discount">
				                    		<label className="ms-title-cupon">{localStorage.cupon}</label>
				                    		<span className="js-title-cupon-delete" onClick={QuitarCupon}><i className="fa fa-times"></i></span>
							            </div>
							            :

							            <div className="ms-item-form js-block-cupon">
							              <input type="text" placeholder="Código de cupón" className="js-input-cupon" />
							              <button className="ms-btn js-btn-get-cupon" onClick={canjearCupon}>Canjear Cupón</button>
							            </div>				            
							        }

						          </div>
						        </div>

						        <div className="ms-wizard-item">
						          <div className="ms-wrap-item">
						            <div className="ms-body-wizard">
						              <div className="ms-table-item js-cart-block-sub-total" style={{ marginBottom : "0"}}>

					                        <div className="ms-sub-title">
					                           <strong>Sub total Transferir</strong>
					                           <strong className="color-price js-subtot-cart">{currenmoneda.rate.label === "venta" ? window.currencyFormat(subTotal,1) : window.currencyFormat(subTotal,2) }</strong>
					                        </div>

					                        <table className="ms-payment-detail">
					                        	<tbody className="table-resumen">

					                        	{
					                        		"infocupon" in resumen && "nombre_mostrar" in  resumen.infocupon && "tipo_adjustment" in  resumen.infocupon && resumen.infocupon.tipo_adjustment === "1" ?

					                        				<tr className="tr-rm-main">
					                        					<td>
					                        						<div className="block-resumen resumen-descuento-prod" >
														            	<span className="ms-grcolor">{resumen.infocupon.nombre_mostrar}</span>
														                <span className="ms-pr-color">-S/{(resumen.infocupon.monto_descuento) }</span>
														            </div>	
														        </td>	                        							
														    </tr>
					                        		:
					                        		null
					                        	}

			                              {"activedescuentoCupon" in resumen && resumen.activedescuentoCupon ? 
				                                  <tr className="tr-rm-main">
				                                    <td>
				                                    {
				                                    	<div className="block-resumen">
					                                    	<span className="ms-grcolor">{resumen.nombrecupon}</span>
								                        	<span className="ms-pr-color">-S/{resumen.mountdescuento}</span>
								                        </div>	                                    	
				                                    }
				                                    </td>
				                                  </tr>
			                                  :null
			                              }

						                    	</tbody>

						                        <tfoot  className="table-resumen" style={{"borderTop": "1px solid #ccc"}}>
						                            <tr className="tr-rm-main">
							                            <td>
				                                    	<div className="block-resumen">
				                                    		<span className="ms-grcolor foot"><strong>Total</strong></span>
					                        				<span className="ms-pr-color foot"><strong className="ms-grcolor">
					                        				{currenmoneda.rate.label === "venta" ? window.currencyFormat(resumen.totalPrice,1) : window.currencyFormat(resumen.totalPrice,2) }</strong></span>
					                        			</div>	 
					                        			</td>
						                            </tr>
						                        </tfoot>                           
						                    </table>
					                           
					                        <div className="ms-sub-title">
					                           <strong>total a recibir</strong>
					                           <strong className="color-price js-subtot-cart">{currenmoneda.rate.label === "venta" ? window.currencyFormat(currenmoneda.recibo,2) : window.currencyFormat(currenmoneda.recibo,1) }</strong>
					                        </div>
					                           

					                        <span className="ms-min-text">Pedido mínimo de S/.35 para poder hacer una compra</span>
					                        {
					                        	(["carrito"].includes(vieworder) && resumen.nextstep ) ?
					                        		<button className="ms-btn green js-btn-next-payment" onClick={()=>nextPage("despacho")}>Continuar</button>
					                        	:
					                        	(["despacho"].includes(vieworder) && resumen.nextstep && despacho.price !=null && despacho.fecha !=null ) ?
					                        		<button className="ms-btn green js-btn-next-payment" onClick={()=>nextPage("payment")}>Continuar</button>
					                        	:
					                        	null
					                        }
					                        
						              </div>
						            </div>
						          </div>
						        </div>
						      </div>
						    </div>

						    :
					    null
				}

					{
						["payment"].includes(vieworder) ?						


					      <div className="ms-wizard-wrapper shop-payment">
					         <div className="ms-lateral-a">
					            <div className="ms-wizard-item">
					               <h3 className="ms-form-title">
					                  <i className="fa fa-truck" aria-hidden="true"></i> IDENTIFICACIÒN
					               </h3>
					               <div className="ms-tab-content js-identification">
					                  <div className="ms-tab active" id="resument_user">
					                     <div className="ms-item-addres">
					                        <span>{value.user.email}</span>
					                        <span>{value.user.name+" "+value.user.lastname }</span>
					                        <span>{value.user.phone}</span>
					                     </div>
					                  </div>
					               </div>
					            </div>


					            <div className="ms-wizard-item">

					               <h3 className="ms-form-title">METODO DE PAGO</h3>
					               <div className="ms-tab-content">
					                  <div className="js-pay-niubiz" style={{"display":"none"}}>
					                     <form id="eko-payment-niubiz" className="eko-payment-niubiz">
					                     </form>
					                  </div>
					                  <div className="ms-tab active" id="resument_payment">
					                     <div className="ms-item-addres">
					                        {
					                        	methodShopping === "schedule" ?
					                        	<ul className="lista-metodos">

						                           <li className="item-metodo js-contraentrega">
						                              <input type="radio" className="js_metodo_pago" name="metodo_pago" id="metodo_pago_0" onClick={() => checkoutSet("efectivo")}  />
						                              <label htmlFor="metodo_pago_0">
						                              <img src="https://ekonofresh.pe/images/pago-tarjeta.png" />
						                              <span>Casa efectivo (Efectivo, YAPE, Tunki)</span>
						                              </label>
						                           </li>


						                           <li className="item-metodo js-contraentrega">
						                              <input type="radio" className="js_metodo_pago" name="metodo_pago" id="metodo_pago_3" onClick={() => checkoutSet("pos_visa")}  />
						                              <label htmlFor="metodo_pago_3">
						                              <img src="https://ekonofresh.pe/images/pago-tarjeta.png" />
						                              <span>Pago contra entrega (POS crédito, débito, tarjetas de alimentos y pre pagadas)</span>
						                              </label>
						                           </li>


						                           <li className="item-metodo js-contraentrega">
						                              <input type="radio" className="js_metodo_pago" name="metodo_pago" id="metodo_pago_4" onClick={() => checkoutSet("pago_contra_entrega")}  />
						                              <label htmlFor="metodo_pago_4">
						                              <img src="https://ekonofresh.pe/images/pago-tarjeta.png" />
						                              <span>Pago contra entrega (Efectivo, YAPE, Tunki)</span>
						                              </label>
						                           </li>


						                           <li className="item-metodo js-contraentrega-2" >
						                              <input type="radio" className="js_metodo_pago" name="metodo_pago" id="metodo_pago_5" onClick={() => checkoutSet("transferencia")}  />
						                              <label htmlFor="metodo_pago_5">
						                              <img src="https://ekonofresh.pe/images/contra-entrega.png" />
						                              <span>Transferencia Bancaria</span>
						                              </label>
						                           </li>

						                           	<div className={metodoPago === "transferencia" ? "list-transferencia show" : "list-transferencia hide" } >
						                           		<ul className="list-pay-transferences">
						                           			<li><b>Banco:</b></li>   
						                           			<li>BCP</li>
						                           			<li><b></b></li>
						                           			<li>193-2229497-0-70</li>
						                           			<li><b>CCI:</b></li>
						                           			<li>002-19300222949707016</li>
											            </ul>

											            <ul className="list-pay-transferences">
											            	<li><b>Banco:</b></li>   
											            	<li>INTERBANK</li>
											            	<li><b></b></li>
											            	<li>200-3002015461</li>
											            	<li><b>CCI:</b></li>
											            	<li>003-200-003002015461-30</li>
											            </ul>

							                           <InputFile
													        label = "Constancia Transferencia"
													        validated="yes"
													        name="constanciatransferencia"
													        valobj = {constanciatransferencia}
													        changeFiles = {handleChangeFile}
													    />

						                           	</div>
						                          	

						                          	{/*
							                           <li className="item-metodo js-niubiz">
							                              <input type="radio" className="js_metodo_pago" name="metodo_pago" id="manual" onClick={() => showModalDeposito() } />
							                              <label htmlFor="manual">
							                              <img src="https://ekonofresh.pe/images/pago-tarjeta.png" />
							                              <span>DEPOSITO BANCARIO</span>
							                              </label>
							                           </li>
							                           */}




						                        </ul>
						                           : null
					                        }
					                         
					                        <p className="eko-param-method-payment">Si elegiste tarjeta de crédito o debito como medio de pago, en este momento solo se ha hecho una reserva en tu tarjeta por el monto estimado más 10% en caso pueda existir alguna diferencia por pesos. El cobro final se realizará en el momento de despachar tu pedido con el valor real y exacto de tu entrega.</p>
					                        <br></br>
					                     </div>
					                  </div>
					               </div>
					            </div>
					         </div>
					         <div className="ms-lateral-b ms-despacho">
					            <div className="ms-wizard-item">
					               <div className="ms-wrap-item">
					                  <div className="ms-back-shipping">
					                     <a href="#"><i className="fa fa-angle-left"></i> Volver al carrito</a>
					                  </div>
					                  <div className="ms-body-wizard">
								              <div className="ms-table-item js-cart-block-sub-total" style={{ marginBottom : "0"}}>

							                        <div className="ms-sub-title">
							                           <strong>Sub total Transferir</strong>
							                           <strong className="color-price js-subtot-cart">{currenmoneda.rate.label === "venta" ? window.currencyFormat(subTotal,1) : window.currencyFormat(subTotal,2) }</strong>
							                        </div>

							                        <table className="ms-payment-detail">
							                        	<tbody className="table-resumen">

							                        	{
							                        		"infocupon" in resumen && "nombre_mostrar" in  resumen.infocupon && "tipo_adjustment" in  resumen.infocupon && resumen.infocupon.tipo_adjustment === "1" ?

							                        				<tr className="tr-rm-main">
							                        					<td>
							                        						<div className="block-resumen resumen-descuento-prod" >
																            	<span className="ms-grcolor">{resumen.infocupon.nombre_mostrar}</span>
																                <span className="ms-pr-color">-S/{(resumen.infocupon.monto_descuento) }</span>
																            </div>	
																        </td>	                        							
																    </tr>
							                        		:
							                        		null
							                        	}

					                              {"activedescuentoCupon" in resumen && resumen.activedescuentoCupon ? 
						                                  <tr className="tr-rm-main">
						                                    <td>
						                                    {
						                                    	<div className="block-resumen">
							                                    	<span className="ms-grcolor">{resumen.nombrecupon}</span>
										                        	<span className="ms-pr-color">-S/{resumen.mountdescuento}</span>
										                        </div>	                                    	
						                                    }
						                                    </td>
						                                  </tr>
					                                  :null
					                              }

								                    	</tbody>

								                        <tfoot  className="table-resumen" style={{"borderTop": "1px solid #ccc"}}>
								                            <tr className="tr-rm-main">
									                            <td>
						                                    	<div className="block-resumen">
						                                    		<span className="ms-grcolor foot"><strong>Total</strong></span>
							                        				<span className="ms-pr-color foot"><strong className="ms-grcolor">
							                        				{currenmoneda.rate.label === "venta" ? window.currencyFormat(resumen.totalPrice,1) : window.currencyFormat(resumen.totalPrice,2) }</strong></span>
							                        			</div>	 
							                        			</td>
								                            </tr>
								                        </tfoot>                           
								                    </table>
							                           							                        
					                        <div className="ms-sub-title">
					                           <strong>total a recibir</strong>
					                           <strong className="color-price js-subtot-cart">{currenmoneda.rate.label === "venta" ? window.currencyFormat(currenmoneda.recibo,2) : window.currencyFormat(currenmoneda.recibo,1) }</strong>
					                        </div>

							                        <span className="ms-min-text">Pedido mínimo de S/.35 para poder hacer una compra</span>
							                        { 
							                        	(
							                        		( resumen.nextstep && ["efectivo","pos_visa","pago_contra_entrega"].includes(metodoPago) ) || 
							                        		( resumen.nextstep && ["transferencia"].includes(metodoPago) && constanciatransferencia !== "" )
							                        	)
							                        	   ? 
							                        		<button className="ms-btn green js-btn-next-payment" onClick={pagarButton}>FINALIZAR COMPRA</button>
							                        	: null
							                        }
								              </div>                     
					                  </div>
					               </div>
					            </div>
					         </div>
					      </div>


						:
						null
					}		

					{
						["confirmacion"].includes(vieworder) ?

				        <div className="ms-wizard-wrapper">
				          <div className="ms-lateral-a">
				            <div className="ms-wizard-item">
				              <h3 className="ms-form-title ms-bgt">Gracias por comprar en CAMBISTAPE SAC</h3>
				              <div className="ms-tab-content">
				                <p>Póngase en contacto con nosotros si tiene alguna pregunta sobre su pedido.</p>
				                <p>Número de orden: {window.payment.numero_pedido} <br/>Entrega {window.payment.fecha_hora_envio}</p>
				                <table className="ms-small-table fm-resumen-payment">
				                  <tbody>

									<tr className="tr-rm-main desc-cate">
										<td><strong className="color-price js-subtot-cart">Se Envia/Recibe {currenmoneda.rate.label === "venta" ? window.currencyFormat(currenmoneda.recibo,2) : window.currencyFormat(currenmoneda.recibo,1) }</strong></td>
						           	</tr>																

		                        	<tr className="tr-rm-main tr-sub-total">
		                        		<td>Sub total</td>
		                        		<td className="ms-pr-color">{currenmoneda.rate.label === "venta" ? window.currencyFormat(window.payment.total,1) : window.currencyFormat(window.payment.total,2) }</td>
		                        	</tr>

				                    <tr>
				                      <td>Costo Envío</td>
				                      <td>{currenmoneda.rate.label === "venta" ? window.currencyFormat(window.payment.envio,1) : window.currencyFormat(window.payment.envio,2) }</td>
				                    </tr>
				                  </tbody>
				                  <tfoot>
				                    <tr>
				                      <td><span>Total Pagar/Transferir(*)</span></td>
				                      <td><span className="ms-total">{currenmoneda.rate.label === "venta" ? window.currencyFormat(window.payment.total,1) : window.currencyFormat(window.payment.total,2) }</span></td>
				                    </tr>
				                  </tfoot> 
				                </table>
				                    <ul className="ms-sp-list">
				                      <li>Si usted eligió como medio de pago una tarjeta de crédito, el cargo (cobro) se realizará en el momento del despacho de su pedido.</li>
				                    </ul>
				                    <br/>
				                <p>NOTA: Tus comprobantes de pago (boletas/facturas) te llegaran desde un correo de Cambistape SAC. De igual manera; cualquier notificación de tu banco o tarjeta de crédito/debito por compras en cambistape.com, aparecerán a nombre de Cambistape SAC.</p>
				                <div className="ms-wrap-links">
				                  <a href="/orders/new">Iniciar otra compra</a> <a href="/orders">Mis pedidos</a>
				                </div>
				              </div>
				            </div>
				          </div>

				          <div className="ms-lateral-b ms-despacho">
				            <div className="ms-wizard-item">
				              <div className="ms-wrap-item">
				                <div className="ms-table-item" >
				                  <div className="ms-icon-block">
				                    <i className="fa fa-lock" aria-hidden="true"></i>
				                    <span>PAGO SEGURO</span>
				                  </div>
				                  <p className="ms-small-text">Aceptamos pagos con tarjeta de crédito Visa, MasterCard, American Express y Diners Club.</p>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
			        :
				        null
			      }

			
			</div>			  	
		</div>


<div className="modal fade" id="modalConfrmpay" role="dialog" aria-labelledby="modalEditAddres">
   <div className="modal-dialog" role="document">
      <div className="modal-content">
         <div className="modal-header">
         <h3 className="ms-form-title"><i className="fa fa-credit-card " aria-hidden="true"></i> DEPOSITO BANCARIO</h3>
            <button onClick={cerrarModalPayu}  type="button" className="close js-close-transfer" data-dismiss="modal" aria-label="Close"><i className="fa fa-times"></i></button>
         </div>
         <div className="modal-body">

          <div className="text-sm"> 
            <ul className="list-pay-transferences">
              <li><b>Banco:</b></li>   
              <li>BCP</li>
              <li><b></b></li>
              <li>193-2229497-0-70</li>
              <li><b>CCI:</b></li>
              <li>002-19300222949707016</li>
            </ul>

            <ul className="list-pay-transferences">
              <li><b>Banco:</b></li>   
              <li>INTERBANK</li>
              <li><b></b></li>
              <li>200-3002015461</li>
              <li><b>CCI:</b></li>
              <li>003-200-003002015461-30</li>
            </ul>            
            <div className="block-amount js-block-amount-checkout">
              <span className="js-total-payment">Titular: FRESHMART S.A RUC: 20539065751</span>
            </div>
          </div>


            <div className="form-payment-transferences">
                  <div className="visible">
                     <div className="relative py-3">
                        <button className="ms-btn green js-btn-save-transferences" 
                        onClick={() => checkoutSet("manual") } >Enviar</button>
                     </div>

                  </div>
            </div>
         </div>
      </div>
   </div>
</div>

		</div>
	)	
}

export default Pedido;