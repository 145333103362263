const RawTermCondition = () => {
return(
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h1 className="title-block color-green">Términos y condiciones</h1>

                        <p>La sola visita de Freshmart.pe no impone al consumidor obligación alguna de compra, a menos
                            que haya aceptado las condiciones ofrecidas. El uso del portal Freshmart.pe se rige por los
                            términos y condiciones descritos a continuación, así como por la legislación que se aplique
                            en la República del Perú. Por lo tanto, todas las visitas y las transacciones que se
                            realicen en Freshmart.pe, denominado en adelante "EL CLIENTE", en este portal, así como
                            sus efectos legales, quedarán regidos por estas reglas y sometidas a esta legislación.</p>

                        <p>Los términos y condiciones contenidos en este instrumento formarán parte de todos los actos
                            y contratos que se ejecuten o celebren mediante los sistemas de oferta y comercialización
                            comprendidos en este sitio Web, entre los clientes de este sitio y Freshmart, a quien se le
                            denominará en adelante "FRESHMART", según convenga al sentido del texto.</p>

                        <p className="color-green font-weight-bold">A CONTINUACIÓN SE EXPONEN DICHAS CONDICIONES:</p>
                        <ol>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">REGISTRO DEL CLIENTE</p>

                                <p>Para realizar una compra a través de FRESHMART, es requisito indispensable
                                    la aceptación de las presentes condiciones y el registro por parte de
                                    EL CLIENTE. Para esto, necesitaremos de algunos datos personales y contará con
                                    una clave de acceso. El solo registro se entenderá como el conocimiento y aceptación
                                    de estos Términos y Condiciones. El registro en www.freshmart.com es gratuito.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">CLAVE SECRETA</p>

                                <p>El CLIENTE asume totalmente la responsabilidad por el mantenimiento de la
                                    confidencialidad de su clave secreta registrada en este sitio Web, la cual le
                                    permite efectuar compras, solicitar servicios y obtener información. Dicha clave es
                                    de uso personal y su entrega a terceros no involucra responsabilidad de Freshmart
                                    S.A.C.. o de sus relacionadas en caso de mala utilización.</p>

                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">USO DE PORTAL</p>

                                <p>FRESHMART informará, de manera inequívoca y fácilmente accesible, los pasos que deben
                                    seguirse para realizar un pedido, e informará vía correo electrónico cuando fue
                                    recibida la solicitud del pedido. Los pasos de una compra serán los siguientes:</p>

                                <ol>
                                    <li>Agregar productos al carrito de compras</li>
                                    <li>Validación de datos del cliente</li>
                                    <li>Compra de productos</li>
                                    <li>Emisión de boleta informativa sobre el pedido donde se indicará los productos a
                                        enviarse y los pesos exactos de los mismo. También se indicará en caso haya
                                        algún producto faltante.
                                    </li>
                                </ol>
                                <p>FRESHMART indicará, además, su dirección de correo postal o electrónico y los medios
                                    técnicos a disposición del consumidor para identificar y corregir errores en el
                                    envío o en sus datos.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">MEDIOS DE PAGO QUE SE PODRÁN UTILIZAR EN
                                    ESTE SITIO</p>
                                <ul>
                                    <li>Los productos y servicios ofrecidos en este sitio, salvo que se señale una forma
                                        diferente para casos particulares u ofertas de determinados bienes o servicios,
                                        sólo podrán ser pagados bajo 2 modalidades.
                                    </li>
                                    <li>Pago contra entrega: Aceptamos pago mediante efectivo, siempre y cuando sea el
                                        monto exacto o vía POS VISA.
                                    </li>
                                    <li>Pagos en línea: Aceptamos tarjetas de crédito Visa, MasterCard y American
                                        Express. Los montos aprobados serán retenidos por un tiempo máximo de
                                        07 días para su atención. De no entregarse el pedido en el tiempo
                                        establecido, la retención se libera y el pedido queda anulado automáticamente y
                                        sin cargo alguno. El monto retenido será el valor de la compra que aparece
                                        en la sección de pago de nuestra web más un 10%. Solo se debitará a su tarjeta
                                        de crédito o débito el monto exacto de los productos efectivamente enviados;
                                        cualquier valor retenido superior al monto exacto de su pedido
                                        será liberado en el mismo momento del cobro.
                                        <p><span className="color-green">NOTA (Cobros con tarjetas de Crédito durante
                                            el Estado de Emergencia)</span>
                                            Por motivos del Estado de Emergencia, tiempo en el cual venimos recibido
                                            solicitudes de pedido con antelación a 7 días calendario antes de la entrega
                                            y dada la configuración de nuestra plataforma de pago (separación del dinero
                                            y posterior cobro), es posible que sus pedidos sean cobrados a su tarjeta de
                                            crédito antes de la entrega.
                                            Si este fuese el caso; todo cobro realizado por adelantado y en exceso a lo
                                            entregado será extornado directamente a su tarjeta de crédito en los
                                            siguientes 2 días de realizada la entrega de sus productos.</p>
                                    </li>
                                    <li>Freshmart SAC trabaja una política de cobros transparente en donde solo se
                                        realiza el cobro una vez que su pedido está completo y listo para despachar,
                                        asegurando que solo se cobrará lo que efectivamente se está despachando (pesos y
                                        cantidades). No se genera ningún cobro por un producto solicitado y no enviado
                                        por falta de stocks y solo se cobra por el peso exacto despachado de los
                                        productos de "pesaje".
                                    </li>
                                </ul>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">FORMACIÓN DEL CONSENTIMIENTO EN LOS PEDIDOS
                                    REALIZADOS A TRAVÉS DE ESTE SITIO</p>
                                <p>A través de www.freshmart.pe, FRESHMART realizará ofertas de bienes y servicios, que
                                    podrán ser aceptadas por vía electrónica o telefónica, y utilizando los mecanismos
                                    que el mismo sitio ofrece para ello. Toda aceptación de oferta quedará sujeta a la
                                    condición de que FRESHMART valide la transacción. En consecuencia, para toda
                                    operación que se efectúe en este sitio, la confirmación y/o validación y/o
                                    verificación por parte de FRESHMART, será requisito para la formación del
                                    consentimiento. Para dar por validada la transacción, FRESHMART deberá
                                    verificar:</p>
                                <ol type="a">
                                    <li>
                                        Que dispone de los productos solicitados en los mercados mayoristas de Lima
                                        posteriormente a la compra y que los productos cumplan con los estándares de
                                        calidad de Freshmart, en particular en cuanto a la frescura.
                                    </li>
                                    <li>
                                        b) Que en caso el pedido incluya bebidas alcohólicas, cigarros y/o tabaco haya
                                        sido realizado por CLIENTES mayores de 18 años. Freshmart solicitara constancia
                                        de mayoria de edad.
                                    </li>
                                </ol>
                                <p>Los productos adquiridos a través de freshmart.pe se sujetarán a las condiciones de
                                    despacho y entrega elegidas por el CLIENTE y disponibles en el sitio. La información
                                    del lugar de entrega es de exclusiva responsabilidad del CLIENTE.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">DESPACHO DE LOS PRODUCTOS</p>
                                <p>Los productos adquiridos a través de freshmart.pe se sujetarán a las condiciones de
                                    despacho y entrega elegidas por el CLIENTE y disponibles en el sitio. La información
                                    del lugar de entrega es de exclusiva responsabilidad del CLIENTE.</p>
                                <p>Los pedidos serán atendidos mediante rangos horarios definidos y de acuerdo con la
                                    disponibilidad de los mismos los cuales pueden encontrarse en nuestra página
                                    web.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">COBRO DEL SERVICIO DE FLETE</p>
                                <p>El cálculo del flete varía de acuerdo a la zona y día y está establecido en la página
                                    web.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">PLAZO MÁXIMO DE ESPERA EN LA ENTREGA</p>
                                <p>Toda entrega es programada en la ventana de atención elegida por el Cliente y se
                                    llevará a cabo siempre y cuando el destinatario se encuentre en la dirección de
                                    despacho previamente identificado.
                                    FRESHMART esperará un máximo de 10 minutos luego de haberse notificado al
                                    cliente la llegada del repartidor a su domicilio. Pasado dicho plazo FRESHMART
                                    gestionará con el cliente o el receptor, según sea el caso, la reprogramación de
                                    la entrega de su pedido. Las reprogramaciones generan un costo adicional por la
                                    nueva entrega correspondiente al mismo monto del flete cargado inicialmente, el
                                    cual será cargado en el documento de pago.
                                </p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">DERECHO DE ANULACIÓN</p>
                                <p>Freshmart.pe acepta la anulación de pedidos. Para este fin, el CLIENTE debe comunicar
                                    la anulación al menos 10 horas antes de que se cumplan los plazos de entrega
                                    indicados en la sección Política de Cambios y Devoluciones. Las anulaciones podrán
                                    solicitarse vía correo electrónico o por teléfono. Asimismo, en caso de
                                    pedidos que muestren indicios de fraude o seguridad de nuestro equipo de despacho,
                                    Freshmart se reserva el derecho de anular el pedido.</p>
                                <p>Si el CLIENTE ingresa una dirección fuera de nuestra zona de cobertura,
                                    Freshmart se reserva el derecho de anular el pedido.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">POLÍTICA DE FALTANTES, CAMBIOS Y
                                    DEVOLUCIONES</p>


FRESHMART acepta cambios y devoluciones de sus productos por motivo de desistimiento, a excepción de:
<ul>
    <li>Productos frescos/perecibles y/o que requieran refrigeración</li>
    <li>Productos que se encuentran en precios de liquidación.</li>
    <li>Productos que hayan sido abiertos, muestren señales de uso o manipulación y que no cuenten con empaques y/o embalajes originales, en perfecto estado.</li>
</ul>
Para realizar cualquier cambio, devolución y/o cancelación dentro de estos parámetros, el cliente, usuario y/o tercero deberá cumplir con las siguientes condiciones y seguir los pasos indicados a continuación:

<ol><br/>
    <li><strong>Política de cambios</strong>:

        <ol style = {{"list-style": "lower-alpha"}}>
            <br/><li>Clientes y/o usuarios: Con excepción de los productos frescos/perecibles o que requieran refrigeración, el cliente/usuario podrá solicitar cambios o sustituciones de los Productos que conforman una Transacción, hasta las 23:59 horas del día en que se haya seleccionado la entrega del Pedido, en cuyo caso se hará el ajuste correspondiente en la Contraprestación. Salvo por cuestiones imputables a la Empresa, no se admitirán cambios o sustituciones de Productos al día siguiente de celebrada la Transacción. En caso de que el Pedido ya se encuentre en marcha, deberá cancelarse la entrega y el Usuario deberá pagar nuevamente la Tarifa de Envío, la cual será cargada al momento de solicitarse el Cambio. Para el cambio de cualquier producto adquirido mediante nuestros canales digitales, la Empresa contará con un plazo de tres (3) días hábiles para recoger dicho producto del domicilio del Usuario y entregar el Producto sustituto. Para el cambio de cualquier producto adquirido en nuestra tienda, el cliente deberá acercarse a la misma y solicitar el cambio, dentro del plazo antes mencionado.</li>
            <br/><li>Terceros: Con excepción de los productos frescos/perecibles o que requieran refrigeración, el tercero podrá solicitar cambios o sustituciones de los Productos que conforman una Transacción, hasta las 23:59 horas del día en que se haya efectuado la entrega del Pedido, en cuyo caso se hará el ajuste correspondiente en la Contraprestación. Salvo por cuestiones imputables a la Empresa, no se admitirán cambios o sustituciones de Productos al día siguiente de celebrada la Transacción. Para el cambio de cualquier producto, el tercero deberá acercarse a nuestra tienda ubicada en Miraflores y solicitar el cambio, dentro del plazo antes mencionado.</li>
        </ol>
    </li>
    <br/><li><strong>Política de devoluciones.</strong>

        <ol style = {{"list-style": "lower-alpha"}}>
            <br/><li>Clientes y/o usuarios: Las devoluciones por productos defectuosos, mediante nuestros canales digitales, considerándose como tal aquellos productos que se encuentren caducos, se sujetarán a lo siguiente: (i) tratándose de Productos perecederos, si el cliente y/o usuario no está completamente satisfecho con su estado, el cliente y/o usuario recibirá un abono en su Saldo a Favor equivalente al 100% (cien por ciento) del Precio pagado por aquellos, sin que se encuentre obligado a devolverlos a la Empresa; y (ii) por lo que hace a Productos no perecederos, se abonará igualmente el Precio pagado por los mismos en el Saldo a Favor, y repartidor asociado irá a recolectarlo al domicilio del usuario/cliente. No se aceptarán devoluciones fuera de los plazos establecidos a continuación.</li>
            <br/><li>Terceros: Para las devoluciones por productos defectuosos, mediante terceros, considerándose como tal aquellos productos que se encuentren caducos, se sujetarán a lo siguiente: (i) tratándose de Productos perecederos, si no se está completamente satisfecho con su estado, el tercero recibirá una nota de crédito equivalente al 100% (cien por ciento) del Precio pagado por aquellos productos, sin que se encuentre obligado a devolverlos a la Empresa, pero sí deberá demostrar mediante foto y/o video donde se visualice la fecha de caducidad y/o estado del producto; y (ii) por lo que hace a Productos no perecederos, se emitirá nota de crédito por el Precio pagado por los mismos, y el tercero deberá traerlo a la tienda Freshmart ubicada en Miraflores. No se aceptarán devoluciones fuera de los plazos establecidos a continuación.</li>
        </ol>

        <ul>
            <br/><li>Productos no perecibles:
            <p>En este caso, el cliente, usuario y/o tercero tendrá como fecha y hora límite para notificar la solicitud de devolución a Freshmart el plazo de veinticuatro (24) horas posteriores a que el propio cliente, usuario y/o tercero haya recibido su Pedido. No se aceptarán devoluciones con posterioridad al plazo antes indicado. </p>
            <ol style = {{"list-style": "lower-alpha"}}>
                <br/><li>Cliente y/o usuario: El cliente y/o usuario deberá ponerse en contacto con nuestro canal de Atención al Cliente a través de correo electrónico (atenciónalcliente@freshmart.pe). Nuestros asesores informarán al cliente y/o usuario el procedimiento a seguir para la devolución del producto y completar el procedimiento para el reembolso respectivo en el Saldo a Favor del cliente y/o usuario.</li>
                <br/><li>Terceros: El tercero deberá solicitar la devolución acercándose a nuestra tienda Freshmart ubicada en Miraflores, donde se le emitirá una nota de crédito por el monto relacionado al/los producto(s) devuelto(s).</li>
            </ol>    
            </li>

            <br/><li>Productos perecibles:
            <p>En este caso, el cliente y/o usuario tendrá como fecha y hora límite para notificar la solicitud de devolución a Freshmart el plazo de veinticuatro (24) horas posteriores a que el propio cliente y/o usuario haya recibido su Pedido. No se aceptarán devoluciones con posterioridad al plazo antes indicado.</p>
            <ol style = {{"list-style": "lower-alpha"}}>
                <br/><li>Mediante canales digitales: Para la devolución de cualquier producto adquirido mediante nuestros canales digitales, El cliente y/o usuario deberá ponerse en contacto con nuestro canal de Atención al Cliente a través del correo electrónico (atenciónalcliente@freshmart.pe). Nuestros representantes informarán al cliente y/o usuario el procedimiento para el reembolso respectivo en el Saldo a Favor del cliente y/o usuario.</li>
                <br/><li>Mediante compra en tienda: Para la devolución de cualquier producto adquirido en nuestra tienda Freshmart Miraflores, el cliente deberá acercarse a la misma y solicitar la devolución, dentro del plazo antes mencionado.</li>
            </ol>    
            </li>

        </ul>

    </li>

            <br/><li><strong>Política de cancelaciones y modificaciones de pedido:</strong>
            <ol style = {{"list-style": "lower-alpha"}}>
                <li>Envío programado para el día siguiente y hasta 7 días: El cliente y/o usuario puede solicitar la modificación (el contenido de su pedido y horario de entrega) o solicitar la cancelación de su pedido sin ningún costo hasta tres (3) horas antes del horario de entrega seleccionado por el cliente y/o usuario. Esto deberá efectuarse a través del correo electrónico (atenciónalcliente@freshmart.pe). Después de este momento, el cliente y/o usuario no podría realizar modificaciones a su pedido y si el cliente y/o usuario desea cancelar, le será cobrado un costo igual a la Tarifa de Envío.</li>
                <br/><li>Envío programado para el mismo día (Sameday): El cliente y/o usuario puede solicitar la modificación (el contenido de su pedido y horario de entrega) o solicitar la cancelación de su orden sin costo hasta tres (3) horas antes del horario de entrega seleccionado por el cliente y/o usuario. Esto deberá efectuarse a través del correo electrónico (atenciónalcliente@freshmart.pe). Después de este momento, el cliente y/o usuario no podría realizar modificaciones a su pedido y si el cliente y/o usuario desea cancelar, le será cobrado un costo igual a la Tarifa de Envío.</li>
                <br/><li>Envío Express (1 hora): Debido a la rapidez de entrega en este tipo de despachos, el cliente y/o usuario no podrá modificar ni cancelar el pedido una vez efectuado en nuestras plataformas digitales.</li>
            </ol>
            </li>    
</ol>


<p><br/>Asimismo, en caso de pedidos que muestren indicios de fraude o pongan en riesgo la integridad de nuestro equipo de despacho, Freshmart se reservará el derecho de anular dicho pedido.</p>
<p>Si el cliente y/o usuario ingresa una dirección fuera de nuestra zona de cobertura, Freshmart se reservará el derecho de anular dicho pedido.</p>

<p>Para verificar cualquiera de las operaciones antes descritas (puntos 1., 2. y 3.), Freshmart podrá solicitar al cliente, usuario y/o tercero el envío del recibo/comprobante de pedido, por correo electrónico (compras mediante canales digitales) o mostrarlo en forma física para compras en Tienda y/o a través de tercero.</p>



                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">USO DE LOS DATOS PERSONALES REGISTRADOS EN
                                    EL SITIO</p>
                                <ul>
                                    <li>Freshmart S.A.C. (en adelante “Freshmart”) a través de su página web
                                        https://freshmart.pe/ (la “Página Web”), recopila datos personales y como
                                        consecuencia de ello, realiza tratamiento de datos personales, cumpliendo con lo
                                        dispuesto en la Ley N° 29733 - Ley de Protección de Datos Personales y su
                                        Reglamento aprobado mediante D.S. 003-2013-JUS. De presentarse alguna
                                        modificación a la normativa antes señalada, las políticas se adecuarán a dichos
                                        cambios, siendo notificados los usuarios a través de la publicación de las
                                        nuevas políticas de privacidad y tratamiento de datos personales en la Página
                                        Web.
                                    </li>
                                    <li>Los datos personales que son recopilados por la Página Web, tendrán como
                                        finalidad validar los pedidos y mejorar la labor de información y
                                        comercialización de los productos y servicios prestados por Freshmart así como
                                        para absolver sus consultas.
                                    </li>
                                    <li>Freshmart reconoce que en caso desee brindarles información publicitaria,
                                        utilizar los datos personales para realizar análisis de perfiles y prospección
                                        comercial, o para fines estadísticos o históricos, requerirá el consentimiento
                                        expreso, inequívoco, libre e informado de los usuarios de la Página Web.
                                    </li>
                                    <li>Los datos personales recopilados a través de la presente Página Web podrán ser
                                        entregados a nivel nacional a las empresas filiales o relacionadas con
                                        Freshmart. Las empresas con las que puede compartir los datos personales de los
                                        usuarios, exclusivamente para fines de la ejecución de la relación contractual
                                        son:
                                    </li>
                                </ul>
                                <ul>
                                    <li>Empresas Logisticas:
                                        <ul>
                                            <li>Operaciones de Carga SAC, con RUC N° 20550954932, con dirección en Av.
                                                Guanay Sur Mza. F-6 Lote. 288 Urb. Punta Negra Sur, Provincia de Lima;
                                            </li>
                                            <li>Calper Logística SAC, con RUC N° 20601259070, con dirección en Av. Del
                                                Pinar Nro. 373 Urb. Chacarilla, distrito de San Borja, Provincia de
                                                Lima;
                                            </li>
                                            <li>99 Minutos Peru SAC, con RUC N° 20606739193, con dirección en Av.
                                                Primavera Nro. 517 Int. 406 Urb. Chacarilla Del Estanque, distrito San
                                                Borja, Provincia de Lima;
                                            </li>
                                            <li>Maxi Mobility Peru SAC, con RUC N° 20548704261, con domicilio en Calle
                                                Elias Aguirre N° 605 Int. 901 distrito de Miraflores, Provincia de Lima.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Empresa de facturación:
                                        <ul>
                                            <li>TCI SAC, con RUC N° 20481139075, con domicilio en Av. Huaman Mza. A
                                                Lote. 2 Urb. Los Portales Del Golf La Libertad – Trujillo.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul>
                                    <li>Del mismo modo, los datos personales recopilados a través de la Página Web serán
                                        almacenados en los servidores de la compañía estadounidense DigitalOcean LLC,
                                        por lo que Freshmart se encuentra realizando flujo transfronterizo de los datos
                                        personales, conforme se describe en la Política de Privacidad, la cual podrá
                                        ingresarse haciendo click&nbsp;<a
                                            href="https://www.freshmart.pe/page/politica-de-privacidad">aquí</a>.
                                        Además, otras compañías extranjeras podrán tener acceso a determinada data
                                        personal de los usuarios de la Página Web considerando que ésta maneja cookies
                                        de terceros, conforme a lo descrito en la Política de Cookies la misma que podrá
                                        encontrarse haciendo click&nbsp;<a
                                            href="https://www.freshmart.pe/page/politicas-de-cookies">aquí</a>. En caso
                                        se trate de transferencia para fines que no son esenciales al servicio brindado
                                        por Freshmart, previamente Freshmart solicitará el consentimiento expreso,
                                        inequívoco, libre e informado de los usuarios.
                                    </li>
                                    <li>Al momento en que el usuario brinda su consentimiento a los presentes Términos y
                                        Condiciones, otorga su consentimiento expresa para que Freshmart realice el
                                        tratamiento de sus datos personales de conformidad con lo dispuesto en la
                                        Política de Privacidad y la Política de Cookies de la Página Web.&nbsp;</li>
                                    <li>El usuario podrá ejercer su derecho de acceso, actualización, rectificación,
                                        inclusión, oposición y supresión o cancelación de datos personales enviando un
                                        correo electrónico a: <a
                                            href="mailto:atencionalcliente@freshmart.pe">atencionalcliente@freshmart.pe</a>
                                    </li>
                                    <li>Los datos personales recopilados por Freshmart se encuentran almacenados en los
                                        siguientes bancos de datos:
                                        <ul>
                                            <li>CLIENTES, con código RNPDP-PJP N° 19064.</li>
                                            <li>TRABAJADORES, con código RNPDP-PJP N°19065.</li>
                                            <li>USUARIOS DE LA PÁGINA WEB, con código RNPDP-PJP N°19062.</li>
                                            <li>PROVEEDORES, con código RNPDP-PJP N°19066.</li>
                                            <li>QUEJAS Y RECLAMOS, con código RNPDP-PJP N°19063.</li>
                                        </ul>
                                    </li>
                                    <li>Los datos personales recopilados por Freshmart podrán ser almacenados
                                        exclusivamente por el tiempo necesario para cumplir con la finalidad para lo
                                        cual fueron recopilados, respetando el Principio de Calidad, establecido en el
                                        artículo 8 de la Ley de Protección de Datos Personales y las demás disposiciones
                                        del Reglamento de la Ley de Protección de Datos Personales.
                                    </li>
                                </ul>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">
                                    VALIDEZ DE LAS OFERTAS CONTENIDAS EN ESTE SITIO</p>
                                <p>Los precios de los productos y servicios disponibles en este sitio, mientras
                                    aparezcan en él, sólo tendrán vigencia y aplicación en éste y no serán
                                    necesariamente aplicables a otros canales de venta utilizados por FRESHMART, salvo
                                    que FRESHMART lo indique. Asimismo, FRESHMART podrá modificar cualquier información
                                    contenida en este sitio, incluyendo las relacionadas con mercaderías, servicios,
                                    precios, existencias y condiciones, en cualquier momento y sin previo aviso,
                                    respetando las compras que han sido aceptadas hasta dicho momento, siempre sujeto a
                                    las condiciones de validación que se indican en el número 6.</p>
                                <p>Nos reservamos el derecho de anular un pedido en caso de que este no cumpla con todos
                                    los términos y condiciones mencionados sobre la promoción.</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">PROMOCIONES</p>
                                <p>Las promociones que se ofrezcan en este sitio Web no son necesariamente las mismas
                                    que ofrezcan otros canales de venta utilizados por FRESHMART, tales como locales
                                    físicos, a menos que se señale expresamente en este sitio o en la publicidad que
                                    realice FRESHMART para cada promoción.</p>
                                <p>Nos reservamos en la anulación del pedido, cuando verificamos cualquier
                                    incumplimiento en los términos y condiciones generales y/o individuales de cada
                                    promoción. Las promociones están diseñadas para atender un único usuario y/o
                                    domicilio. La promoción de referido es para clientes y domicilios nuevos.<br/>
                                    <br/>
                                    Así mismo, las promociones de productos está limitada a un pedido por
                                    día, por usuario y/o domicilio.</p>

                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">OTROS SITIOS WEB</p>
                                <p>Freshmart.pe no garantiza, avala ni respalda de ninguna forma el acceso a información
                                    o contenido de cualquier otro sitio Web o portal salvo que exista una relación
                                    especial con otra empresa que no pertenezca a FRESHMART SAC ni sea filial de la
                                    misma, para introducir más funciones en el Sitio. Estas relaciones especiales se
                                    establecen con socios comerciales, patrocinadores y sitios de marca de empresa (en
                                    adelante “páginas de marca de empresa”).</p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">REPRESENTANTES</p>
                                <p>Toda presentación o reclamo relacionado con actos o contratos ejecutados o celebrados
                                    a través de este sitio, deberá ser presentada enviando un correo a <a
                                        href="mailto:general@Freshmart.pe">general@freshmart.pe</a></p>
                            </li>
                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">CAPACIDAD LEGAL PARA CONTRATAR</p>
                                <p>Los servicios de freshmart.pe están disponibles sólo para aquellos individuos que
                                    tengan capacidad legal para contratar, según lo dispuesto por la legislación
                                    vigente. Si una persona no tiene capacidad legal para contratar, debe abstenerse de
                                    utilizar los servicios ofrecidos en el sitio. Freshmart.pe podrá suspender la
                                    participación de CLIENTE que se compruebe carecen de capacidad legal para usar los
                                    servicios ofrecidos en el sitio en cualquier momento en forma temporal o definitiva
                                    o cuando al registrarse brinden información que sea falsa, inexacta o
                                    fraudulenta.</p>
                            </li>


                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">Programa FRESHCLUB</p>
                                <p>Freshclub es un programa de beneficios exclusivos para todos nuestros clientes activos que realicen sus compras semanales en nuestra App o web. Aquí podrás encontrar muchos descuentos y promociones en más de 50 establecimientos de moda, belleza, turismo, salud y otros.<br/>¿Cómo puedo pertenecer a Freshclub?<br/>Solo tienes que realizar tus compras semanales por un monto mínimo de S/400 durante el mes.<br/>Se acredita 1 punto por cada sol consumido/comprado en el mes en la web o App.<br/>Niveles Fresh:
                                <br/>El status se logra al cierre del mes y es vigente durante el mismo.<br/>Niveles:<br/>
                                <ol>
                                    <li>Light: 400 puntos</li>
                                    <li>Fresh: 800 puntos</li>
                                    <li>Extra Fresh: 1300 puntos</li>
                                    <li>Súper Fresh: 1800 puntos</li>
                                </ol>
                                <br/>
                                ** Los Puntos Fresh que se canjean por descuentos en pedidos seguirán enviándose de forma regular, siempre y cuando cumplan con las condiciones de pertenecer a cada nivel.
                                </p>
                            </li>


                            <li className="color-orange-li">
                                <p className="color-orange font-weight-bold">Promociones, concursos y descuentos</p>
                                <ul>
                                    <li><b>Sorteo “Navidad Mágica” con Freshmart – Del 01 al 18 dic 2022</b>:
                                    <p>Por cada S/300 soles acumulas una oportunidad para ganar. Promoción válida para compras y despachos del 01 al 18 (inclusive) de diciembre de 2022. Ingresan todos los clientes de Freshmart que tengan una compra mínima de S/300. Serán 03 sorteos en las fechas: 05, 12 y 19 de diciembre. Los premios son: 03 viajes a Punta Cana (cada viaje incluye pasajes aéreos y estadía para 04 personas. El viaje consta de 04 días y 03 noches), Freshmart no tramita visas ni viajes de provincia a Lima. El pasaje incluye salida desde aeropuerto Lima. 25 vales de consumo de S/500 (1 cupón de doble uso de S/250 abierto para 2 quincenas desde la fecha de sorteo) en Freshmart.pe y 13 vales de consumo para un día de Spa en Spa Heavenly Escape - Heavenly Spa by Westin. Stock Premios: 41 unidades / 41 ganadores. No válido para compras mayoristas. No es acumulable con otras promociones. No participan colaboradores Freshmart ni familiares directos. Fechas no incluidas para viajes: Feriados, días festivos y vacaciones escolares (del 16/12/2022 al 26/02/2023, fechas aproximadas). Entrega de los premios a partir del 3 de Enero del 2023. Concurso exclusivo de Freshmart.</p>
                                    </li>

                                    <li><b>Cashback CBNAVI60 - Del 04 al 07 dic 2022</b>:
                                    <p>Válido para pedidos creados y recibidos del 04 al 07 de diciembre a través de la WEB o APP de Freshmart.pe y que se hayan entregado hasta el 07 de diciembre de 2022. Pedido mínimo de S/300 soles. Para participar deberá ingresar el cupón CBNAVI60 al momento de generar el pedido (antes de finalizarlo). Bonificación máxima S/180 soles. La bonificación será enviada por correo mediante 03 envíos con la siguiente vigencia: 1er envío (12/12): Código único de un solo uso, por el valor de S/60, vigencia hasta el 18/12. 2do envío (19/12): Código único de un solo uso por el valor de S/60, vigencia hasta el 25/12. 3er envío (26/12): Código único de un solo uso por el valor de S/60, vigencia hasta el 30/12. No válido para compras mayoristas, sujeto a anulación del pedido. El pedido mínimo de las siguientes compras deberá ser el valor de S/260. Los cupones de bonificación se enviarán exclusivamente a través del correo electrónico que el cliente usó al participar de la promoción. Cupones de bonificación no compatibles con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compra realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones.</p>
                                    </li>

                                    <li><b>Bonificación lechones</b>:
                                    <p>Compra S/500 o más del 01 al 31 de diciembre a través de la WEB o APP de Freshmart.pe y recibe 01 Lechón al peso congelado (totalmente gratis). No es acumulable con otras promociones. Válido hasta agotar stock. Stock 115.</p>
                                    </li>

                                    <li><b>Regalo Espumante Anna Codorniu Dic22</b>:
                                    <p>Promoción válida para un pedido mínimo S/350 (incluyendo el espumante). Al usar el cupón REGALOAC se hace efectivo del 100% de descuento sobre el precio del producto. Válido para pedidos despachados desde el 19 al 22 (inclusive) de diciembre o hasta agotar stock, para compras por la WEB o APP de Freshmart.pe. Stock 100 unidades. No válido con otras promociones. Un uso de cupón por pedido. No válido para compras mayoristas. Precio sugerido para venta S/59 del espumante Cava Brut Rosé Anna Codorniu Botella 750 ml.</p>
                                    </li>
                                    
                                    <li><b>Cashback Licores Dic22 – Del 22 al 24/12/2022</b>:
                                    <p>Válido para pedidos creados y recibidos del 22 al 24 de diciembre de 2022. a través de la WEB o APP de Freshmart.pe. Con el cupón CBLICORESDIC en tu pedido mínimo de S/200 en la categoría LICORES (no incluye cervezas) + S/300 en las demás categorías. Incluye todas las modalidades de nuestro delivery que estén dentro de los distritos de cobertura en la fecha señalada. No válido para compras mayoristas. Freshmart se reserva el derecho de anular pedidos que no cumplan con estas condiciones. Cupón válido para un pedido por cliente/vivienda/usuario. Ejemplo pedido: compra S/300 en frutas, verduras, abarrotes, carnes u otra categoría. Agrega a tu carrito un monto mín. S/200 en licores (excepto cervezas) y para finalizar agregar el cupón CBLICORESDIC y listo! Ya estarás participando del cashback. Redención del cashback: Se entregará a los clientes que hayan cumplido las condiciones un cupón único con el valor de S/200 que podrán ser utilizados en todo el super de freshmart.pe, del 04 al 19 de enero de 2023. Pedido mínimo S/200 + S/200 de la bonificación cashback. El cupón de bonificación se enviará exclusivamente a través del correo electrónico que el cliente usó al participar de la promoción. Cupón de bonificación no compatible con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compras realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones.</p>
                                    </li>


                                    <li><b>40% en BEBIDAS Y LICORES</b>:
                                    <p>Pedido mínimo S/250 en todo el super. Válido del 26 al 30/12/2022. El descuento del 40% se aplicará solo a la categoría bebidas y licores ingresando el cupón: HOLA2023. No incluye cervezas. Beneficio máx.: S/150. No acumulable con otras promociones. No válido para compras mayoristas. Un uso por cliente/usuario/vivienda. Stock limitado de usos del cupón. Freshmart se reserva el derecho de anular los pedidos que no cumplan con estas condiciones.</p>
                                    </li>

                                    <li><b>Bonificación Regalo Pavo Redondos</b>:
                                    <p>Compra S/500 o más del 27 al 30 de diciembre de 2022 a través de la WEB o APP de Freshmart.pe y recibe 01 Pavo entero - redondos (7.5 kg aprox). Ingresando el cupón REGPAVODIC. Una bonificación por pedido. No es acumulable con otras promociones. Válido hasta agotar stock. Stock 100.</p>
                                    </li>

                                    <li><b>Promoción Pañales Dic2022 - Cupón: BEBEFRESH</b>:
                                    <p>Pedido mínimo S/200 en todo freshmart.pe. El descuento se aplica sobre el monto de los pañales dentro del carrito de compras. Máximo 04 paquetes de pañales. Válido del 28 al 30/12/2022 o agotar stock de usos de cupón. Beneficio máximo S/100. No acumulable con otras promociones. Descuento válido aplicando el cupón. Un uso por vivienda/cliente/usuario.</p>
                                    </li>

                                    <li><b>Cashback CBSOLYPLAYA ENERO 2023</b>:
                                    <p>Válido para pedidos creados y recibidos del 04 al 06 de enero 2023 a través de la WEB o APP de Freshmart.pe. Incluye pedidos despachado a playas. Pedido mínimo de S/300 soles. Para participar deberá ingresar el cupón CBSOLYPLAYA al momento de generar el pedido (antes de finalizarlo). Bonificación máxima de S/300 soles. La bonificación será enviada por correo mediante 03 envíos con la siguiente vigencia: 1er envío (09/01): Código único de un solo uso, por el valor de S/60, vigencia hasta el 15/01. 2do envío (16/01): Código único de un solo uso por el valor de S/60, vigencia hasta el 22/01. 3er envío (23/01): Código único de un solo uso por el valor de S/60, vigencia hasta el 29/01. No válido para compras mayoristas, sujeto a anulación del pedido. El pedido mínimo de las siguientes compras deberá ser el valor de S/260. Los cupones de bonificación se enviarán exclusivamente a través del correo electrónico que el cliente usó al participar de la promoción. Cupones de bonificación no compatibles con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compras realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones.</p>
                                    </li>

                                    <li><b>Promo 10% en pedidos para la playa - Enero 2023</b>:
                                    <p>Promoción válida para compras y despachos del 24 al 28 (inclusive) de enero de 2023. El descuento del 10% se aplicará ingresando el nombre del cupón SURGRANDE10 SURCHICO10 dependiendo la ubicación donde se encuentre. Sur Chico (hasta Naplo) y Sur Grande desde Chilca a Asia. Pedido mínimo de S/300 para aplicar el descuento y el beneficio máximo es de S/100. Un uso de cupón por pedido/vivienda/cliente. Stock limitado de usos de cupón. No válido para compras mayoristas. Promoción válida solo para los distritos de la playa.</p>
                                    </li>

                                    <li><b>Programación de Pedidos para Playa </b>:
                                        <p>Horario de corte: Pedidos hasta las 12 p.m. y recibe al día siguiente a partir de las 9:00 a.m.<br/>Horario de entrega: De 9 am a 9 pm <br/> NO atendemos EXPRESS ni SD</p>
                                        <p>Días de entrega:</p>
                                        <p>Sur Chico (Hasta Naplo):</p>
                                        <ul>
                                            <li>Despacho todos los días. De Lunes a Domingo <br/>Costo de envío: S/14.90</li>
                                        </ul>

                                        <br/>
                                        <p>Sur Grande (Chilca-Asia):</p>
                                            <ul>
                                                <li>Despachos Lunes y Viernes <br/>Costo de envío: S/19.90</li>
                                            </ul>

                                        </li>

                                        <br/><li><b>Cupones NUEVAFRESH y PRIMERAFRESH / 2023</b>:
                                        <p>Cupones válidos solo para nuevos registros. El cupón se hace efectivo en la primera compra por la web o app. Pedido mínimo S/120. Descuento de S/40. Vigencia del 06/01 al 06/03/2023 o agotar stock de usos. Un solo uso por cliente / usuario / vivienda. Descuento no es compatible con otros cupones y/o promociones. Beneficio exclusivo para una sola cuenta de correo electrónico. El valor de los cupones no se contabilizan en el programa Puntos Fresh. Cupones sujetos a stock limitado de usos. Freshmart se reserva el derecho de cancelar los cupones al momento de agotarse el stock promocional.</p>
                                    </li>

                                    <li><b>Cashback para clientes de 1ra compra - CB1ERFRESH Enero 2023</b>:
                                    <p>Promoción exclusiva para nuevos clientes que pidan y reciban su pedido hasta el 31/01/2023 (inclusive). Ingresando el cupón: CB1ERAFRESH al momento de generar el pedido y antes de finalizarlo. Bonificación máxima de S/200 soles. La bonificación del cashback será enviada por correo a partir del 02/02/2023 al correo que registrado de 1ra compra. La bonificación se entregará en un cupón de dos usos con la cantidad del valor del cashback. Ejemplo: Por un pedido de S/100 se devuelve un cupón con dos usos de S/25, total de devolución: S/50. El pedido mínimo de las siguientes compras, y para hacer efectiva la bonificación, deberá ser el valor del cupón más S/35. El cupón de bonificación se enviará exclusivamente a través del correo electrónico que el cliente usó para realizar su primera compra. Los cupones de bonificación no son compatibles con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compras realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones. No válido para compras mayoristas. Freshmart.pe se reserva el derecho de anular el pedido en caso no cumpla con las condiciones descritas.</p>
                                    </li>

                                    <li><b>35% OFF en frutas y verduras - Del 12 al 14 de Enero 2023</b>:
                                    <p>Promoción válida para pedidos con despacho hasta el 14/01/2023. Pedido mínimo S/200 en abarrotes y un ped mín. S/50 en frutas y verduras. Beneficio máx.: S/65 . No incluye frutas y verduras congeladas ni frutos secos. Un uso de cupón por pedido. No válido para compras mayoristas.</p>
                                    </li>                                    
                                    

                                    <li><b>Cashback SUPERFRESHCB – Del 03 al 07 de febrero 2023</b>:
                                    <p>Promoción válida para todos los clientes activos y recurrentes de Freshmart.pe El cliente debe ingresar el cupón SUPERFRESHCB en su pedido mínimo de S/300 para participar de esta promoción. Un uso del cupón por cliente/vivienda/usuario. No válido para compras mayoristas. El cupón no es acumulable con otras promociones. Redención de la bonificación: se entregará un cupón de 02 usos con el 50% del pedido inicial con el que ingresó a la promoción. El cupón se entregará el 08/02/2023 mediante correo con el que hizo el pedido para participar de esta promoción. Vigencia del cupón de redención, para pedidos creados y despachados del 08 al 14/02/2023 (inclusive), no válido para pedidos programados después de esta fecha. El pedido mínimo de las siguientes compras y para hacer efectivo la bonificación deberá ser el valor del cupón más S/35. El cupón de bonificación se enviará exclusivamente a través del correo electrónico que el cliente usó para realizar su primera compra. Los cupones de bonificación no son compatibles con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compras realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones. No válido para compras mayoristas. Freshmart.pe se reserva el derecho de anular el pedido en caso no cumpla con las condiciones descritas.</p>
                                    </li>

                                    <li><b>Cashback VUELVEMASFRESH – Del del 03/02 al 12/02/2023</b>:
                                    <p>Promoción exclusiva para clientes que no realizaron ningún pedido hace 06 meses. El cliente debe ingresar el cupón VUELVEMASFRESH en su pedido mínimo de S/230 para participar de esta promoción. Un uso de cupón por cliente/vivienda/usuario. No válido para compras mayoristas. El cupón no es acumulable con otras promociones. Redención de la bonificación: la bonificación de Cashback tendrá un monto fijo y será dividido en 03 cupones únicos, los cuales se entregarán por separado y serán enviados por correo con el que participó del Cashback. Fechas de entregas: Primer cupón: 14/02 y se podrá usar hasta el 19/02. Segundo cupón: se enviará el 20/02 y podrá usarlo hasta el 26/02. Tercer y último cupón: se enviará el 27/02 y podrá usarlo hasta el 05/03. El pedido mínimo para hacer uso del cupón de redención deberá ser de S/120. Ejemplo: recibes S/200 de bonificación, se divide en 03 cupones de S/ 67. Monto mínimo de pedido para incluir el cupón: S/ 120.  Vigencia del cupón de redención, para pedidos creados y despachados del 14/02 al 05/03/2023 (inclusive), no válido para pedidos programados después de esta fecha. El cupón de bonificación se enviará exclusivamente a través del correo electrónico que el cliente usó para realizar su compra. Los cupones de bonificación no son compatibles con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compras realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones. No válido para compras mayoristas. Freshmart.pe se reserva el derecho de anular el pedido en caso no cumpla con las condiciones descritas.</p>
                                    </li>
                                  
                                    <li><b>SORTEO Corona Sunsets World Tour:</b>:
                                    <p>Participan compras mayores a s/59.90 en los productos de Corona. La vigencia es del 20/02/23 hasta el domingo 19/03/23. El sorteo se hará el 20/03/23. Habrán 2 ganadores de manera aleatoria. Primer ganador: 1 Entrada doble al corona sunsets en máncora, Piura el sábado 8 de abril. 2do ganador: 1 Corona Kit.
                                    <br/>Aplican los productos:
                                    <ul>
                                        <li>6pack Corona botella 355ml</li>
                                        <li>6pack Corona Lata 355ml</li>
                                        <li>6pack Coronita botella 210ml</li>
                                    </ul>
                                    </p>
                                    </li>                                    


                                    <li><b>50MARZOCB– Del 27 al 02 de marzo 2023:</b>:
                                    <p>Promoción válida para todos los clientes activos y recurrentes de Freshmart.pe del 27/02 hasta el 02/03 de 2023. El cliente debe ingresar el cupón 50MARZOCB en su pedido mínimo de S/300 en todo el super para participar de esta promoción. Un uso del cupón por cliente/vivienda/usuario. No válido para compras mayoristas. El cupón no es acumulable con otras promociones. No se considerarán pedidos programados para después del 02/03. Freshmart.pe se reserva el derecho de anular los pedidos que no consideren los términos mencionados.<br/>Redención de la bonificación: la bonificación de Cashback tendrá un monto fijo y será enviado en 1 cupón único de doble uso por medio del correo con el que participó del Cashback. Beneficio máximo S/200.<br/> Fecha de entrega: 04 de marzo de 2023<br/>Válido para pedidos creados y despachados desde el 04/03 hasta el 19/03. No aplica para pedidos programados después del 19/03. El pedido mínimo para hacer uso del cupón de redención deberá ser de S/35 + el valor del cupón. Ejemplo: Si recibes S/100 de bonificación, tu cupón de doble uso tendrá el valor de S/50  y el monto mínimo en tus dos pedidos para hacerlo efectivo será S/85.<br/><b>Los cupones de bonificación no son compatibles con otros cupones y/o promociones. Bajo ninguna circunstancia aplicaremos el descuento al momento de la entrega del pedido. Stock limitado de promociones por día. Las compras realizadas con cupones de bonificación no estarán afectas a cambios ni devoluciones. No válido para compras mayoristas.</b></p>
                                    </li>

                                </ul>
                            </li>

                                    <li className="color-orange-li">
                                    <p className="color-orange font-weight-bold">Tienda Miraflores Pet Friendly:</p>
                                    <p>‌Nuestros términos y condiciones para que la pases fresh cuando nos visites con tu mascota:
                                    <ul>
                                    <li> Deben estar atentos y no separarse de su mascota durante toda su visita en nuestra tienda.</li>
                                    <li>Todo cliente que ingrese a la tienda en compañía de su mascota debe ser mayor de edad.</li>
                                    <li>Las mascotas deben llevar collar, correa y bozal (en caso sea necesario).</li>
                                    <li>El cliente debe velar por la limpieza de los desechos de su mascota (debe contar con los implementos adecuados)</li>
                                    <li>Todas las mascotas que ingresen a Freshmart deben estar vacunadas.</li>
                                    <li>La mascota debe estar acostumbrada a compartir espacios con otras personas y no ser agresivo.</li>
                                    <li>Está permitido el ingreso de toda especie doméstica que vive en el entorno humano familiar, cuyos actos puedan ser controlados por el dueño o persona encargada.</li>
                                    <li>El cliente se hace responsable de las acciones de su mascota en su totalidad.</li>
                                    <li>En caso de maltrato animal o abandono, se podrá dar aviso a las autoridades que correspondan.</li>
                                    </ul>
                                    ¡Cuida siempre de tu amigo de cuatro patas!<br/>
                                    No olvides compartir con nosotros una foto de tu visita a nuestra tienda.                                    
                                    </p>
                                    </li>

                        </ol>
                        <p></p>
                    </article>
                </div>
    )
}
export default RawTermCondition;