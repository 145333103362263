import {useState, useEffect} from 'react'
import DireccionesShipping from './components/DireccionesShipping'
import DireccionFiscal from './components/DireccionFiscal'

import PageMisPedidos from './components/PageMisPedidos'
import User from './components/User'
import Category from './components/Category'

import App from './App'
import Pageterminosycondiciones from './components/Pageterminosycondiciones'
import Pagepoliticadeprivacidad from "./components/Pagepoliticadeprivacidad";
import Pagepoliticasdecookies from "./components/Pagepoliticasdecookies";
import Pagequienessomos from "./components/Pagequienessomos";
import Pagepreguntasfrecuentes from "./components/Pagepreguntasfrecuentes";
import PageDetailblog from "./components/PageDetailblog";


import PageCarrito from "./components/PageCarrito"
import PageDespacho from "./components/PageDespacho"
import PagePayment from "./components/PagePayment"
import PageMiperfil from "./components/PageMiperfil"
import PageDireccion from "./components/PageDireccion"
import PageCambiarClave from "./components/PageCambiarClave"
import PageMisFacturaciones from "./components/PageMisFacturaciones"
import PageBuscar from "./components/PageBuscar"
import PageLogin from "./components/PageLogin";
import PageMobileAuth from "./components/PageMobileAuth";
import PageMobileAuthRegister from "./components/PageMobileAuthRegister";
import PageRegistro from "./components/PageRegistro";
import PageRecoverPassword from "./components/PageRecoverPassword";
import PageRecoverPasswordMobile from "./components/PageRecoverPasswordMobile";
import DetalleProducto from "./components/DetalleProducto";
import PageTransversal from "./components/PageTransversal";

import PageConfirmacion from "./components/PageConfirmacion";
import PageBlog from "./components/PageBlog";
import PageMistarjetas from "./components/PageMistarjetas";

import PageHalloween from "./components/PageHalloween";
import PagePreguntasActivacion from "./components/PagePreguntasActivacion";

import PageContacto from "./components/PageContacto";
import PageLibroReclamos from "./components/PageLibroReclamos";
import PageFreshclubPuntosfresh from "./components/PageFreshclubPuntosfresh";
import Home from "./components/Page/Home";
import PageTeam from "./components/Page/PageTeam";
import PageTeamNew from "./components/Page/PageTeamNew";
import PageTeamEdit from "./components/Page/PageTeamEdit";

import PageClient from "./components/Page/PageClient";
import PageClientNew from "./components/Page/PageClientNew";
import PageClientEdit from "./components/Page/PageClientEdit";

import PageProvider from "./components/Page/PageProvider";
import PageProviderNew from "./components/Page/PageProviderNew";
import PageProviderEdit from "./components/Page/PageProviderEdit";

import PageMisProductos from "./components/Page/PageMisProductos";
import PageProductNew from "./components/Page/PageProductNew";
import PageProductEdit from "./components/Page/PageProductEdit";


import PageCategory from "./components/Page/PageCategory";
import PageCategoryNew from "./components/Page/PageCategoryNew";
import PageCategoryEdit from "./components/Page/PageCategoryEdit";
import PageEmailSignature from "./components/Page/PageEmailSignature";

import PagePedido from "./components/Page/PagePedido";
import PagePedidoNew from "./components/Page/PagePedidoNew";
import PagePedidoView from "./components/Page/PagePedidoView";

import HeaderTop from './components/HeaderTop'
import Menu from './components/Menu'


    var myLazyLoad = new window.LazyLoad({
        elements_selector: ".shop-lazy-image",
        callback_load: function(element){
          //window.$(element).parent('span').addClass('loaded');
          window.$(element).removeAttr('data-original','');
          window.$(element).removeClass('shop-lazy-image');
          

        },
        callback_error: function(element){
          window.$(element).parent('span').addClass('loaded');
          window.$(element).attr('src',window.path_service+'/images/no-image.png').removeClass('error').addClass('loaded');
          window.$(element).attr('data-origin',window.path_service+'/images/no-image.png');
        }
    });

    window.myLazyLoad = myLazyLoad;

    window.listMoneda = [
        {id :1 , name:"Soles", simbolo :"S/." },
        {id :2 , name:"Dolar", simbolo : "$"},
        {id :3 , name:"Euro",simbolo : "€" }
    ]

window.currencyFormat = (data,moneda = 2) =>{
    data = parseFloat(data)
    const datMoneda = window.listMoneda.filter(function(params) {
        return params.id == parseInt(moneda)
    })
    return (datMoneda.length === 1 ? datMoneda[0].simbolo : window.listMoneda[1].simbolo) + data.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const AppPages = ({form}) => {
    const [miproductos, setMiproductos] = useState([])
    const [validateproductos, setValidateproductos] = useState([])
    const [productosNoStock, setProductosNoStock] = useState([])
    const [productosModified, setProductosModified] = useState([])

    const [user, setUser] = useState([])
    const [stats, setStats] = useState({customers: 0,products:0})

    const [subTotal, setSubTotal] = useState("0.00")
    const [itemshopping, setItemshopping] = useState([])
    const [resumen, setResumen] = useState([])

    const [expressActive, setExpressActive] = useState(false)
    const [methodShopping, setMethodShopping] = useState("schedule")
    const [pashipping, setPashipping] = useState(0)
    const [paFiscal, setFiscal] = useState(false)
    const [menus, setMenus] = useState([])
    const [transversal, setTransversal] = useState([])

    const handleMiproductos = (data) =>{
        setMiproductos(data)
    }

    const handleSubTotal = (data) =>{
        setSubTotal(data)
    }

    const myListCart = async () =>{
        var myHeaders = [];
        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
        }
            
        const response = await fetch(window.path_service+"/admin/detail", { method:'POST' , headers: myHeaders})
        const data = await response.json()

        if (data.status && data.hasOwnProperty("user") ) {
            setUser(data.user)

            if ( data.user.hasOwnProperty("email") && data.user.email !== "" ) {
                
                localStorage.emailUser = data.user.email;

                if (window.pagenow !== "mi-perfil" && (data.user.has_dni === false || data.user.has_phone === false) ) {
                    window.location.href = "/mi-perfil";
                }
                
            }else{
             window.location.href = "/login";   
            }
        }else{
            localStorage.removeItem("token_authlead");
        }


        const responsestats = await fetch(window.path_service+"/admin/stats", { method:'POST' , headers: myHeaders})
        const datastats = await responsestats.json()

            if ( datastats.hasOwnProperty("status") && datastats.status ) {
                setStats(datastats.stats)
            }


        if ( ["",undefined,"undefined"].includes( localStorage.token_authlead ) && !["login","registro","recuperar-contrasena"].includes(window.pagenow) ) {
            window.location.href = "/login";
            console.log("entro");
        }

    }

    const validateProductExpress = async() =>{
        if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
            const dataq = new FormData();       
            dataq.append("id_comercio", localStorage.getItem('comercioId') );

            const response_service = await fetch(window.path_service+"/api/verify_checkexpress_product", { method:'POST',body:  dataq })
            const response = await response_service.json()
            if (response.status) {
                setValidateproductos(response.data)
                window.$("#modalListOrdenPedido").modal({
                    backdrop: 'static',
                    keyboard: false
                }).modal("show")                
            }

        }
    }


    const actualizarNostock = () =>{
        window.$("#modalListProductNoStock").modal("hide");
    }

    const actualizarnuevoStock = () =>{
        window.$("#modalListProductChange").modal("hide");
    }


    const removeProduct = async(product_id) =>{    
      window.Swal.fire({
        title: 'Eliminar Producto',
        text: '¿Esta seguro de querer eliminarlo de tu carrito?',
        icon: 'error',
        showCancelButton: true,
        cancelButtonColor: "#666",
        confirmButtonColor: "#13a538",
        confirmButtonText: 'Si',
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          xhrremoveProduct(product_id)
        }
      })
    }

    const xhrremoveProduct = async(product_id) =>{    
      if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );              

        const dataq = new FormData();
        dataq.append("id_producto", product_id );
        const response_service = await fetch(window.path_service+"/api/remove/product-cart", { method:'POST',body:  dataq, headers: myHeaders })
        const response = await response_service.json()
        if (response.status) {
            window.modaLoadingcart = 
                window.Swal.fire({
                    imageUrl: window.path_service+'/images/loading.png',
                    title: 'Limpiando el carrito...',
                    showConfirmButton: false,
                    customClass: 'ms-loading-modal',
                    allowOutsideClick: false
                });            
            myListCart();
        }else{
            myListCart();
        }
        //const data = await response
        

      }else{
        let ecart = JSON.parse(localStorage.getItem('my_shopping_car'));
        var existProduct = ecart.map(function(item){ return item['id']; }).indexOf( product_id );
        if( existProduct !== -1 ){
          ecart.splice(existProduct,1);
        }
        localStorage.setItem('my_shopping_car', JSON.stringify(ecart) );    
        myListCart();
      }
    }

    const loadMenu = (value) => {
        setMenus(value)
    }
    const loadTransversal = (value) => {
        setTransversal(value)
    }

    const changeHasExpress = (value) => {
        setExpressActive(value)
    }

    const changeMethodShopping = (value) => {
        setMethodShopping(value)
        localStorage.methodshopping = value;

        if (value === "schedule") {
            localStorage.comercioId = 1;
        }
       
        if ( ![undefined,"","undefined"].includes(localStorage.distritoPay) ) {
            window.$("#modalSelectionDistrito").modal("hide");
        }

        if (["express","recojotienda"].includes(localStorage.methodshopping)) {
            document.body.classList.add('express')
            window.$("body").addClass("express")
        } else {
            window.$("body").removeClass("express")
        }

        /*
        if (["carrito","payment","despacho"].includes(window.pagenow)) {
            myListCart();
        }
        */
        myListCart();
    }

    useEffect(() => {

    if ( ["login","registro","forgot-pass"].includes(window.pagenow) ) {
          window.$("body").removeClass('nav-md');
          window.$("body").addClass('login');
          localStorage.clear()
    }else{
        myListCart();
    }

    }, []);

    return (
        <div>
        {
            !["login","registro","forgot-pass"].includes(form) ?
                <HeaderTop />
            : null
        }        
        

            <div className="wrap-body">
            {
                !["login","registro","forgot-pass"].includes(form) ?
                    <Menu form={form} stats={stats} />
                    :
                    null
                }

                <main className="wrap-content page-interna">
                    <div className="page-content">

                        {
                            form === "home" || form === "" ?
                                <PagePedido
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                            : null
                        }


                        {
                            form === "mis-tarjetas" ?
                                <PageMistarjetas
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }            

                        {
                            form === "mis-productos" || form ==="products" ?
                                <PageMisProductos
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }            

                        {
                            !form.includes("products/new") && form.includes("products/")  ?
                                <PageProductEdit
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }   

                        {
                            form ==="products/new" ?
                                <PageProductNew
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }   

                        {
                            form === "category" ?
                                <PageCategory
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }            

                        {
                            form ==="category/new" ?
                                <PageCategoryNew
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }   

                        {
                            !form.includes("category/new") && form.includes("category/")  ?
                                <PageCategoryEdit
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />

                                :
                                null
                        }   

                        {
                            form === "mis-pedidos" ?
                                <PageMisPedidos
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />     
                                :
                                null
                        }

                        {
                            form === "terminos-y-condiciones" || form === "terminos-condiciones" ?
                                <Pageterminosycondiciones
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "politica-de-privacidad" ?
                                <Pagepoliticadeprivacidad
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "contacto" ?
                                <PageContacto
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        
                        {
                            form === "libro-reclamos" ?
                                <PageLibroReclamos
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "confirmacion" ?
                                <PageConfirmacion
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }
                        

                        {
                            form === "carrito" || form === "appcart" ?
                                <PageCarrito
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                    form = {form}
                                />
                                :
                                null
                        }         

                        {
                            form === "despacho" || form === "appdespacho" ?
                                <PageDespacho
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                    form = {form}
                                />                     
                                :
                                null
                        }     

                        {
                            form === "payment" || form === "apppayment"  ?
                                <PagePayment
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                    form = {form}
                                />                    
                                :
                                null
                        }                 

                        
                        
                        {
                            form === "mis-direcciones" ?
                                <PageDireccion
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }                 

                        {
                            form === "cambiar-clave" ?
                                <PageCambiarClave
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        {
                            form === "mis-facturaciones" ?
                                <PageMisFacturaciones
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }
                        {
                            form === "mi-perfil" ?
                                <PageMiperfil
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }                 


                        {
                            form === "mi-firma-email" ?
                                <PageEmailSignature
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    validateproductos = {validateproductos}
                                    itemshopping = {itemshopping}
                                    resumen = {resumen}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }                 

                        
                        {
                            form === "politicas-de-cookies" ?
                                <Pagepoliticasdecookies
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "quienes-somos" ?
                                <Pagequienessomos
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "preguntas-frecuentes" || form === "page/preguntas-frecuentes" ?
                                <Pagepreguntasfrecuentes
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }


                        {
                            form === "freshclub-puntos-fresh" ?
                                <PageFreshclubPuntosfresh
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }


                        {
                            form === "detail-blog" ?
                                <PageDetailblog
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        


                        {
                            form === "buscar" ?
                                <PageBuscar
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }


                        {
                            form === "login" ?
                                <PageLogin
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        {
                            form === "login-mobile" ?
                                <PageMobileAuth
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }


                        {
                            form === "recuperar-contraseña-mobile" ?
                                <PageRecoverPasswordMobile
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        {
                            form === "register-mobile" ?
                                <PageMobileAuthRegister
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        
                        {
                            form === "registro" ?
                                <PageRegistro
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }
                        


                        {
                            form === "recuperar-contraseña" || form === "forgot-pass" ?
                                <PageRecoverPassword
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }


                        {
                            form === "detalle-producto" ?
                                <DetalleProducto
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />                    
                                :
                                null
                        }

                        {
                            form === "team" ?
                                <PageTeam
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "team/new" ?
                                <PageTeamNew
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }                    


                        {
                            !form.includes("team/new") && form.includes("team/")  ?
                                <PageTeamEdit
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />

                                :
                                null
                        }                       


                        {
                            form === "customer" ?
                                <PageClient
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "customer/new" ?
                                <PageClientNew
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }                    


                        {
                            !form.includes("customer/new") && form.includes("customer/")  ?
                                <PageClientEdit
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />

                                :
                                null
                        }                       


                        {
                            form === "provider" ?
                                <PageProvider
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "provider/new" ?
                                <PageProviderNew
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }                    


                        {
                            !form.includes("provider/new") && form.includes("provider/")  ?
                                <PageProviderEdit
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />

                                :
                                null
                        }   



                        {
                            form === "orders" ?
                                <PagePedido
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }

                        {
                            form === "orders/new" ?
                                <PagePedidoNew
                                    user = {user}
                                    removeProduct = {removeProduct}
                                    subTotal = {subTotal}
                                    miproductos = {miproductos}
                                    myListCart = {myListCart}
                                    handleMiproductos = {handleMiproductos}
                                    handleSubTotal = {handleSubTotal}
                                    expressActive={expressActive}
                                    methodShopping={methodShopping}
                                    changeHasExpress={changeHasExpress}
                                    changeMethodShopping={changeMethodShopping}
                                    loadMenu={loadMenu}
                                    loadTransversal={loadTransversal}
                                    menus={menus}
                                    transversal={transversal}
                                    myLazyLoad = {myLazyLoad}
                                />
                                :
                                null
                        }                    



                        {
                            !form.includes("orders/new") && form.includes("orders/")  ?
                                <PagePedidoView
                                    user = {user}
                                    myListCart = {myListCart}
                                    myLazyLoad = {myLazyLoad}
                                />

                                :
                                null
                        }   



                    </div>
                </main>
            </div>

        </div>
    )
}

export default AppPages;