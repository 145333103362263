import { useState,useEffect } from 'react'
import Input from '../Input'
import InputEmail from '../Input/InputEmail'
import InputPhone from '../Input/InputPhone'
import InputPassword from '../InputPassword'
import CuentaBancaria from '../Form/CuentaBancaria'
import Select from '../Select'
import DireccionesShipping from '../DireccionesShipping'
import Tabform from '../Tabform'

const Client = ({type_page}) =>{
	const [value, setValue ] = useState( 
		{
			id:null,
			name:"",
			lastname:"",
			gender:"m",
			birthday:null,
			phone:"",
			dni:"",
			address:[],
			cuenta_bancaria : [],
			email:"",
			year:"",
			month:"",
			clave:"",
			status:"1",
			day:""
		} 
	)

	const range = (start, end, step = 1) => {
	  let output = [];
	  if (typeof end === 'undefined') {
	    end = start;
	    start = 0;
	  }
	  for (let i = start; i < end; i += step) {
	    output.push(i);
	  }
	  return output;
	};
	const year_now = new Date().getFullYear();
	const year_max = year_now-17;
	const  year_min = year_now-79;
	const years = range(year_min,year_max).reverse();
	const months = [
		{id:"01",name:"Enero"},
		{id:"02",name:"Febrero"},
		{id:"03",name:"Marzo"},
		{id:"04",name:"Abril"},
		{id:"05",name:"Mayo"},
		{id:"06",name:"Junio"},
		{id:"07",name:"Julio"},
		{id:"08",name:"Agosto"},
		{id:"09",name:"Setiembre"},
		{id:"10",name:"Octubre"},
		{id:"11",name:"Noviembre"},
		{id:"12",name:"Diciembre"}
	];

	const getDaysInMonth =() => {
		let month = parseInt(value.month);
		const year =  parseInt(value.year);

		if ( isNaN(month) || isNaN(year)  ) {
			return [];
		}

	    month = month -1;
	  var date = new Date(year, month, 1);
	  var days = [];
	  while (date.getMonth() === month) {
	    days.push(new Date(date).getDate());
	    date.setDate(date.getDate() + 1);
	  }
	  return days;
	}


	const handleCuentaBancaria = (list) => {
		setValue((state) => (
		{
	      ...state,
	      cuenta_bancaria: list
	    }
	    ))	 		
	}		
	

	const handleAddress = (list) => {
		/*
		setValue((state) => (
		{
	      ...state,
	      address: list
	    }
	    ))	 		
	    */
	}

	const handleChangePhone = (e) => {
		value.phone = e;
		console.log(e)
	}

	
	const tabChange = (tab) => {
		console.log( window.$("."+tab).hasClass("active") )
		if ( !window.$("."+tab).hasClass("active") ) {
			window.$(".wrap-tab-master li").removeClass("active");
			//window.$("."+tab).addClass("active");
			window.$(this).addClass("active");
			console.log(tab)
		}
	}

	
	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}else{

				if ( !isNaN(window.detailpath) ) {
					getApiData();
				}

			}
	}, []);


	const getApiData = async () => {
			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/customer/show",{method:'POST',body: JSON.stringify({id:window.detailpath}), headers: myHeaders})
				const data = await response.json()
		       

		       if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") ) {

				  	let vyear="", vmonth="", vday="",vbirthday = null;
				  	
				  	if ( !["",undefined,null].includes(data.data.birthday)  ) {
				  		const date = new Date(data.data.birthday);
				  		vyear = date.getFullYear();
				  		vday = date.getDate();
				  		vmonth = (date.getMonth()+1).toString().padStart(2,0);
				  	}
				  	
				  	vbirthday = value.year+"-"+value.month+"-"+value.day;

					setValue((state) => (
							{
						      ...state,
							id : data.data.id,
							name: data.data.name,
							lastname:data.data.lastname,
							gender: data.data.gender,
							birthday:vbirthday,
							phone: "+"+data.data.phone,
							dni: data.data.dni,
							email: data.data.email,
							cuenta_bancaria: data.data.cuenta_bancaria,
							status:String(data.data.status),
							year: vyear,
							month: vmonth,
							clave:"",
							day: vday								      
						    }
					))	 	
					/*
					setValue({
						id : data.data.id,
						name: data.data.name,
						lastname:data.data.lastname,
						gender: data.data.gender,
						birthday:vbirthday,
						rol: data.data.role,
						phone: "+"+data.data.phone,
						dni: data.data.dni,
						address:[],
						email: data.data.email,
						status:String(data.data.status),
						year: vyear,
						month: vmonth,
						clave:"",
						day: vday						
					} )	
					*/				

		        }
		    }		

	};

	const submit = async(event) =>{
		event.preventDefault();

		value.clave=value.dni;

		let modaLoading;
		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

			    const dataq = new FormData();
				const datavalue = Array.from(new FormData(event.target))
			    for(let [key, val] of datavalue) {
			    	dataq.append(key, val );
			    }	
			    value.birthday = value.year+"-"+value.month+"-"+value.day;
			    
			    let apiRequest = window.path_service+"/customer/add"

			    if ( type_page === "edit"  ) {			    	
			    	apiRequest = window.path_service+"/customer/update";
			    }

				const response = await fetch( apiRequest ,{method:'POST',body:  JSON.stringify(value) , headers: myHeaders})
				const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {
					window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			        });         					
					
						if ( type_page === "new"  ) {
							setTimeout(() => {
								window.location.href = "/customer";
							}, 3500);
						}	

				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 					
				}
				//reset();
			}
			modaLoading.close();			
	}

	console.log(value)

	return (
		<div className="ms-form-payment-register">
			<Tabform tabs={[
						{id:1,title:"información",tabname:"tab-one",active: true},
						{id:2,title:"Dirección",tabname:"tab-two",active: false},
						{id:3,title:"Cuentas Bancarias",tabname:"tab-three",active: false},
						//{id:3,title:"Telefono",tabname:"tab-three",active: false}
					]} />
			
		            	<div className="tab-two tab-content">
		            		<DireccionesShipping litAddress={value.address} handleAddress={handleAddress} />
		            	</div>

		            	<div className="tab-three tab-content">
		            		<CuentaBancaria litCuenta={value.cuenta_bancaria} handleCuentaBancaria={handleCuentaBancaria} />
		            	</div>

		            <form className="fm-form-user" onSubmit={submit}>

		            	<div className="fm-form-user tab-one tab-content active">

						      <Input 
						        label ="Nombre*"
						        validated="yes"
						        name="name"
						        value = {value.name}
						        onChange = {handleChangeForm}
						        />

						      <Input 
						        label = "Apellidos*"
						        validated="yes"
						        name="lastname"
						        value = {value.lastname}
						        onChange = {handleChangeForm}
						        />

						      <fieldset className="groupForm">
					              <div className="ms-item fm-block-genero">
					                <label>gender<span>*</span></label>
					                  <div className="ms-item ms-checbox">
					                    <input type="radio" className="js-profile-gender" name="gender" id="female" value="f" 
					                    checked={value.gender === "f"} onChange = {handleChangeForm}
					                      />
					                    <label htmlFor="female">Femenino</label>
					                  </div>

					                  <div className="ms-item ms-checbox">
					                    <input type="radio" className="js-profile-gender" name="gender" id="male" value="m" 
					                    checked={value.gender === "m"} onChange = {handleChangeForm}
					                      />
					                    <label htmlFor="male">Masculino</label>
					                  </div>
					              </div>
				             </fieldset>

				             <fieldset className="groupForm">
					              <div className="ms-item fm-block-genero">
					                  <div className="ms-item ms-checbox">
					                    <input type="radio" className="js-profile-gender" name="status" id="statusActive" value="1" 
					                    checked={value.status === "1"} onChange = {handleChangeForm}
					                      />
					                    <label htmlFor="statusActive">Activo</label>
					                  </div>

					                  <div className="ms-item ms-checbox">
					                    <input type="radio" className="js-profile-gender" name="status" id="statusInactive" value="2" 
					                    checked={value.status === "2"} onChange = {handleChangeForm}
					                      />
					                    <label htmlFor="statusInactive">Desactivado</label>
					                  </div>
					              </div>
				              </fieldset>

						        <div className="ms-item fm-block-birthday">
						        <label>Fecha de Nacimiento*</label>
							        <Select
								        label = ""
								        validated="yes"
								        name="year"
								        value = {value.year}
								        onChange = {handleChangeForm}
							        >
					                  <option value="">Año</option>
					                  {
					                  	years.map(
					                  	year =>
					                  		<option key={year} value={year}>{year}</option>
					                  	)
					                  }
							        </Select>


							        <Select
								        label = ""
								        validated="yes"
								        name="month"
								        value = {value.month}
								        onChange = {handleChangeForm}
							        >
					                  <option value="">Mes</option>
					                  {
					                  	months.map(
					                  	month =>
					                  		<option key={month.id} value={month.id}>{month.name}</option>
					                  	)
					                  }
							        </Select>


							        <Select
								        label = ""
								        validated="yes"
								        name="day"
								        value = {value.day}
								        onChange = {handleChangeForm}
							        >
					                  <option value="">Día</option>
					                  {
					                  	getDaysInMonth().map(
					                  	day =>
					                  		<option key={day} value={day}>{day}</option>
					                  	)
					                  }
							        </Select>
						        </div>
						      
								<InputPassword 
									label = "Contraseña"
								    validated="yes"
								    name="clave"
								    placeholder="Password"
								    value = {value.clave}
								    classWrap="ms-item"
								    onChange = {handleChangeForm}
								    /> 


							<InputPhone 
								label = "Teléfono*"
							    validated="yes"
							    name="phone"
							    value = {value.phone}
							    classWrap="ms-item"
							    clasInput="fm-form-input"
							    onChange = {handleChangePhone}
							    />


						      <InputEmail 
						        label = "Email"
						        validated="yes"
						        name="email"
						        value = {value.email}
						        onChange = {handleChangeForm}
						        />            


						      <Input 
						        label = "Dni*"
						        validated="yes"
						        name="dni"
						        value = {value.dni}
						        onChange = {handleChangeForm}
						        />


		            	</div>


		                   {
						    (
						    	value.name !== "" && 
							    value.lastname !== "" && 
							    value.phone !== "" && 
							    value.dni  !== "" && 
							    value.year  !== "" && 
							    value.month !== "" && 
							    value.day   !== "" 							    
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Cambios</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Cambios</button>
		                   </div>		                   
		                   }

		            </form>

		</div>
	)
}

export default Client;