import Header from './Header'
import Footer from './Footer'

const Pagepreguntasfrecuentes  = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>
            <Header
                user = {user}
                myLazyLoad = {myLazyLoad}
                loadMenu = {loadMenu}
                loadTransversal = {loadTransversal}
                menus = {menus}
                transversal = {transversal}
                changeHasExpress = {changeHasExpress}
                changeMethodShopping = {changeMethodShopping}
                methodShopping = {methodShopping}
                expressActive = {expressActive}
                subTotal = {subTotal}
                miproductos = {miproductos}
                list_cart = {myListCart}
                handleMiproductos = {handleMiproductos}
                handleSubTotal = {handleSubTotal}
                removeProduct = {removeProduct}
            />

            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm">
                                <a className="links-breadcrumb" href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                Preguntas Frecuentes
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-2">Preguntas recuentes</h2>
                        <div className="page-body">
                            <p className="color-green font-weight-bold">Preguntas Frecuentes</p>
                            <p className="color-green font-weight-bold">Sobre el producto</p>

                            <p className="color-orange fw-bold">1. ¿Quiénes somos?</p>

                            <p>Somos un supermercado online. Funcionamos a través de la modalidad de delivery. Vendemos abarrotes, frutas, verduras, lácteos, carnes, pescados, aves, licores & bebidas, artículos de cuidado personal, artículos de limpieza, panadería & pastelería, accesorios para bebés, productos para mascotas, útiles de librería y bazar.
                            </p>

                            <p className="color-orange fw-bold">2. ¿Llegan a mi zona de reparto?</p>

                            <p className="color-orange fw-bold">Zonas de cobertura</p>

                            <p className="color-green">De lunes a domingo:</p>

                            <ul>
                                <li>Barranco</li>
                                <li>Breña</li>
                                <li>Jesús María</li>
                                <li>La Molina</li>
                                <li>Los Nogales (El Agustino)</li>
                                <li>Lince</li>
                                <li>Magdalena</li>
                                <li>Mayorazgo (Ate)</li>
                                <li>Miraflores</li>
                                <li>Pueblo Libre</li>
                                <li>Salamanca (Ate)</li>
                                <li>San Borja</li>
                                <li>San Isidro</li>
                                <li>San Luis</li>
                                <li>San Miguel</li>
                                <li>Santa Catalina (La Victoria)</li>
                                <li>Surco</li>
                                <li>Surquillo</li>
                            </ul>

                            <p className="color-green">De martes a viernes:</p>

                            <ul>
                                <li>Bellavista</li>
                                <li>Carabayllo</li>
                                <li>La encantada de villa (Chorrillos)</li>
                                <li>La Punta y Chucuito</li>
                                <li>Puente Piedra</li>
                            </ul>

                            <p className="color-green">De martes a jueves:</p>

                            <ul>
                                <li>Chaclacayo</li>
                            </ul>

                            <p className="color-green">De Lunes & sábado:</p>

                            <ul>
                                <li>Chorrillos</li>
                                <li>Comas</li>
                                <li>Independencia</li>
                                <li>Lima Cercado</li>
                                <li>Los Olivos</li>
                                <li>San Martin de Porras</li>
                                <li>Zarate</li>

                            </ul>

                            <p className="color-green">Lunes, jueves y viernes:</p>

                            <ul>
                                <li>Lurin</li>
                            </ul>

                            <p className="color-green">De martes a sábado:</p>
                            <ul>
                                <li>San Juan de Miraflores</li>
                                <li>Santa Anita</li>
                                <li>Villa Maria del Triunfo</li>
                            </ul>

                            <p className="color-green">*Servicio Estacional (verano):</p>

                            <ul>
                                <li>
                                Playa Sur Chico (Hasta Naplo) –  lunes a domingo<br/>
                                Costo de envío: S/ 14.90<br/>
                                *Costo de envío sujeto a cambio.</li>

                                <li>
                                Playa Sur Grande (Chilca-Asia) – lunes y viernes<br/>
                                Costo de envío S/ 19.90<br/>
                                *Costo de envío sujeto a cambio.
                                </li>
                            </ul>

                            <p className="color-green">Sameday* & Express**:</p>

                            <ul>
                                <li>Barranco</li>
                                <li>Jesús María</li>
                                <li>La Molina</li>
                                <li>Lince</li>
                                <li>Los Nogales (El Agustino)</li>
                                <li>Magdalena</li>
                                <li>Miraflores</li>
                                <li>Pueblo Libre</li>
                                <li>San Borja</li>
                                <li>San Isidro</li>
                                <li>San Luis</li>
                                <li>San Miguel</li>
                                <li>Santa Catalina (La Victoria)</li>
                                <li>Surco</li>
                                <li>Surquillo</li>
                            </ul>

                            <p>*<b>Sameday</b>: pide hasta la 1:00 p.m. y recibe en el rango de 2:00 p.m. a 9:00 p.m., según la hora de ingreso de tu pedido.</p>
                            <p>**<b>Express</b>: recibe en menos de 01 hora, de lunes a domingo. (Seguimos ampliando nuestra zona de cobertura a todo Lima, si tu distrito no se encuentra en el listado por favor consúltalo a atención al cliente para brindarte una información exacta)</p>

                            <p className="color-orange fw-bold">3. ¿Cómo hago un pedido?</p>

                            <p>Antes de registrarte, verifica si llegamos hasta tu hogar aquí:&nbsp;“<a
                                href="/page/zonas-de-cobertura">Consulta las áreas de despacho aquí</a>”.</p>

                            <p>Una vez que verifiques que llegamos a tu zona de reparto,</p>

                            <p>1. Regístrate <a href="/login">aquí</a> con Facebook o a través del formulario.</p>

                            <p>2. Ingresa los productos que desees a tu carrito de compras.</p>

                            <p>3. Dirígete al carrito que aparece en la parte superior derecha y aprieta
                                <span className="color-green"> ver carrito</span>.</p>

                            <p>4. Verifica los productos que hay y aprieta
                                <span className="color-green"> continuar</span>.</p>

                            <p>5. Coloca los datos de envío. Si deseas hacer envíos a más direcciones, agrégalas a tu
                                cuenta.&nbsp;Asegúrate de agregar la dirección y no modificarla, pues puede
                                ocasionar fallas en el sistema.</p>

                            <p>
                                6. Elige la fecha de reparto y presiona <span className="color-green"> continuar.</span>
                            </p>

                            <p>7. Elige el método de pago y presiona
                                <span className="color-green"> finalizar la compra</span>.</p>

                            <p className="color-orange fw-bold">3. ¿Cuánto cobran por delivery a mi zona?</p>

                            <p>El costo de delivery varía dependiendo del horario y distrito. Va desde S/ 2.50 hasta   S/ 6.50 para zonas de Lima Urbana.</p>

                            <p>Playa Sur Chico (Hasta Naplo)  el costo de envío: S/ 14.90.<br/>*Costo de envío sujeto a cambio.</p>

                            <p>Playa Sur Grande (Chilca-Asia)  el costo de envío S/ 19.90<br/>*Costo de envío sujeto a cambio.</p>


                            <h5 className="color-green fw-bold">Sobre los métodos de pago</h5>

                            <p className="color-orange fw-bold">1. ¿Qué métodos de pago aceptamos?</p>

                            <p>Puedes pagar con las siguientes opciones:</p>

                            <p>
                                <span className="color-green">· En línea:</span> A través de nuestra página web con
                                tarjetas de crédito Visa, Mastercard, Diners o American Express.
                            </p>

                            <p>
                                <span className="color-green">· Contraentrega:</span> POS Visa (para tarjetas Visa
                                débito o crédito)
                            </p>

                            <p>
                                <span className="color-green">· Contraentrega:</span> POS Mastercard (para
                                tarjetas Mastercard,&nbsp;Diners y American Express débito o crédito).
                            </p>

                            <p>
                                <span className="color-green">· Contraentrega:</span> Efectivo, Yape (banco BCP), Solo para montos hasta S/500. <span className="color-green">
                                El pago se efectúa al momento de recibir el pedido.</span></p>

                            <p>Yape: 934-109-650 (una vez realizada la transferencia enviar constancia a Atención al cliente junto a su número de pedido y/o mostrarlo al momento de recibir el pedido)</p>

                            <p>
                                <span className="color-green">· Transferencia bancaria:</span> Solo para clientes
                                frecuentes.
                            </p>

                            <p>BCP (Soles): 193-2229497-0-70, CCI: 002-193-002229497070-16 (BCP)</p>

                            <p>Interbank (Soles): 200-3002015461, CCI: 003-200-003002015461-30 (Interbank)</p>

                            <p>RAZON SOCIAL: FRESHMART SAC, RUC&nbsp; : 20547839651</p>

                            <p className="color-orange fw-bold">3. Si elijo pagar con transferencia o Yape, ¿en qué
                                momento la realizo?</p>

                            <p>Después de recibir el pedido, cuando tengas el ticket impreso con el monto final. Solo
                                cuando nuestro equipo te entregue la boleta con el monto final, debes hacer la
                                transferencia y enviar la constancia a <a
                                    href="/form/contacto">atencionalcliente@freshmart.pe</a>, indicando el número de
                                pedido de referencia.&nbsp;</p>

                            <p className="color-orange fw-bold">4. Pagué a través de la web. ¿por qué tengo un cargo
                                mayor en la tarjeta?</p>

                            <p>Si hiciste un pago a través de la web, nuestra pasarela de pago retiene el monto total de
                                tu pedido + el 10% por <em>default</em>. Esto es una retención. El cobro se efectúa al
                                momento de recibir el pedido con el monto que figura en tu boleta.&nbsp;</p>

                            <p className="color-orange fw-bold">5. ¿Cómo puedo saber que los datos de mi tarjeta son
                                tratados de forma segura?</p>

                            <p>Los datos de tu tarjeta pasan a través del control de la pasarela de pagos de manera
                                fácil y completamente segura.&nbsp;</p>

                            <p className="color-orange fw-bold">6. Tengo un código de descuento, ¿cómo lo utilizo?</p>

                            <p>Deberás ingresarlo después de completar tu carrito y justo antes de ingresar los datos de
                                despacho. El monto se actualizará&nbsp;al momento de ingresar el cupón. Puedes
                                verificarlo en el subtotal y&nbsp;visualizarlo en el total al momento de recibir tu
                                pedido.</p>

                            <h5 className="color-green font-weight-bold">Sobre productos frescos</h5>

                            <p className="color-orange fw-bold">1. ¿Las frutas y verduras son frescas?</p>

                            <p>Todas las frutas y verduras se compran la madrugada que sale tu pedido en los diferentes
                                mercados de abastos.</p>

                            <p className="color-orange fw-bold">
                                2. ¿Cuál es la procedencia de la carne y cómo funciona su cadena de frío?
                            </p>

                            <p>Trabajamos directamente con proveedores certificados. La cadena de frío se mantiene durante el proceso de empacado hasta llegar a la puerta de tu casa.</p>

                            <p className="color-orange fw-bold">
                                3. ¿Cuál es la procedencia de pollo y aves y cómo funciona su cadena de frío?
                            </p>

                            <p>
                                Nuestro principal proveedor de pollo es San Fernando. Nuestros proveedores nos
                                despachan los productos congelados. La cadena de frío se mantiene durante el proceso de
                                empacado hasta llegar a la puerta de tu casa.
                            </p>

                            <p className="color-orange fw-bold">
                                4. ¿Cuál es la procedencia del pescado y cómo funciona su cadena de frío?
                            </p>

                            <p>Nuestros pescados y mariscos son congelados. La cadena de frío se mantiene durante el
                                proceso de empacado, hasta el despacho, por lo que siempre llegará en perfecto estado
                                hasta tu casa. Los pescados y mariscos congelados conservan los mismos nutrientes que el
                                pescado fresco.</p>

                            <h5 className="color-green fw-bold">Sobre el REPARTO y los PEDIDOS</h5>

                            <p className="color-orange fw-bold">1. ¿Desde cuándo tienen fechas disponibles?</p>

                            <p>Tenemos fechas de reparto disponibles para el mismo día en distritos seleccionados. Estos pedidos pueden realizarse a través de 02 modalidades: Sameday, para pedir hasta la 1:00 p.m. y recibir a partir de las 2:00 p.m. dependiendo el distrito o Express, para pedir hasta 15 artículos y recibirlos en 01 hora o menos. Servicio disponible para Miraflores de 10:00 a.m. a 11:00 p.m. y para el Agustino de 10:00 a.m. a 7:00 p.m.</p>

                            <p className="color-orange fw-bold">2. ¿A qué hora me va a llegar mi pedido?</p>

                            <p>Todas nuestras entregas salen a partir de las 9:00 a.m. y pueden llegar hasta las 9:00
                                p.m., dependiendo del horario seleccionado.</p>

                            <p className="color-orange fw-bold">
                                3. ¿Puedo modificar el contenido de mi pedido, la fecha de entrega o cancelarlo?
                            </p>

                            <p>Sí puedes hacerlo. Solo atendemos solicitudes a través del correo:
                                atencionalcliente@freshmart.pe o a través de Whatsapp (solo mensajería) al número
                                934-109-650.</p>

                            <p className="color-orange fw-bold">4. ¿Pueden reprogramar mi pedido?</p>

                            <p>Sí. Solo en caso de que tú desees reprogramarlo.</p>

                            <p className="color-orange fw-bold">5. ¿Puedo comprar usando un pedido anterior?</p>

                            <p>Sí. Ingresa a "Mis Pedidos" y aparecerá el listado de todos los pedidos que hayas hecho.
                                Haz clic en “Repetir” sobre el pedido que quieras tomar de referencia. Luego tendrás la
                                opción de elegir sólo lo que te interese del pedido y podrás ajustar las cantidades
                                que necesites.</p>

                            <p className="color-orange fw-bold">6. ¿Cómo puedo obtener una factura de mi pedido?</p>

                            <p>Al realizar tu pedido, marca la casilla para recibir factura, ingresa los datos
                                correspondientes y te la enviaremos por correo una vez que recibas tu
                                pedido.</p>

                            <p className="color-orange fw-bold">
                                7. ¿Con cuánto tiempo de anticipación debo hacer un pedido?
                            </p>

                            <p>Freshmart Programado: Haz tu pedido hasta las 11:45 p.m. y recibe al día siguiente a partir de las 9:00 a.m.</p>

                            <p>Freshmart Para Hoy / Same Day: Pide hasta la 1:00 p.m. y recibe el mismo día a partir de las 2:00 p.m. a 6:00 p.m. o 5:00 p.m. a 9:00 p.m.*</p>

                            <p>Freshmart Express: Pide hasta 15 artículos y recíbelos en 01 horas o menos. Servicio disponible para Miraflores de 10:00 a.m. a 11:00 p.m. y para el Agustino de 10:00 a.m. a 7:00 p.m.</p>

                            <p className="color-orange fw-bold">8. ¿Si hay faltantes, se cobra?</p>

                            <p>Nunca cobramos los faltantes. Pero es importante comentar que no manejamos stock de productos frescos. Estos podrían no llegar en tu pedido si en caso hubiera algún problema en la cadena de suministro: quiebre de stock por alta demanda, derrumbes en las carreteras, condiciones climatológicas desfavorables, etc. Todos estos factores escapan de nuestro control, por lo que podrían no llegar en alguno de tus pedidos.</p>

                            <p>Si hubiera algún producto que no llegó, nos comprometemos a llevarlo sin costo de despacho adicional. Para ello, debes solicitar el faltante vía correo a atencionalcliente@freshmart.pe</p>

                            <p>*El faltante podrás revisarlo en el ticket de preventa.</p>

                            <p>Si en caso no te llegó un producto que se cargó a tu boleta,
                                <span className="color-green"> tienes hasta 03 días
                                desde el momento en que recibes tu compra para reportarlo</span> con nuestro equipo.
                            </p>

                            <p className="color-orange fw-bold">9. ¿Hay un mínimo de compra?</p>

                            <p>Sí. Manejamos un pedido mínimo de S/.35.00</p>

                            <p className="color-orange fw-bold">10. ¿Cómo sé el monto final de mi pedido?</p>

                            <p>Podrás visualizarlo en el correo “Tu pedido está siendo despachado”, donde ya se
                                pesaron los productos frescos y se calculó el monto final. De igual manera, te
                                entregaremos un ticket impreso donde podrás verificarlo.</p>

                            <p className="color-orange fw-bold">
                                11. ¿Qué sucede si no estoy satisfecha con un producto y tengo algún reclamo?
                            </p>

                            <p>Comunícate con nosotros por correo a atencionalcliente@freshmart.pe o vía Whatsapp
                                al 934-109-650 y te repondremos a la brevedad posible.</p>

                            <h5 className="color-green fw-bold">Sobre los productos y precios</h5>

                            <p className="color-orange fw-bold">1. ¿Me pueden enviar una lista de precios?</p>

                            <p>Todos nuestros productos y precios se encuentran en nuestra página web. Ingresa a la
                                categoría de tu preferencia y mira todos los productos que tenemos para ti. También
                                tenemos una sección de catálogos, que podrás encontrar en la parte superior de
                                tu pantalla o bien en el pie de página.</p>

                            <h5 className="color-green fw-bold">Sobre nuestros proveedores</h5>

                            <p className="color-orange fw-bold">
                                1. Soy productor y me interesa ser socio comercial de Freshmart.pe, ¿cómo los contacto?
                            </p>

                            <p>Escribe un correo electrónico a proveedores@freshmart.pe Nuestro equipo te responderá a
                                la brevedad posible.&nbsp;</p>

                            <p>&nbsp;</p>
                        </div>
                    </article>
                </div>
            </main>
            <Footer/>
        </div>
    )
}

export default Pagepreguntasfrecuentes;
