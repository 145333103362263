const TabformItem = ({handleChange,id,title,extra_class,isactive}) =>{

   const activeMenu = (e) =>{
		handleChange(isactive,id)   	
   }

            if (isactive) {
            	return ( <li className={"tab-form active "+ extra_class}>{title}</li>)            	
            }else{
            	return ( <li onClick={(e)=>activeMenu(e) } className={"tab-form "+ extra_class}>{title}</li>)            	
            }
	   		
	}


export default TabformItem;