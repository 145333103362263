import { useEffect, useState } from 'react'
import Header from './Header'
import Producto from './Producto'
import Footer from './Footer'

const PageCarrito = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal, form}) =>{
	const [productoRecomendados,setProductoRecomendados] = useState([])
	const [productoAntojitos,setProductoAntojitos] = useState([])
	const [countRecomendado,setCountRecomendado] = useState(0)
	const [countAntojitos,setCountAntojitos] = useState(0)
	

	const xhrRecomendados = async() => {
		let skus = [];
		if (typeof miproductos === "object") {

			skus = miproductos.map(function(item){
			    return item.sku    ;
			});
		}

		setCountRecomendado(1)
		localStorage.my_shopping_car = '[]';
        const dataq = new FormData();        
        dataq.append("id_comercio", localStorage.getItem('comercioId') );
        if (localStorage.methodshopping === "express" ) {
        	dataq.append("is_express", "1" );
        }else{
        	dataq.append("is_express", "0" );
        }
        dataq.append("skus", JSON.stringify(skus)  );
        
        

		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			  var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );              

			const response = await fetch(window.path_service+"/api/recomendados",{method:'POST',body: dataq, headers: myHeaders })
			const data = await response.json()

			if (data.length > 0 ) {
				setProductoRecomendados(data);
				window.$("#modalListRecomendados").modal("show");
			}else{
				xhrAntojitos()
				/*
				if (form === "appcart") {
					window.location.href = "/app/despacho";
				}else{
					window.location.href = "/despacho";
				}				
				*/
			}
		}

	}


	const xhrAntojitos = async() => {
		let skus = [];
		if (typeof miproductos === "object") {

			skus = miproductos.map(function(item){
			    return item.sku    ;
			});
		}

		setCountAntojitos(1)
		localStorage.my_shopping_car = '[]';
        const dataq = new FormData();        
        dataq.append("id_comercio", localStorage.getItem('comercioId') );
        if (localStorage.methodshopping === "express" ) {
        	dataq.append("is_express", "1" );
        }else{
        	dataq.append("is_express", "0" );
        }
        dataq.append("skus", JSON.stringify(skus)  );

		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			  var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );              

			const response = await fetch(window.path_service+"/api/antojitos",{method:'POST',body: dataq, headers: myHeaders })
			const data = await response.json()

			if (data.length > 0 ) {
				setProductoAntojitos(data);
				window.$("#modalLisAntojitos").modal("show");
			}else{

				if (form === "appcart") {
					window.location.href = "/app/despacho";
				}else{
					window.location.href = "/despacho";
				}

			}
		}

	}



	const MantenerMisProductos = () =>{
		changeMethodShopping("schedule")
		window.$("#modalListOrdenPedido").modal("hide");
		myListCart()
	}

	const DeleteProductNoExpress = async() =>{
		var modaLoading = 
		window.Swal.fire({
			imageUrl: window.path_service+'/images/loading.png',
			title: 'Cargando datos...',
			showConfirmButton: false,
			customClass: 'ms-loading-modal',
			allowOutsideClick: false
		});

	    const dataq = new FormData();
	    dataq.append("action", "remove_product_no_express" );
	    const response_service = await fetch(window.path_service+"/api/remove_product_no_express", { method:'POST',body:  dataq })
	    const response = await response_service.json()

	    
		modaLoading.close();
	    window.$("#modalListOrdenPedido").modal("hide");
	    myListCart();

	}

	
	const handleCloseModalAnto = () =>{
		window.$("#modalLisAntojitos").modal("hide")
	}
	const handleCloseModal = () =>{
		window.$("#modalListRecomendados").modal("hide")
	}

	useEffect(() => {
		/*
		if (form === "appcart") {
			window.$("body").addClass("fm-body-auth-mobile");	
			window.$("body").removeClass("fixed-top-init");			
			window.$(".fm-app-loading").remove();
		}
		*/
		myListCart();

		localStorage.removeItem("paymnet_shipping");
	}, []);


	const continuarDespacho = () =>{
		if (form === "appcart") {
			window.location.href = "/app/despacho";
		}else{
			window.location.href = "/despacho";
		}
	}


	const nextPage = () =>{
		if (countRecomendado === 1) {
			if (countAntojitos === 1) {
				if (form === "appcart") {
					window.location.href = "/app/despacho";
				}else{
					window.location.href = "/despacho";
				}
			}else{
				xhrAntojitos()
			}
		}else{
			xhrRecomendados();
		}
		
	}

	const canjearCupon = async() =>{
	  if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
	  	localStorage.cupon = document.getElementsByClassName("js-input-cupon")[0].value;
	  	localStorage.tempcupon = "1";
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'Añadiendo cupón...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});
	    myListCart();
	  }
	}

	const QuitarCupon = async() =>{
	  if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
	  	localStorage.removeItem("cupon");
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'Quitando cupón...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});
	    myListCart();
	  }
	}
	
	const handleClickMinius = async(prod) => {
	    let value = parseFloat( prod.cantidad );
	    const interval = parseFloat( prod.intervalo );
	    const min = parseFloat( prod.cantidad_inicial );

	    if( (value-interval) < min){
	        value = min;
	    }else{
	        value = value-interval;
	    }

	    if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
	    	const dataq = new FormData();
            dataq.append("action", "update" );
            dataq.append("id_producto", prod.id );
            dataq.append("cantidad", value );

	    	//if (window.pagenow === "appcart"){
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'actualizando el carrito...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});
	    	//}

            const response = await fetch(window.path_service+"/api/update/product-cart",{method:'POST',body: dataq })
            const data = await response.json()
            if (data.status) {
            	myListCart();
            }
        }	    
	}

	const handleClickPlus = async(prod) =>{
	    const interval = parseFloat( prod.intervalo );
	    const maxi = parseFloat( prod.cantidad_maxima );
	    let value = parseFloat( prod.cantidad );

	    if( (maxi > 0) && ( (value+interval) > maxi )  ){
	        value = maxi;
	    }else{
	        value = value+interval;
	    }
	    //setCantVal(value.toFixed(2))
	    //handleAumentarCantidad(value.toFixed(2))

	    if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {

	    	//if (window.pagenow === "appcart"){
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'actualizando el carrito...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});
	    	//}

	    	const dataq = new FormData();
            dataq.append("action", "update" );
            dataq.append("id_producto", prod.id );
            dataq.append("cantidad", value );

            const response = await fetch(window.path_service+"/api/update/product-cart",{method:'POST',body: dataq  })
            const data = await response.json()
            if (data.status) {
            	myListCart();
            }
        }	
	}


	const handleClearCard = async() => {
	  window.Swal.fire({
	    title: 'Limpiar Carrito',
	    text: '¿Esta seguro de querer eliminar todo el carrito?',
	    icon: 'error',
	    showCancelButton: true,
	    cancelButtonColor: "#666",
	    confirmButtonColor: "#13a538",
	    confirmButtonText: 'Si',
	    cancelButtonText: "No"
	  }).then((result) => {
	    if (result.isConfirmed) {
	      xhrClearCart()
	    }
	  })
	}


	const xhrClearCart = async() => {
	    	window.modaLoadingcart = 
				window.Swal.fire({
					imageUrl: window.path_service+'/images/loading.png',
					title: 'Limpiando el carrito...',
					showConfirmButton: false,
					customClass: 'ms-loading-modal',
					allowOutsideClick: false
				});

		localStorage.my_shopping_car = '[]';
        const dataq = new FormData();        
        dataq.append("id_comercio", localStorage.getItem('comercioId') );
        dataq.append("action","shop_remove_cart_all")

		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			  var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );              

			const response = await fetch(window.path_service+"/api/remove/product-cart",{method:'POST',body: dataq, headers: myHeaders })
			const data = await response.json()
		}
		myListCart();
	}


	return(
		<div>


			<main  className="wrap-content page-interna" style={{ backgroundColor : "rgb(229, 229, 229)"}}>

			  <div className="container-interna ms-payment ms-carrito">

			  	<div className="ms-section">
			          
					<div className="ms-payment-steps">
					      <ul className="ms-content-steps">
					        <li className="item-step check">
					          <div className="head-step">
					            <div className="icon-step"><i className="fa fa-user"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Usuario</span>
					          </div>
					        </li>					      
					        <li className="item-step active">
					          <div className="head-step">
					            <div className="icon-step"><i className="fa fa-shopping-cart"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Mi Carrito</span>
					          </div>
					        </li>
					        <li className="item-step">
					          <div className="head-step">
					            <div className="icon-step"><i className="fa fa-user"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Despacho</span>
					          </div>
					        </li>
					        <li className="item-step">
					          <div className="head-step">
					            <div className="icon-step"><i className="fa fa-credit-card"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Metodo de pago</span>
					          </div>
					        </li>
					        <li className="item-step">
					          <div className="head-step">
					            <div className="icon-step"><i className="fa fa-file-text-o"></i></div>
					          </div>
					          <div className="body-step">
					            <span>Confirmación</span>
					          </div>
					        </li>
					      </ul>
					</div>


			<div className="ms-wizard-wrapper">
			      <div className="ms-lateral-a">
			        <div className="ms-wizard-item">
			          <div className="ms-header-wizard">
			            <a className="ms-btn-clear js-clean-shopping-cart" onClick={handleClearCard}>Limpiar Carrito</a>
			          </div>
			          
			          	<div className="ms-body-wizard js-list-product-cart">
			          	{
			          		itemshopping.length> 0 ?
			          			itemshopping.map(
			          				(cart,keycar) => 
					              <div className="ms-table-item" key={keycar}>
					                <div className="ms-table-title">{cart.name}</div>
					                <table className="fm-detail-prod">
					                  <tbody>      
					                  {
					                  	cart.data.map(
					                  		(item,keyindex) =>
						                      <tr key={keyindex}>
						                        <td>
						                          <div className="ms-item-product">
						                            <div className="ms-wrap-img">
						                              <img className="shop-lazy-image" src={item.image}  alt={item.name} />
						                            </div>
						                            <div className="ms-wrap-detail">
						                              <p className="product-item__name">{item.name}</p>
						                            </div>
						                          </div>
						                        </td>
						                        <td className="ms-done">
						                        {
						                        	item.has_express === "true" ?
						                          	<div className="icon-express">
						                            	<img src="/images/express.svg?1" className="img-express" />
						                        	</div>                                                                    
						                        	:
						                        	null
						                        }
						                            <span className="ms-item-product-price">S/.{item.price_show}</span>
						                        </td>
						                        <td>
						                          <div className="js-qty">
						                          	{
						                          		(item.cantidad - item.intervalo) < item.cantidad_inicial ?
						                               <button type="button" className="qty_minus js-product-cart-minus btn-crud-product no-active" readOnly>
						                                 <span className="fa fa-minus"></span>
						                               </button>
						                          		:						                          		
						                               <button onClick={() => handleClickMinius(item)} type="button" className="qty_minus js-product-cart-minus btn-crud-product">
						                                 <span className="fa fa-minus"></span>
						                               </button>
						                          	}
						                               
						                               <input disabled="" type="text" className="js-qty__num" value={item.cantidad} min={item.cantidad_inicial} interval={item.intervalo} max={item.cantidad_maxima} cantidad_total={item.cantidad} data-id={item.id} aria-label="quantity" pattern="[0-9]*" name="quantity" readOnly />
						                             
						                             {
						                             	( 
						                             		(item.cantidad_maxima > 0) && ( (item.cantidad+item.intervalo) > item.cantidad_maxima ) 
						                             	) ?

						                             	<button type="button" className="qty_plus js-product-cart-plus btn-crud-product no-active">
						                             		<span className="fa fa-plus"></span>
						                             	</button>						                             	
						                             	:
						                             	<button onClick={() => handleClickPlus(item)} type="button" className="qty_plus js-product-cart-plus btn-crud-product">
						                             		<span className="fa fa-plus"></span>
						                             	</button>
						                             }  
						                             </div>
						                        </td>

						                        <td>
						                          <span className="ms-item-product-price total">S/.{item.total_sin_descuento}</span>
						                        </td>
						                        <td>
						                          <a onClick={() => removeProduct(item.id)  } className="ms-btn-prod-delete js-product-cart-delete" aria-label="Eliminar"><i className="fa fa-trash-o" aria-hidden="true"></i></a>
						                        </td>                        
						                      </tr>

					                  	)
					                  }
					                  </tbody>
					                </table>
					              </div>
							)
			          			: null
			          	}
						</div>
			        </div>
			      </div>

			      <div className="ms-lateral-b">
			        <div className="ms-wizard-item">
			          <div className="ms-wrap-item">
			            <span className="ms-title">Cupón de descuento</span>

			            {
			            	"tiene_cupon" in resumen && resumen.tiene_cupon ?
				            <div className="js-block-discount">
	                    		<label className="ms-title-cupon">{localStorage.cupon}</label>
	                    		<span className="js-title-cupon-delete" onClick={QuitarCupon}><i className="fa fa-times"></i></span>
				            </div>
				            :

				            <div className="ms-item-form js-block-cupon">
				              <input type="text" placeholder="Código de cupón" className="js-input-cupon" />
				              <button className="ms-btn js-btn-get-cupon" onClick={canjearCupon}>Canjear Cupón</button>
				            </div>				            
				        }

			          </div>
			        </div>

			        <div className="ms-wizard-item">
			          <div className="ms-wrap-item">
			            <span className="ms-title">Criterios de sustitución</span>
			            <div className="ms-item-form">
			              <select className="select-fresh">
			                <option value="1">No reemplazar productos</option>
			                <option value="2">Reemplazar productos (opción rechazo en punto de entrega)</option>
			              </select>
			              <span className="ms-min-text">* La información de sustituciones propuestas y diferencia en precios aparece en la parte baja del ticket de pre-venta. Estas pueden ser aceptadas o rechazadas al momento de la entrega. De ser el caso por favor solo entregar de vuelta el producto no deseado a nuestro equipo de despacho para poder hacer el ajuste respectivo en el cobro.</span>
			            </div>
			            <div className="ms-back-shipping">
			              <a href={window.path_service}><i className="fa fa-angle-left"></i> Seguir comprando</a>
			            </div>
			            <div className="ms-body-wizard">
			              <div className="ms-table-item js-cart-block-sub-total" style={{ marginBottom : "0"}}>

		                        <div className="ms-sub-title">
		                           <strong>Subtotal</strong>
		                           <strong className="color-price js-subtot-cart" mount="00.00">S/. {subTotal}</strong>
		                        </div>

		                        <table className="ms-payment-detail">
		                        	<tbody className="table-resumen">

		                        	{
		                        		"items" in resumen  && resumen.items.length > 0 ?
		                        		resumen.items.map((item,keyindex) =>
		                        			item.has_dsc_backo_tipo_2 &&
		                        			(
		                        				("producto" in item.descuentos) ||
		                        				("especial" in item.descuentos) 
		                        			)
		                        			 ?
			                        			<tr key = {keyindex} className="tr-rm-main">
			                        				{
			                        					Object.keys(item.descuentos).map(
			                        					(keyDesc, index) => 
			                        					keyDesc === "producto" || keyDesc === "especial" ?
			                        						item.descuentos[keyDesc].map(
				                        					(descpro, descindex) =>
				                        						descpro.tipo_adjustment === 2 ?
				                        						<tr className="tr-rm-main desc-cate" key={descindex}>
				                        							<td>
				                        								<div className="block-resumen resumen-descuento-prod" >
				                        									<span className="ms-grcolor">{descpro.nombre_mostrar}</span>
																			<span className="ms-pr-color">-S/{(descpro.monto_descuento) }</span>
																		</div>	
																	</td>	                        							
																</tr>
																: null
				                        					)
			                        						: null
			                        					)
			                        				}
			                        			</tr>
		                        			: null

		                        			)
		                        		: null
		                        	}

		                        	{
		                        		"descuentos_productos" in resumen  ?
		                        				Object.keys(resumen.descuentos_productos).map(
		                        					(keyDesc, index) => 
		                        					resumen.descuentos_productos[keyDesc].map(
		                        						(descpro, descindex) =>
		                        						descpro.tipo_adjustment === 2 ?

		                        						<tr className="tr-rm-main desc-cate" key={descindex}>
		                        							<td>
		                        								<div className="block-resumen resumen-descuento-prod" >
		                        									<span className="ms-grcolor">{descpro.nombre_mostrar}</span>
		                        									<span className="ms-pr-color">-S/{(descpro.monto_a_descontar) }</span>
		                        								</div>	
		                        							</td>	                        							
		                        						</tr>
		                        						: null
		                        					)
		                        				)
			                        		
		                        		:
		                        		null
		                        	}

		                        				<tr className="tr-rm-main tr-sub-total">
		                        					<td>
		                        						<div className="block-resumen resumen-subtotal-prod" >
											            	<span className="ms-grcolor">Sub Total Productos</span>
											                <span className="ms-pr-color">S/{(resumen.total_descuentos_productos) }</span>
											            </div>	
											        </td>	                        							
											    </tr>


		                        	{
		                        		"infocupon" in resumen && "nombre_mostrar" in  resumen.infocupon && "tipo_adjustment" in  resumen.infocupon && resumen.infocupon.tipo_adjustment === "1" ?

		                        				<tr className="tr-rm-main">
		                        					<td>
		                        						<div className="block-resumen resumen-descuento-prod" >
											            	<span className="ms-grcolor">{resumen.infocupon.nombre_mostrar}</span>
											                <span className="ms-pr-color">-S/{(resumen.infocupon.monto_descuento) }</span>
											            </div>	
											        </td>	                        							
											    </tr>
		                        		:
		                        		null
		                        	}

		                        	{
		                        		"descuentos_globales" in resumen && "dia" in resumen.descuentos_globales ?
		                        				<tr className="tr-rm-main">
		                        					<td>
		                        						<div className="block-resumen resumen-descuento-prod" >
											            	<span className="ms-grcolor">{resumen.descuentos_globales.dia.nombre_mostrar}</span>
											                <span className="ms-pr-color">-S/{(resumen.descuentos_globales.dia.monto_a_descontar) }</span>
											            </div>	
											        </td>	                        							
											    </tr>
		                        		:
		                        		null
		                        	}

		                        	{
		                        		"descuentos_globales" in resumen && "distrito" in resumen.descuentos_globales ?
		                        				<tr className="tr-rm-main">
		                        					<td>
		                        						<div className="block-resumen resumen-descuento-prod" >
											            	<span className="ms-grcolor">{resumen.descuentos_globales.distrito.nombre_mostrar}</span>
											                <span className="ms-pr-color">-S/{(resumen.descuentos_globales.distrito.monto_a_descontar) }</span>
											            </div>	
											        </td>	                        							
											    </tr>
		                        		:
		                        		null
		                        	}


		                        	{
		                        		"descuentos_globales" in resumen && "orden" in resumen.descuentos_globales ?
		                        				<tr className="tr-rm-main">
		                        					<td>
		                        						<div className="block-resumen resumen-descuento-prod" >
											            	<span className="ms-grcolor">{resumen.descuentos_globales.orden.nombre_mostrar}</span>
											                <span className="ms-pr-color">-S/{(resumen.descuentos_globales.orden.monto_a_descontar) }</span>
											            </div>	
											        </td>	                        							
											    </tr>
		                        		:
		                        		null
		                        	}


	                              {
	                              	resumen.activeshippingprice ?
	                                  <tr className="tr-rm-main">
	                                    <td>
	                                    	<div className="block-resumen">
	                                    		<span className="ms-grcolor">Precio de envio</span>
		                        				<span className="ms-pr-color">S/{ (resumen.shipping_price).toFixed(2) }</span>
		                        			</div>	                                    	
	                                    </td>
	                                  </tr>
	                                  : null
	                              }

                              {"dctoorden" in resumen && "nombre_mostrar" in resumen.dctoorden ? 
	                                  <tr className="tr-rm-main">
	                                    <td>
	                                    	<div className="block-resumen">
	                                    		<span className="ms-grcolor">{resumen.dctoorden.nombre_mostrar}</span>
		                        				<span className="ms-pr-color">-S/{resumen.dctoorden.monto_a_descontar}</span>
		                        			</div>	                                    	
	                                    </td>
	                                  </tr>
                                  :null
                              }  

                              {"activedescuentoCupon" in resumen && resumen.activedescuentoCupon ? 
	                                  <tr className="tr-rm-main">
	                                    <td>
	                                    {
	                                    	<div className="block-resumen">
		                                    	<span className="ms-grcolor">{resumen.nombrecupon}</span>
					                        	<span className="ms-pr-color">-S/{resumen.mountdescuento}</span>
					                        </div>	                                    	
	                                    }
	                                    </td>
	                                  </tr>
                                  :null
                              }                                            
			                    	</tbody>

			                        <tfoot  className="table-resumen" style={{"borderTop": "1px solid #ccc"}}>
			                            <tr className="tr-rm-main">
				                            <td>
	                                    	<div className="block-resumen">
	                                    		<span className="ms-grcolor foot"><strong>Total</strong></span>
		                        				<span className="ms-pr-color foot"><strong className="ms-grcolor">S/{resumen.totalPrice}</strong></span>
		                        			</div>	 
		                        			</td>
			                            </tr>
			                        </tfoot>                           
			                    </table>
		                           
		                           

		                        <span className="ms-min-text">Pedido mínimo de S/.35 para poder hacer una compra</span>
		                        { 
		                        	resumen.nextstep ? 
		                        	<button className="ms-btn green js-btn-next-payment" onClick={nextPage}>Continuar</button>
		                        	: null
		                        }
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>

			  	</div>

			  	
			  </div>

			</main>


	<div className="modal fade" id="modalListOrdenPedido" tabIndex="-1" role="dialog" aria-labelledby="modalListOrdenPedido">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">
	      <div className="modal-header">
	        <h3 className="ms-form-title">¿Estas comprando en modalidad Express y algunos productos NO SON EXPRESS?</h3>
	      </div>
			<div className="container-tabla">
			   <div className="campo-detalle">
				   <ul className="lista-detalle head">
				      <li className="fila-detalle">
				         <div className="item-detalle1"><span>SKU</span></div>
				         <div className="item-detalle2"></div>
				         <div className="item-detalle3">
				            <span>PRODUCTO</span>
				         </div>
				         <div className="item-detalle4"><span>PRECIO</span></div>
				         <div className="item-detalle5"><span>CANTIDAD</span></div>
				         <div className="item-detalle6"><span></span></div>
				         <div className="item-detalle7"><span>TOTAL</span></div>
				      </li>
				   </ul>			      
			   </div>
			   <ul className="lista-detalle">
			   {
			   	validateproductos.map(
			   		(product,index) =>
				    <li key = {index}  className="fila-detalle">
				         <div className="item-detalle1"><span>{product.sku}</span></div>
				         <div className="item-detalle2"></div>
				         <div className="item-detalle3"><p>{product.nombre}</p></div>
				         <div className="item-detalle4"><span>s/.{product.price}</span></div>
				         <div className="item-detalle5"><span>{product.cantidad}</span></div>
				         <div className="item-detalle6"><span></span></div>
				         <div className="item-detalle7"><span>s/.{product.total}</span></div>
				    </li>

			   	)
			   }
			   </ul>
			</div>
			<div className="content-buttons">
			   <div className="content-but">
			   		<div onClick={DeleteProductNoExpress}  className="button-agregar"><button type="button" className="btn-form1 btn-select">ELiminar productos No Express</button></div>
			   		<div onClick={MantenerMisProductos} className="button-agregar"><button type="button" className="btn-form1">Mantener mis productos</button></div>
			   </div>
			</div>



	    </div>
	  </div>
	</div>



      <div className="modal fade" id="modalLisAntojitos" tabIndex="-1" role="dialog" aria-labelledby="modalLisAntojitos">
         <div className="modal-dialog" role="document">
            <div className="modal-content">
               <div className="modal-header">
                  <h3 className="ms-form-title title-antojitos">
                     ¿Quieres añadir algo final a tu carrito?
                  </h3>
                  <button onClick={handleCloseModalAnto} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="fa fa-times"></i>
                  </button>
               </div>
               <div className="overview-recomendados">
                  <ul className="fm-wrap-list-product">

				    {
				    	productoAntojitos.map(
				    		x =>
				    		<Producto 
											        		template="recomendados"
											        		key={x.product.id} 
											        		methodShopping = {methodShopping}
											        		prod = {x.product} 
											        		showImage = {true}
											        		list_cart = {myListCart} />

				    	)
				    }


                  </ul>
               </div>
               <div className="container-tabla"></div>
               <div className="content-buttons">
                  <div className="content-but-antojitos">
                     <div onClick={continuarDespacho}  className="button-agregar button-agregar-next"><button type="button" className="btn-form1 btn-select">Continuar</button></div>
                  </div>
               </div>
            </div>
         </div>
      </div>

	<div className="modal fade" id="modalListRecomendados" tabIndex="-1" role="dialog" aria-labelledby="modalListRecomendados">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">

		        <div className="modal-header">
		          <h3 className="ms-form-title">
		          </h3>
		          <button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
		          <i className="fa fa-times"></i>
		          </button>
		        </div>

		        <div className="overview-recomendados-head">
	    	<ul className="fm-wrap-list-product">
	    		<li className="item-prod prod-recomendados">
	    			<span className="txt-recomendados">Estas Comprando</span>	
	    		</li>
	    		<li className="item-prod prod-recomendados">
	    			<span className="txt-recomendados">Te Recomendamos</span>
	    		</li>	    		
	    	</ul>
	    	</div>


	    	<div className="overview-recomendados">
				    {
				    	productoRecomendados.map(
				    		x =>
				    		<ul className="fm-wrap-list-product">

											        <Producto 
											        		template="recomendados_main"
											        		key={x.product_main.id} 
											        		methodShopping = {methodShopping}
											        		prod = {x.product_main} 
											        		showImage = {true}
											        		list_cart = {myListCart} />

											        <Producto 
											        		template="recomendados"
											        		key={x.product.id} 
											        		methodShopping = {methodShopping}
											        		prod = {x.product} 
											        		showImage = {true}
											        		list_cart = {myListCart} />
							</ul>
				    	)
				    }

	    	</div>


			<div className="container-tabla">			   
			</div>

			<div className="content-buttons">
			   <div className="content-but">
			   		<div onClick={continuarDespacho}  className="button-agregar button-agregar-next"><button type="button" className="btn-form1 btn-select">Continuar</button></div>
			   </div>
			</div>



	    </div>
	  </div>
	</div>


		</div>
	)
}
export default PageCarrito;