import { useState, useEffect } from 'react'

const useInfinityBuscar = ({methodShopping,buscar,ordenar}) =>{
  const [itemxhr, setItemxhr] = useState([])
  const [blocks, setBlocks] = useState(1)
  const [xhr, setXhr] = useState(1)

  const more = async () => {
  	setXhr(0)
  	if (xhr === 1) {		
  		const more_items = await fetch_properties(blocks)

    	if (more_items.status && more_items.data.length > 0 ) {
		    setBlocks(prev => prev + 1)
		    setItemxhr(prev => [...prev, ...more_items.data])
		    setXhr(1)    
    	}else{
    		setXhr(0)    
    	}
  	}
  }

	const fetch_properties = async (page) =>{
		const dataq = new FormData();
		dataq.append("action", "shopping_search_product" );
		dataq.append("q", buscar );
		dataq.append("page", page );
		dataq.append("order", ordenar );
		dataq.append("id_comercio", localStorage.comercioId );
		dataq.append("limit", "unlimited" );
		dataq.append("type", "normal" );
		if (methodShopping === "express" ) {
			dataq.append("is_express", "1" );
		}else{
		    dataq.append("is_express", "0" );
		}
		const response_service = await fetch(window.path_service+"/api/search/product", { method:'POST',body:  dataq })
		const data = await response_service.json()
		
		return data;
	}


  useEffect(() => {
    ;(async () => {
      const items_inits = await fetch_properties(0)
      if (items_inits.status) {
      	setItemxhr(items_inits.data)
      	setXhr(1)
      	setBlocks(1)
      }
      
    })()
  }, [methodShopping,ordenar])

  return [itemxhr, more]

}
export default useInfinityBuscar;