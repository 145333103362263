import { useState,useEffect } from 'react'
import Producto from './Producto'
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      className="next-slide slick-arrow"
      onClick={onClick}
    ><i className="fa fa-angle-right"></i></button>
  );
}

function SamplePrevArrow(props) {
  const {  onClick } = props;
  return (
    <button
      className="prev-slide slick-arrow"
      onClick={onClick}
    ><i className="fa fa-angle-left"></i></button>
  );
}

  var settings = {
                slidesToShow: 4,
                slidesToScroll: 2,
                speed: 900,
                autoplay: false,
                autoplaySpeed: 3500,
                dots: true,
                infinite: false,
                //PrevArrow:'<button type="button" className="prev-slide slick-arrow"><i className="fa fa-angle-left"></i> </button>',
                //NextArrow:'<button type="button" className="next-slide slick-arrow"><i className="fa fa-angle-right"></i></button>', 

			    nextArrow: <SampleNextArrow />,
			    prevArrow: <SamplePrevArrow />,

                responsive: [
                  {
                    breakpoint:930,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    }
                  },
                  {
                    breakpoint:768,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      dots: false,
                      arrows: true,
                    }
                  } ,
                  {
                    breakpoint:600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      dots: false,
                      arrows: true,
                    }
                  }            
                ]
              };



const ProductosRecomendados = ({titleProd,product_id,id_subcategoria,palabras_claves , limit, title,list_cart,methodShopping}) => {
	const [products, setProducts ] = useState([])

  let is_express = 0;
  if (methodShopping === "express") {
    is_express = 1;
  }

	const getApiData = async () => {
		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			const dataq = new FormData();
			dataq.append("id_comercio", localStorage.comercioId );
			dataq.append("is_express", is_express );
			dataq.append("id_subcategoria", id_subcategoria );
			dataq.append("product_id", product_id );
			dataq.append("title", titleProd );
			dataq.append("palabras_claves", palabras_claves );
 
		  const response = await fetch(window.path_service+"/api/product/recommended",{method:'POST',body: dataq})
		  const data = await response.json()
		  setProducts(data.data)
		}
	};

	useEffect(() => {
		getApiData();
	}, [methodShopping,id_subcategoria]);

	return(
	  <div className="js-slider-eko">
	  {
	  	products.length > 0 ? 
		   <div className="container eko-container-default">
		      <div className="text-left eko-slider-block-title">
		         <h3 className="title_heading mb-0 js-title-blobk">{title}&nbsp;</h3>
		      </div>
		      <Slider className="js-list-section-slider slick-dotted list-section-response-product" {...settings}>
			      	{ products.map(
				      	(x, index)  => 
				      	<Producto
                key={x.id} 
                methodShopping = {methodShopping}
	              prod = {x} 
	              list_cart = {list_cart}
	              />
				    ) }
		      </Slider>
		     </div>
	  	: null
	  }
	  </div>

	)

}
export default ProductosRecomendados;