import { useState } from 'react'

const HeaderTop = ({user,handleMiproductos,handleSubTotal,expressActive,changeHasExpress,methodShopping,changeMethodShopping,subTotal,miproductos,loadMenu,loadTransversal,transversal,menus,myLazyLoad,list_cart,removeProduct}) =>{
	const [hasDistrito,setHasDistrito] = useState(false)
	const [visible, setVisible ] = useState(false)

   
   const closeSession = (e) =>{
   	localStorage.clear()
   	window.location.href = "/login";
   }   

   const fc_visible = (e) =>{
   	setVisible(visible=>!visible)
   	
   	if (!visible) {
   		window.$("body").addClass("active-menu-mobile")
   	}else{
   		window.$("body").removeClass("active-menu-mobile")
   	}
   }

return(
		  <header id="slider-header">
		    <div className="section-full-header">
		      <section className="full-width-banner container">

		      <div className="full-header-left">
		        <div className="btn-menu btn-category js-btn-open-category" onClick={(e)=>fc_visible(e) }>
		        {
		        	visible ? 
		        		<i className="fa fa-remove" aria-hidden="true"></i>
		        	: 
		        		<i className="fa fa-bars" aria-hidden="true"></i>
		        }
		          
		          

		        </div>         
		         <div className="block-register block-logeed">
		            <a href="#"><i className="fa fa-user-circle" aria-hidden="true"></i><span>Mi cuenta</span></a>
		            <div className="dropdown-menu js-list-menus-login">
		               <div className="container-list-sub-menu">
		                  <ul className="lista-menu-login list-sub-menu-desk js-list-sub-menu-desk">
		                     <li className="item-menu-login"><a href="/mi-perfil"><i className="fa fa-user-o" aria-hidden="true"></i><span>Mis datos personales</span></a></li>
		                     <li className="item-menu-login"><a href="/customer"><i className="fa fa-group" aria-hidden="true"></i><span>Mis CLientes</span></a></li>
		                     <li className="item-menu-login"><a href="/cambiar-clave"><i className="fa fa-file-text" aria-hidden="true"></i><span>Cambiar clave</span></a></li>
		                     <li className="item-menu-login"><a href="#" onClick={(e)=>closeSession() } ><i className="fa fa-sign-out" aria-hidden="true"></i><span>Cerrar sesión</span></a></li>
		                  </ul>
		               </div>
		            </div>
		         </div>
		         <div className="block-login"><a className="js-user-logout" onClick={(e)=>closeSession() }  >CERRAR SESIÓN</a></div>
		         <div className="block-login block-head"><a className="" href="/orders"><i className="fa fa-truck" aria-hidden="true"></i><span>MIS PEDIDOS</span></a></div>
		         <div className="block-register js-change-express active"></div>
		      </div>      
		        
		        <div className="full-header-rigth">
		          <div className="group-datos-contact">

		            <div className="telefono">
		              <a href=" https://wa.me/94798355" target="_blank">
		                <i className="fa fa-whatsapp" aria-hidden="true"></i>
		                <span>947-983-553 (solo texto)</span>
		              </a>
		            </div>
		          </div>

		          <div className="group-servicios">
		            <div id="wrap-main" className="js-call-listinbox">
		              <div className="icono-inbox">
		                <i className="fa fa-envelope-o" aria-hidden="true"></i>
		                <div className="carrito-cantidad carrito-red shop-parpadeo">
		                  <p>0</p>
		                </div>
		              </div>
		            </div>
		          </div>

		        </div>
		      </section>
		    </div>
		  </header>
	)

}

export default HeaderTop;