import { useEffect } from 'react'
import Pedido from '../Form/Pedido'

const PagePedidoNew = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	
	return (
		<div id="ms-profile">
				        		<Pedido type_page="new" user={user} />				        	
        		

	        </div>
	)

}

export default PagePedidoNew;