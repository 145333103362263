import { useState, useEffect } from 'react'
import Input from './Input'
import InputFiscal from './InputFiscal'
import Fiscal from './Fiscal'

const DireccionFiscal = ({visual,changeAddressFiscalDefaul}) => {
	const [direcciones, setDirecciones ] = useState([])
	const [direccionDefault, setDireccionDefault ] = useState([])

	const [showList, setShowList ] = useState(true)

	const handledeleteItem = (item) => {
	  window.Swal.fire({
	    title: 'Estas Seguro de eliminar?',
	    icon: 'warning',
	    showCancelButton: true,
	    cancelButtonColor: "#666",
	    confirmButtonColor: "#13a538",
	    confirmButtonText: 'Si',
	    cancelButtonText: "No"
	  }).then((result) => {
	    if (result.isConfirmed) {
	      deleteItem(item)
	    }
	  })
	}

	
	const handleChangeDefault = async (item) => {
		let modaLoading;
		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

	    const dataq = new FormData();
		dataq.append("id_address", item.id );
		
		const response = await fetch(window.path_service+"/api/user/address_fiscal_default",{method:'POST',body:  dataq})	  
		const data = await response.json()
	  if ( data.hasOwnProperty("status") && data.status ) {
	  	getApiData()
	  }
	  modaLoading.close();	  
	};


	const deleteItem = async (item) =>{
		const dataq = new FormData();
	    dataq.append("id_address", item.id );
	    dataq.append("action", "shop_delete_address" );
	    const response = await fetch(window.path_service+"/api/user/delete_fiscal_address",{method:'POST',body:  dataq})	  
	    const data = await response.json()

	  if ( data.hasOwnProperty("status") && data.status  ) {
	  	window.Swal.fire({
	          icon: 'success',
	          title: data.message,
	          showConfirmButton: false,
	          timer: 2500
	    });   	  	
	    getApiData()
	  }else{
	  	window.Swal.fire({
	          icon: 'error',
	          title: "Surgio un error inesperado, intentalo nuevamente",
	          showConfirmButton: false,
	          timer: 2500
	    });   	  	
	  }

	}

	const getApiData = async () => {
	    const dataq = new FormData();

	  const response = await fetch(window.path_service+"/api/get-list-address-fiscal",{method:'POST',body:  dataq})	  
	  const data = await response.json()
	  if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") && data.data.length > 0 ) {
	  	setDirecciones(data.data)
	  	
	  	const addressDefault =
		  	(data.data).filter(function(item){
		  		return (item.is_default);
		  	});
		if (addressDefault.length > 0) {
		  	if(typeof changeAddressFiscalDefaul === 'function'){
		  		setDireccionDefault(addressDefault)
		  		changeAddressFiscalDefaul(addressDefault[0])
		  	}
		}

	  }else{
	  	if (visual === "payment") {
		  	setDireccionDefault([])
		  	openModalAddress()
		  	setShowList(true)
	  	}
	  }
	};


	const [value, setValue ] = useState( {razon_social:"",numero_ruc:"",direccion_fiscal:""} )
	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const openModalAddress = async (item) =>{
		window.$("#modalEditInvoice").modal("show")
	}

	const handleCloseModal = () =>{
		window.$("#modalEditInvoice").modal("hide")
	}
	
	const submit = async(event) =>{
		event.preventDefault();
		
			if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {

			    const dataq = new FormData();
				const datavalue = Array.from(new FormData(event.target))
			    for(let [key, val] of datavalue) {
			    	dataq.append(key, val );
			    }			    
				const response = await fetch(window.path_service+"/api/save/invoice",{method:'POST',body:  dataq })
				const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {
					window.$("#modalEditInvoice").modal("hide")
					setValue( {razon_social:"",numero_ruc:"",direccion_fiscal:""})
					window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			        });         					
					getApiData()
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 					
				}
				//reset();
			}
			
	}

	const handleShowAll = () =>{
		setShowList(true)
	}

	useEffect(() => {
		if (visual === "payment") {
			setShowList(false)
		}
		getApiData()
	}, []);

	return(
		<div>
            <div className="fm-container-block">

            	{
            		visual === "payment" && showList===false ?
	            	<div>
		            	<div className="fm-block-button">
		            		<button className="btn-add-item" onClick={handleShowAll}>Modificar</button>
		            	</div>
	            	
		            	<ul className="fm-block-list">
		            	{
		            		direccionDefault.map(direccion =>
		            			<Fiscal 
		            			key ={direccion.id}
		            			direccion={direccion}
		            			handledeleteItem= {handledeleteItem}
		            			handleChangeDefault= {handleChangeDefault}
		            			/>
		            			)
		            	}

		            	</ul>            		
	            	</div>
	            	: null
            	}


            	{
            		showList ?
	            	<ul className="fm-block-list">
		            	{
		            		direcciones.map(direccion =>
		            			<Fiscal 
		            			key ={direccion.id}
		            			direccion={direccion}
		            			handledeleteItem= {handledeleteItem}
		            			handleChangeDefault= {handleChangeDefault}
		            			/>
		            			)
		            	}
	            	</ul>
            		: null
            	}


            	{
            		showList ?
		            	<div className="fm-block-button">
		            		<button className="btn-add-item" onClick={openModalAddress}>Agregar nuevo RUC</button>
		            	</div>     
	            	: null
            	}

            </div>	




			<div className="modal fade" id="modalEditInvoice" role="dialog" aria-labelledby="modalEditAddres" aria-hidden="true">
			  <div className="modal-dialog" role="document">
			    <div className="modal-content">
			      <div className="modal-header">
			        <h3 className="ms-form-title">
			          <i className="fa fa-truck" aria-hidden="true"></i> Datos de facturación
			        </h3>
			        <button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
			        	<i className="fa fa-times"></i>
			        </button>
			      </div>
			      <div className="modal-body">

			      	<form id="form-address-fiscal" className="form-address-fiscal" onSubmit={submit}>
				        <div className="ms-wizard-item">
				          <div className="ms-form-payment-register">
						      <Input 
						        label = "Razón Social"
						        validated="yes"
						        name="razon_social"
						        value = {value.razon_social}
						        onChange = {handleChangeForm}
						        />

						      <InputFiscal 
						        label = "Número de RUC"
						        validated="yes"
						        name="numero_ruc"
						        value = {value.numero_ruc}
						        onChange = {handleChangeForm}
						        />

						      <Input 
						        label = "Dirección del domicilio fiscal"
						        validated="yes"
						        name="direccion_fiscal"
						        value = {value.direccion_fiscal}
						        onChange = {handleChangeForm}
						        />

			                   {
							    (
							    	value.numero_ruc !== "" && 
							    	value.numero_ruc.length === 11 && 
								    value.direccion_fiscal !== "" && 
								    value.razon_social !== ""
								)
			                   	?
			                   <div className="fm-item-form">
			                     <button className="fm-btn-opera">Guardar datos de facturación</button>
			                   </div>
			                   :
			                   <div className="fm-item-form disabled">
			                     <button disabled className="fm-btn-opera">Guardar datos de facturación</button>
			                   </div>		                   
			                   }
				          </div>
				        </div>
			      	</form>


			      </div>
			    </div>
			  </div>
			</div>

        

        </div>
	)	
}

export default DireccionFiscal;