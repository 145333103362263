import Header from './Header'
import Footer from './Footer'
import DireccionFiscal from './DireccionFiscal'

const PageMisFacturaciones = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	return(
		<div>
	        <Header
	        	user = {user}
	        	myLazyLoad = {myLazyLoad}
	        	loadMenu = {loadMenu}
	        	loadTransversal = {loadTransversal}
	        	menus = {menus}
	        	transversal = {transversal}
	        	changeHasExpress = {changeHasExpress}
	        	changeMethodShopping = {changeMethodShopping}
	        	methodShopping = {methodShopping}
	        	expressActive = {expressActive}
		        subTotal = {subTotal}
		        miproductos = {miproductos}
		        list_cart = {myListCart}
		        handleMiproductos = {handleMiproductos}
		        handleSubTotal = {handleSubTotal}
		        removeProduct = {removeProduct}
	        />

				<main  className="wrap-content page-interna">
					<div className="shop-container-register">
						<div id="ms-profile">
						  <div className="ms-section">
						    <div className="ms-tab-profile ms-payment" style={{zIndex: "auto"}}>
						      <div className="ms-lateral-a">
						        <div className="ms-header-menu-profile">
						          <button className="ms-active-menu">Mis Datos Personales</button>
						        </div>
						        <div className="ms-body-menu-profile">
						          <div className="ms-list-menu-profile">
						            <div className="ms-header-list-menu">Mi cuenta</div>
						            <ul className="ms-menu-profile">
						              <li>
						                <button className="ms-item"><i className="fa fa-user-o" aria-hidden="true"></i>Mi perfil</button>
						                <ul className="ms-submenu-profile">
						                  <li><a href="/mi-perfil">Mis datos personales</a></li>
						                  <li><a href="/mis-direcciones">Mis direcciones</a></li>
						                  <li><a href="/cambiar-clave" >Cambiar mi contraseña</a></li>
						                  <li><a href="/mis-facturaciones" className="active">Mis datos de facturacion</a></li>
						                  <li><a href="/mis-tarjetas">Mis tarjetas guardadas</a></li>
						                  <li><a href="/metodo_pago/report/user_purchases" Target="_blank">FRESHCLUB</a></li>
						                </ul>
						              </li>
						              <li>
						                <button className="ms-item"><i className="fa fa-truck fa-flip-horizontal" aria-hidden="true"></i>Pedidos</button>
						                <ul className="ms-submenu-profile">
						                  <li><a href="/mis-pedidos">Mis pedidos</a></li>
						                </ul>
						              </li>
						            </ul>
						          </div>
						        </div>
						      </div>
						      <div className="ms-lateral-b">
						        <div className="ms-body-menu-profile">
						          <div className="ms-info-menu-selected">
						            <div className="ms-title">Mis datos de facturación</div>
						            
						            <DireccionFiscal />
						            <div className="ms-separate"></div>

						          </div>
						        </div>
						      </div>
						    </div>
						  </div>
						</div>
					</div>
				</main>

	        <Footer />
	    </div>	
	)
}
export default PageMisFacturaciones;