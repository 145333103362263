import { useState, useEffect } from 'react'
import ItemForm from '../ItemForm'
import Input from '../Input'
import InputName from '../Input/InputName'
import InputPhone from '../Input/InputPhone'
import Select from '../Select'
import InputEmail from '../InputEmail'


const CuentaBancaria = ({litCuenta = [] ,handleCuentaBancaria,visual,changeAddressDefaul,changeMethodShopping,expressActive}) => {
	const [ contacts,setContacts ] = useState(litCuenta)
	const [showList, setShowList ] = useState(true)

	useEffect(() => {
		setContacts(litCuenta)
	}, [litCuenta]);


	const [listMoneda] = useState ([
		{id :1 , name:"Soles", simbolo :"S/." },
		{id :2 , name:"Dolar", simbolo : "$"},
		{id :3 , name:"Euro",simbolo : "€" }
	])

	const [listBancos] = useState (
		[
		{id: 1, name: "Banco de Comercio"},
			{id: 2, name: "Banco de Crédito del Perú"},
			{id: 3, name: "Banco Interamericano de Finanzas (BanBif)"},
			{id: 4, name: "Banco Pichincha"},
			{id: 5, name: "BBVA"},
			{id: 6, name: "Citibank Perú"},
			{id: 7, name: "Interbank"},
			{id: 8, name: "MiBanco"},
			{id: 9, name: "Scotiabank Perú"},
			{id: 10, name: "Banco GNB Perú"},
			{id: 11, name: "Banco Falabella"},
			{id: 12, name: "Banco Ripley"},
			{id: 13, name: "Banco Santander Perú"},
			{id: 14, name: "Alfin Banco"},
			{id: 15, name: "Bank of China"},
			{id: 16, name: "Bci Perú"},
			{id: 17, name: "ICBC PERU BANK"}
		])


	
	function generarRandom(num) {
	    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	    const charactersLength = characters.length;
	    let result = "";
	    let ch;
	    while (result.length < num){
	        ch = characters.charAt(Math.floor(Math.random() * charactersLength));
	        if (!result.includes(ch)){
	            result += ch;
	        }
	    }
	  return result;
	}


	const [inputval, setInputval] = useState(
			{
				idbanco :false,
				titular_cuenta:false,
				cuenta_bancaria :false,
				moneda : false
			}
		)


	const [value, setValue ] = useState(
	{
		id : generarRandom(6),
		idbanco :"",
		titular_cuenta : "",
		cuenta_bancaria :"",
		cuenta_interbancaria :"",	
		moneda: "",
		main: false
	}
		)


	const handleChangeForm = (e) => {

		if ( ["idbanco","cuenta_bancaria","moneda","titular_cuenta"].includes(e.target.name) && e.target.value !== ""  ) {
			handlerIsValidated(e.target.name,e.target.value !== "" ? true : false )
		}

		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))

	}

	const handlerIsValidated = (vname,valbool) => {
		setInputval((state) => (
		{
	      ...state,
	      [vname]: valbool
	    }
	    ))			
	}

	const openModalForm = () =>{
		window.$("#modalCuentaBancaria").modal("show")
		if (typeof window.map === "object") {
			setTimeout(() => {
				window.map.invalidateSize()
			}, 1000);
		}
	}
	
/*	const openShow = () =>{
		console.log(contacts,litCuenta)
	}	
*/
	const submit = async(event) =>{
		event.preventDefault();

			
				if( value.main){
					handleChangeDefault(value)
				}
					
					contacts.push(value)

					handleCuentaBancaria(contacts)
					setValue(	{
						id : generarRandom(6),
						idbanco :"",
						cuenta_bancaria :"",
						titular_cuenta : "",
						cuenta_interbancaria :"",	
						moneda: "",
						main: false
						})	

					setInputval({
						idbanco :false,
						cuenta_bancaria :false,
						moneda : false
					})

			window.$("#modalCuentaBancaria").modal("hide")


			        window.Swal.fire({
			            icon: 'success',
			            title: "Se añadio correctamente",
			            showConfirmButton: false,
			            timer: 2500
			          });         					


	}	

	

	const handledeleteItem = (item) => {
	  window.Swal.fire({
	    title: 'Estas Seguro de eliminar?',
	    icon: 'warning',
	    showCancelButton: true,
	    cancelButtonColor: "#666",
	    confirmButtonColor: "#13a538",
	    confirmButtonText: 'Si',
	    cancelButtonText: "No"
	  }).then((result) => {
	    if (result.isConfirmed) {
	      deleteItem(item)
	    }
	  })
	}


	const handleChangeDefault = async (item) => {
		
		contacts.forEach(function(val,index){
			val.main = false
			if(val.id === item.id)
				val.main = true 
			contacts[index] = val;
		});

		handleCuentaBancaria(contacts)	
	};


	const deleteItem =(item) =>{
		let contactsSafe = contacts.filter(function(val) {
		    return val.id != item.id
		})		

		handleCuentaBancaria(contactsSafe)
		setContacts(contactsSafe)
		
		
	  	window.Swal.fire({
	          icon: 'success',
	          title: "Se elimino correctamente para que los cambios se han efecto guarde los cambios",
	          showConfirmButton: false,
	          timer: 2500
	    });   

	}

	const handleCloseModal = () =>{
		window.$("#modalCuentaBancaria").modal("hide")
	}

	const getBankinfo= (idbank) => {
		
		const infobank = listBancos.filter(function(item) {
		    return item.id==idbank
		})

		return (infobank.length === 1) ? infobank[0] : [];
	}


	const getCurrencyinfo= (idmoneda) => {
		
		const infoCurrency = listMoneda.filter(function(item) {
		    return item.id==idmoneda
		})

		return (infoCurrency.length === 1) ? infoCurrency[0] : [];
	}


	return(
		

            <div className="fm-container-block">

				<div className="filter-result">
				   <p className="mb-30 ff-montserrat">Total Contactos : {contacts.length}</p>

		            	{
		            		contacts.map((contact,direccionkey) =>

								   <div className="job-box d-md-flex align-items-center justify-content-between mb-30">
								      <div className="job-left">
								      	<div className="job-info-user">
								      		<div className="job-user">
									            <ul className="job-detail">
									               <li className="job-item"> <i className="fa fa-bank" aria-hidden="true"></i> {getBankinfo(contact.idbanco).name} - {getCurrencyinfo(contact.moneda).name}</li>
									               <li className="job-item"> <i className="fa fa-user" aria-hidden="true"></i> {contact.titular_cuenta}</li>
     	{
									               	contact.cuenta_bancaria !=="" ?
									               		<li className="job-item"> <i className="fa fa-credit-card-alt" aria-hidden="true"></i> {contact.cuenta_bancaria}</li>
									               	:
									               	null
									               }


									            	{
									               	contact.cuenta_interbancaria !=="" ?
									               		<li className="job-item"> <i className="fa fa-credit-card-alt" aria-hidden="true"></i>CCI:  {contact.cuenta_interbancaria}</li>
									               	:
									               	null
									               }
									               	


									            </ul>					            
								      		</div>
								      	</div>
								         
								      </div>
								      		
								      {
								      	contact.main ?
									      	<div className="job-right my-4 flex-shrink-0"> 
									      		<a onClick={() => handleChangeDefault(contact) } href="#" className="btn btn-track btn-tracked">Principal</a>
									      	</div>
								      	:
								      	<div className="job-right my-4 flex-shrink-0"> 
								      		<a onClick={() => handledeleteItem(contact) } href="#" className="btn btn-track btn-expired">eliminar</a>
								      		<a onClick={() => handleChangeDefault(contact) } href="#" className="btn btn-track btn-no-track">Ser Principal</a>
								      	</div>
								      }
								      

								   </div>

		            			)
		            	}



				  				   
				</div>


            	{
            		showList ?
	            	<div className="fm-block-button">
	            		<button className="btn-add-item" onClick={openModalForm}>Agregar Cuenta Bancaria</button>
	            	</div>
	            	: null
            	}



		  <div className="modal fade" id="modalCuentaBancaria">
		    <div className="modal-dialog" role="document">
		      <div className="modal-content">
		        <div className="modal-header">
		          <h3 className="ms-form-title">
		            <i className="fa fa-money" aria-hidden="true"></i> Cuenta Bancaria
		          </h3>
		          <button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
		          <i className="fa fa-times"></i>
		          </button>
		        </div>
		        <div className="modal-body">
		         <form className="form-address" onSubmit={submit}>
		             <div className="ms-wizard-item">

		               <div className="ms-form-payment-register">

					        <Select
						        label = "Bancos*"
						        validated="yes"
						        name="idbanco"
						        value = {value.idbanco}
						        onChange = {handleChangeForm}
					        >			        
						        <option value="">Seleccione Tu banco de Preferencia</option>
						      	{
						      		listBancos.map((entry,enkey) =>
						      			<option key={enkey} value={entry.id}>{entry.name}</option>
						      		)
						      	}
					        </Select>

					      	<Input 
						        label = "Cuenta Bancaria"
						        validated="yes"
						        name="cuenta_bancaria"
						        value = {value.cuenta_bancaria}
						        onChange = {handleChangeForm}
					        />

					      	<Input 
						        label = "Titular de Cuenta Bancaria"
						        validated="yes"
						        name="titular_cuenta"
						        value = {value.titular_cuenta}
						        onChange = {handleChangeForm}
					        />

					        

					      	<Input
						        label = "Cuenta Interbancaria (CCI)"
						        validated="no"
						        name="cuenta_interbancaria"
						        value = {value.cuenta_interbancaria}
						        onChange = {handleChangeForm}
					        />


					        <Select
						        label = "Moneda*"
						        validated="yes"
						        name="moneda"
						        value = {value.moneda}
						        onChange = {handleChangeForm}

					        >			        
						        <option value="">Seleccione un Tipo de Dirección</option>
						      	{
						      		listMoneda.map((entry,enkey) =>
						      			<option key={enkey} value={entry.id}>{entry.name}</option>
						      		)
						      	}
					        </Select>

		                     <div className="ms-wrap-checkbox">
		                       <div className="ms-flex-address">
		                         <input type="checkbox" id="account-bank-main" className="" 
		                         onChange={handleChangeForm}
		                         name='main'
		                         checked={value.main}
		                         />
		                         <label htmlFor="account-bank-main">Cuenta Principal</label>
		                       </div>
		                     </div>


		                 <div className="ms-item ms-full">              


		                   {
						    (
							    !Object.values(inputval).includes(false)
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Cuenta</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Cuenta</button>
		                   </div>		                   
		                   }
		                 </div>

		               </div>
		             </div>
		         
		          
		          </form>

		        </div>
		      </div>
		    </div>
		  </div>


            </div>	
	)	
}

export default CuentaBancaria;