import { useState,useEffect } from 'react'

const InputFile = ({valobj,label,validated,classWrap="ms-item",changeFiles,...rest}) =>{
	
	const [valFor, setValFor ] = useState(null)
	const [value, setValue ] = useState("")


	const handleChangeFile = (e) => {
		const valuein = e.target.files[0]
		console.log(valuein)
		
		if (valuein.size < 3000972) {

			if (typeof valuein == "object") {
				var reader = new FileReader();
				reader.onload = function (e) {
	                	setValue(e.target.result)
	                }
	                reader.readAsDataURL(valuein);
	            } else {

	            }

	            changeFiles(e)

		}else{
			window.Swal.fire({
				icon: 'error',
				title: "excedió el limite, maximo de 2.5Mb",
				showConfirmButton: false,
				timer: 3500
			});  			
		}

	}

	const generateId = (length) =>{
	    let result = '';
	    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	    const charactersLength = characters.length;
	    let counter = 0;
	    while (counter < length) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
	      counter += 1;
	    }
	    setValFor(result);	
	}


	const eliminarFoto = () =>{
		changeFiles(null,rest.name)
		setValue("")
	}

	useEffect(() => {
		setValue( (valobj !== null) ? valobj:"" )
		generateId(4)
	}, []);


	return (
		<div className={classWrap+" cpUpload"} >
		  <h3>{label}</h3>
				  <div className="upfile-area">
		  		{
		  			value.length !== 0 || valobj.length !== 0 ?
		  				<span onClick={eliminarFoto} className="removeImg">X</span>
		  			:
		  			null
		  		}

				    <label htmlFor={valFor}>
					  	{
					  		value.length !== 0?
					  		<img src={value} />
					  		:
					  		 	valobj.length !== 0 ?
					  		 		<img src={valobj} />
					  		 	:
					  		 	null
					  	}
				    </label>
					    <div className="icon">
					      <i className="fas fa-images"></i>
					    </div>
					    <span className="header">arrastrar y soltar</span>
					    <span className="header">o<span className="button">selecciona</span></span>
					    <input onChange={handleChangeFile} type="file" id={valFor} {...rest} accept="image/png, image/jpeg, image/jpg, image/webp" hidden  />
					    <span className="support">Soporta: webp, JPEG, JPG, PNG</span>
				  </div>

		</div> 

	)
}
export default InputFile;