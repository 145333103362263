import { useState, useEffect } from 'react'

const useInfinityAutocomplete = ({buscar,ordenar}) =>{
  const [itemxhr, setItemxhr] = useState([])
  const [blocks, setBlocks] = useState(1)
  const [xhr, setXhr] = useState(1)
  //const abortController = new AbortController();

  const textSearch =(txtbuscar) => {
    buscar = txtbuscar
    more()
  }

  const clearList =(txtbuscar) => {
    setItemxhr([])
  }

  const more = async () => {
    //console.log(abortController)
  	setXhr(0)
  	if (xhr === 1 && buscar !== "" ) {
  		const more_items = await fetch_properties(blocks)
      setXhr(1)    
    	if (more_items.status ) {
		    setBlocks(prev => prev + 1)
		    //setItemxhr(prev => [...prev, ...more_items.data])
        setItemxhr(more_items.data)
    	}
  	}
  }

	const fetch_properties = async (page) =>{
    /*
    const controller = new AbortController();
    const signal = controller.signal;
    // Make a fetch request with the signal
    fetch( window.path_service+"/orders/users",
    {
          signal: signal,
          method:'POST',
          body: JSON.stringify({"keyword": buscar }), 
          headers: myHeaders
        
        }      
          )
      .then(response => {
        // Handle the response
      data = response.json()

      return data;
      })
      .catch(error => {
        // Handle errors
      });
    // To cancel the request, call the abort() method on the controller
    controller.abort();
    */


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
  //const controller = new AbortController();
  //const signal = controller.signal;

    const response = await fetch( window.path_service+"/orders/users",
    {
      //signal: signal,
      method:'POST',
      body: JSON.stringify({"keyword": buscar }), 
      headers: myHeaders
    
    })
    //controller.abort();

    const data = await response.json()

		return data;
		
	}


  useEffect(() => {
    ;(async () => {
      const items_inits = await fetch_properties(0)
      if (items_inits.status) {
      	setItemxhr(items_inits.data)
      	setXhr(1)
      	setBlocks(1)
      }
      
    })()
  }, [ordenar])

  console.log(xhr,buscar)

  return [itemxhr, more,textSearch, clearList]

}
export default useInfinityAutocomplete;