import { useState, useEffect } from 'react'
import ItemForm from '../ItemForm'
import Input from '../Input'
import InputName from '../Input/InputName'
import InputPhone from '../Input/InputPhone'
import Select from '../Select'
import InputEmail from '../InputEmail'


const Contacto = ({listContacts = [] ,handleContact,visual,changeAddressDefaul,changeMethodShopping,expressActive}) => {
	const [ contacts,setContacts ] = useState(listContacts)
	const [showList, setShowList ] = useState(true)
	
	function generarRandom(num) {
	    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	    const charactersLength = characters.length;
	    let result = "";
	    let ch;
	    while (result.length < num){
	        ch = characters.charAt(Math.floor(Math.random() * charactersLength));
	        if (!result.includes(ch)){
	            result += ch;
	        }
	    }
	  return result;
	}


	const [inputval, setInputval] = useState(
			{
				name :false,
				lastname :false,
				phone :false,
				email: false,
				cargo : false
			}
		)


	const [value, setValue ] = useState(
	{
		id : generarRandom(6),
		name :"",
		lastname :"",
		phone :"",
		phone2	 :"",	
		email: "",
		email2: "",
		cargo : "",
		address : "",
		numero_documento:"",
		default: false
	}
		)

	const handleChangePhone = (e) => {
		setValue((state) => ({
	      ...state,
	      ["phone"]: ![undefined,"undefined","",null].includes(e) ? e : ""
	    }))
		setInputval((state) => (
		{
	      ...state,
	      ["phone"]: true
	    }
	    ))		    
	}

	const handleChangePhone2 = (e) => {
		setValue((state) => ({
	      ...state,
	      ["phone2"]: ![undefined,"undefined","",null].includes(e) ? e : ""
	    }))
	}

	const handleChangeForm = (e) => {

		if (e.target.name === "email" && (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(e.target.value) ) ) {
			handlerIsValidated("email",true)
		}

		if ( ["name","lastname","phone","cargo"].includes(e.target.name) && e.target.value !== ""  ) {
			handlerIsValidated(e.target.name,e.target.value !== "" ? true : false )
		}


		if (e.target.name === "distrito") {
			value.distritoName = e.target.options[e.target.selectedIndex].text
		}	    

		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))

	}

	const handlerIsValidated = (vname,valbool) => {
		setInputval((state) => (
		{
	      ...state,
	      [vname]: valbool
	    }
	    ))			
	}

	const openModalForm = () =>{
		window.$("#modalContacto").modal("show")
		if (typeof window.map === "object") {
			setTimeout(() => {
				window.map.invalidateSize()
			}, 1000);
		}
	}

	const submit = async(event) =>{
		event.preventDefault();

			
				if( value.default){
					handleChangeDefault(value)
				}
					
					contacts.push(value)

					handleContact(contacts)
					setValue(	{
							id : generarRandom(6),
							name :"",
							lastname :"",
							phone :"",
							phone2	 :"",	
							email: "",
							email2: "",
							cargo : "",
							address : "",
							numero_documento:"",
							default: false
						})	

					setInputval({
						name :false,
						lastname :false,
						phone :false,
						email: false,
						cargo : false
					})

			window.$("#modalContacto").modal("hide")


			        window.Swal.fire({
			            icon: 'success',
			            title: "Se añadio correctamente",
			            showConfirmButton: false,
			            timer: 2500
			          });         					


	}	

	

	const handledeleteItem = (item) => {
	  window.Swal.fire({
	    title: 'Estas Seguro de eliminar?',
	    icon: 'warning',
	    showCancelButton: true,
	    cancelButtonColor: "#666",
	    confirmButtonColor: "#13a538",
	    confirmButtonText: 'Si',
	    cancelButtonText: "No"
	  }).then((result) => {
	    if (result.isConfirmed) {
	      deleteItem(item)
	    }
	  })
	}


	const handleChangeDefault = async (item) => {
		
		contacts.forEach(function(val,index){
			val.default = false
			if(val.id === item.id)
				val.default = true 
			contacts[index] = val;
		});

		handleContact(contacts)	
	};


	const deleteItem =(item) =>{
		let contactsSafe = contacts.filter(function(val) {
		    return val.id != item.id
		})		

		handleContact(contactsSafe)
		setContacts(contactsSafe)
		
		
	  	window.Swal.fire({
	          icon: 'success',
	          title: "Se pre elimino correctamente para que los cambios se han efecto guarde los cambios",
	          showConfirmButton: false,
	          timer: 2500
	    });   

	}

	const handleCloseModal = () =>{
		window.$("#modalContacto").modal("hide")
	}

	useEffect(() => {
		setContacts(listContacts)
	}, [listContacts]);


	return(
		

            <div className="fm-container-block">

				<div className="filter-result">
				   <p className="mb-30 ff-montserrat">Total Contactos : {contacts.length}</p>

		            	{
		            		contacts.map((contact,direccionkey) =>

								   <div className="job-box d-md-flex align-items-center justify-content-between mb-30">
								      <div className="job-left">
								      	<div className="job-info-user">
								      		<div className="job-user">
									            <ul className="job-detail">
									               <li className="job-item"> <i className="fa fa-user" aria-hidden="true"></i> {contact.name+" "+contact.lastname}</li>
									               {
									               	contact.cargo !== "" ?
									               		<li className="job-item"> <i className="fa fa-briefcase" aria-hidden="true"></i> {contact.cargo}</li>
									               	:
									               	null
									               }
									               

									               {
									               	contact.numero_documento !=="" ?
									               		<li className="job-item"> <i className="fa fa-address-card-o" aria-hidden="true"></i> {contact.numero_documento}</li>
									               	:
									               	null
									               }
									               									               
									               <li className="job-item"> <i className="fa fa-phone" aria-hidden="true"></i> <a href={"tel:"+contact.phone}>{contact.phone}</a> </li>
									               {
									               	contact.phone2 !== "" ?
									               		<li className="job-item"> <i className="fa fa-phone" aria-hidden="true"></i> <a href={"tel:"+contact.phone2}>{contact.phone2}</a> </li>
									               	:
									               	null
									               }
									               <li className="job-item"> <i className="fa fa-envelope" aria-hidden="true"></i><a href={"mailto:"+contact.email}>{contact.email}</a></li>				               
									               {
									               	contact.email2 !== "" ?
									               		<li className="job-item"> <i className="fa fa-envelope" aria-hidden="true"></i><a href={"mailto:"+contact.email2}>{contact.email2}</a></li>
									               		:
									               		null
									               }
									               									               

									            </ul>					            
								      		</div>
								      	</div>
								         
								         {
								         	contact.address !== "" ?
										         <div className="job-content">
										            <ul className="job-detail">
										            	<li className="job-item"> <i className="fa fa-map-marker" aria-hidden="true"></i> {contact.address}</li>
										            </ul>
										         </div>
								         	
								         	: null
								         }

								      </div>
								      		
								      {
								      	contact.default ?
									      	<div className="job-right my-4 flex-shrink-0"> 
									      		<a onClick={() => handleChangeDefault(contact) } href="#" className="btn btn-track btn-tracked">Principal</a>
									      	</div>
								      	:
								      	<div className="job-right my-4 flex-shrink-0"> 
								      		<a onClick={() => handledeleteItem(contact) } href="#" className="btn btn-track btn-expired">eliminar</a>
								      		<a onClick={() => handleChangeDefault(contact) } href="#" className="btn btn-track btn-no-track">Ser Principal</a>
								      	</div>
								      }
								      

								   </div>

		            			)
		            	}



				  				   
				</div>


            	{
            		showList ?
	            	<div className="fm-block-button">
	            		<button className="btn-add-item" onClick={openModalForm}>Agregar nuevo Contacto</button>
	            	</div>
	            	: null
            	}



		  <div className="modal fade" id="modalContacto">
		    <div className="modal-dialog" role="document">
		      <div className="modal-content">
		        <div className="modal-header">
		          <h3 className="ms-form-title">
		            <i className="fa fa-user" aria-hidden="true"></i> Contacto
		          </h3>
		          <button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
		          <i className="fa fa-times"></i>
		          </button>
		        </div>
		        <div className="modal-body">
		         <form className="form-address" onSubmit={submit}>
		             <div className="ms-wizard-item">

		               <div className="ms-form-payment-register">

					      <InputName
					        label = "nombre"
					        validated="yes"
					        name="name"
					        value = {value.name}
					        onChange = {handleChangeForm}
					        handlerIsValidated = {handlerIsValidated}
					        />

					      <InputName 
					        label = "apellidos"
					        validated="yes"
					        name="lastname"
					        value = {value.lastname}
					        onChange = {handleChangeForm}
					        />

							<InputPhone 
								label = "Teléfono*"
							    validated="yes"
							    name="phone"
							    value = {value.phone}
							    classWrap="ms-item"
							    clasInput="fm-form-input"
							    onChange = {handleChangePhone}
							    />

							<InputPhone 
								label = "Otro Teléfono (opcional)"
							    validated="yes"
							    name="phone2"
							    value = {value.phone2}
							    classWrap="ms-item"
							    clasInput="fm-form-input"
							    onChange = {handleChangePhone2}
							    />

						      <Input 
						        label = "Dirección Completa"
						        validated="no"
						        name="address"
						        classWrap = "ms-item ms-full"
						        value = {value.address}
						        onChange = {handleChangeForm}
						        />

					      <Input 
					        label = "DNI"
					        validated="no"
					        name="numero_documento"
					        value = {value.numero_documento}
					        onChange = {handleChangeForm}
					        />


					      <InputEmail 
					        label = "E-mail Principal"
					        validated="yes"
					        name="email"
					        value = {value.email}
					        onChange = {handleChangeForm}
					        handlerIsValidated = {handlerIsValidated}
					        />

					      <InputEmail 
					        label = "E-mail Secundario"
					        validated="no"
					        name="email2"
					        value = {value.email2}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "Cargo"
					        validated="yes"
					        name="cargo"
					        value = {value.cargo}
					        onChange = {handleChangeForm}
					        />

		                     <div className="ms-wrap-checkbox">
		                       <div className="ms-flex-address">
		                         <input type="checkbox" id="contact-main" className="" 
		                         onChange={handleChangeForm}
		                         name='default'
		                         checked={value.default}
		                         />
		                         <label htmlFor="contact-main">Contacto Principal</label>
		                       </div>
		                     </div>


		                 <div className="ms-item ms-full">              


		                   {
						    (
							    !Object.values(inputval).includes(false)
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Contacto</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Contacto</button>
		                   </div>		                   
		                   }
		                 </div>

		               </div>
		             </div>
		         
		          
		          </form>

		        </div>
		      </div>
		    </div>
		  </div>


            </div>	
	)	
}

export default Contacto;