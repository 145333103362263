import { useState, useEffect ,useRef } from 'react'
import Input from '../Input'
import Select from '../Select'

const FilterCategory = ({showlist = 0,handleCategory }) =>{
  const [listCat, setListCat ] = useState([])
  const [listSubcat, setListSubcat ] = useState([])
  const [pagination, setPagination ] = useState({
    tot : 0,
    next : false,
    prev:false,
    text:""
  })

  console.log(pagination)

  const listsort = 
  [
    {label:"Alfabético A-Z",value:"name-asc"},
    {label:"Alfabético Z-A",value:"name-desc"}
  ]

	const [value, setValue ] = useState(
	{
		page :0,
		keyword:"",
	    category:"",
	    subcategory:"",
    	sort:"name-asc",
		stockrange:null
	}
		)


  useEffect(() => {
    listCategory()
    submit();   
  }, [showlist]);


  const handleChangeFormCategory = (e) => {
    
    setValue((state) => ({
        ...state,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      }))
  }


  const listCategory = async(vid_parent = 0) =>{
    value.subcategory=""

          if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
              myHeaders.append("Content-Type", "application/json");        
        
        const response = await fetch(window.path_service+"/category/list",{method:'POST',body: JSON.stringify({status:1,id_parent:vid_parent}), headers: myHeaders })
        const data = await response.json()


          if ( data.hasOwnProperty("status") && data.status ) {
            
            if (vid_parent === 0 ) {
              setListCat(data.data)         
            }
            
          }
        
      }
      
    }

	const handleChangeSort = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
    value.sort = e.target.value
    submit()
	}

  const handleChangeForm = (e) => {
    setValue((state) => ({
        ...state,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      }))
    console.log(value)
  }


  const fc_pagination = async(action="next") =>{
  	if (action === "next") {
  		value.page = parseInt(value.page) + 1;
  	}

  	if (action === "prev") {
  		value.page = parseInt(value.page) - 1;
  	}
  	submit()
  }

  const submit = async() =>{
    window.$(".item-filter").removeClass("active");

    var modaLoading = 
      window.Swal.fire({
        imageUrl: window.path_service+'/images/loading.png',
        title: 'Cargando datos...',
        showConfirmButton: false,
        customClass: 'ms-loading-modal',
          allowOutsideClick: false
     });
            


        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
      
      const response = await fetch(window.path_service+"/category/list",{method:'POST',body: JSON.stringify(value), headers: myHeaders })
      const data = await response.json()


      if ( data.hasOwnProperty("status") && data.status ) {
        handleCategory(data.data)
        setPagination(
{
    tot : data.total,
    next : data.pagination.header.next,
    prev : data.pagination.header.previous,
    text:data.text_pag
  })
      }else{
            window.Swal.fire({
                  icon: 'error',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2500
            }); 
        
      }
      modaLoading.close();
    }
    
  }

	return(
        <div className="filter-container">
           <div className="ib-fheader">
              <div className="ib-fhpa">
                 <div className="ib-fhpa-minisearch">
                    <input id="ib-fmsearch-a" className="ib-fmsearch" autocorrect="off" autocomplete="disabled" 
                    type="text" placeholder="Ingresa el nombre de la Categoria" 
                    name="keyword"
                    value={value.keyword}  
                    autocomplete="off"
                    onChange = {handleChangeForm} />
                    <div className="ib-fmsubmit ib-icon-search ib-kw-tg-search" onClick={(e)=>submit() }>
                      <span className="fa fa-search"></span>
                    </div>
                 </div>

              </div>
              <div className="ib-fhpb">
                 <ul className="ib-fhpb-openers">
                    

                    <li className="item-filter datefil">
                       <div className="ib-oiwrapper"><span className="ib-iotxt ib-lbl-price-ntf">Categoria</span></div>
                       <div className="minfil-range">
                          <div className="ib-fititle">Categorias</div>
                          <div className="ib-ficontent ib-price-range-outer">
                             <div className="ib-price-range-wrap ib-price-range-wrap-sale" style={{display:"block"}}>
                                <div className="ib-wimputs wrap-filter-select">
                                  <Select
                                    label = "Categoria Principal"
                                    name="category"
                                    value = {value.category}
                                    onChange = {handleChangeFormCategory}

                                  >
                                        <option value="">Selecciona una Categoria</option>
                                        {
                                          listCat.map(x => 
                                          <option value={x.id}>{x.name}</option>
                                          )
                                        }                       
                                  </Select>

                                </div>
                             </div>
                          </div>
                       </div>
                    </li>

                    <li className="item-filter sended" onClick={(e)=>submit() } >
                       <div className="wrapper-filter"><a className="btn-filter">Buscar</a></div>
                    </li>

                    <li className="item-filter sended">
                       <div className="wrapper-filter"><a href="/category/new" className="btn-filter">Nuevo</a></div>
                    </li>


                 </ul>
              </div>
           </div>

          <div className="wrap-fil-more">
              <div className="wrap-pag"> 
                <span> {pagination.text}</span>

                {
                  pagination.prev ?
                    <button onClick={(e)=>fc_pagination("prev") } className="st-button st-prev"></button>  
                  :
                    <button disabled className="st-button st-prev disabled"></button>  
                }
  
                {
                  pagination.next ?
                    <button onClick={(e)=>fc_pagination("next") } className="st-button st-next"></button> 
                  :
                    <button disabled className="st-button st-next disabled"></button> 
                }
                
                
              </div>

              <div className="wrap-sort">
                  <select 
                  name="sort"
                  onChange = {handleChangeSort}
                  className="sort-input">
                  {
                    listsort.map(
                      item => 
                        value.sort === item.value ?
                          <option selected="selected" value={item.value}>{item.label}</option>
                        :
                          <option value={item.value}>{item.label}</option>
                      
                    )
                  }
                  </select>
              </div>                        
          </div> 

        </div>
	)

}

export default FilterCategory;