import { useState, useEffect } from 'react'
import Header from './Header'
import ListCart from './ListCart'
import Footer from './Footer'

const PageConfirmacion = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

if (window.payment.bool_check_payed) {
	localStorage.removeItem("id_orden");
	localStorage.removeItem("comercioId");
	localStorage.removeItem("methodshopping");
	localStorage.removeItem("message_welcome");
	localStorage.removeItem("distritoPay");
	localStorage.removeItem("checkDay");
	localStorage.removeItem("address");
	localStorage.removeItem("paymnet_shipping");
	localStorage.removeItem("facturacion");
	localStorage.removeItem("paymnet_shipping");
	localStorage.removeItem("reducirBolsa");
	localStorage.removeItem("moreComment");	    
	localStorage.removeItem("emailUser");
	localStorage.removeItem("cupon");
	localStorage.removeItem("tempcupon");
}


	return(
		<div>
	        <Header
	        	user = {user}
	        	myLazyLoad = {myLazyLoad}
	        	loadMenu = {loadMenu}
	        	loadTransversal = {loadTransversal}
	        	menus = {menus}
	        	transversal = {transversal}
	        	changeHasExpress = {changeHasExpress}
	        	changeMethodShopping = {changeMethodShopping}
	        	methodShopping = {methodShopping}
	        	expressActive = {expressActive}
		        subTotal = {subTotal}
		        miproductos = {miproductos}
		        list_cart = {myListCart}
		        handleMiproductos = {handleMiproductos}
		        handleSubTotal = {handleSubTotal}
		        removeProduct = {removeProduct}
	        />


			<main  className="wrap-content page-interna" style={{ backgroundColor : "rgb(229, 229, 229)"}}>
			    <div className="ms-payment ms-step-4-confirmation" id="payment">
			      <div className="ms-section">
			        <div className="ms-payment-steps">
			          <ul className="ms-content-steps">
			            <li className="item-step check">
			              <div className="head-step">
			                <div className="icon-step"><i className="fa fa-shopping-cart"></i></div>
			              </div>
			              <div className="body-step">
			                <span>Mi Carrito</span>
			              </div>
			            </li>
			            <li className="item-step check">
			              <div className="head-step">
			                <div className="icon-step"><i className="fa fa-user"></i></div>
			              </div>
			              <div className="body-step">
			                <span>Despacho</span>
			              </div>
			            </li>
			            <li className="item-step check">
			              <div className="head-step">
			                <div className="icon-step"><i className="fa fa-credit-card"></i></div>
			              </div>
			              <div className="body-step">
			                <span>Metodo de pago</span>
			              </div>
			            </li>
			            <li className="item-step active">
			              <div className="head-step">
			                <div className="icon-step"><i className="fa fa-file-text-o"></i></div>
			              </div>
			              <div className="body-step">
			                <span>Confirmación</span>
			              </div>
			            </li>
			          </ul>
			        </div>

			        { window.payment.bool_check_payed ?
				        <div className="ms-wizard-wrapper">
				          <div className="ms-lateral-a">
				            <div className="ms-wizard-item">
				              <h3 className="ms-form-title ms-bgt">Gracias por comprar en FRESHMART</h3>
				              <div className="ms-tab-content">
				                <p>Usted puede ver su historial de pedidos en cualquier momento ingresando a <a href="/mis-pedidos">mis pedidos</a> <br/>Póngase en contacto con nosotros si tiene alguna pregunta sobre su pedido.</p>
				                <p>Número de orden: {window.payment.numero_pedido} <br/>Entrega {window.payment.fecha_hora_envio}</p>
				                <table className="ms-small-table fm-resumen-payment">
				                  <tbody>
				                    <tr>
				                      <td>Subtotal</td>
				                      <td>S/.{window.payment.Subtotal}</td>
				                    </tr>

		                        	{
		                        		"products" in window.payment  && window.payment.products.length > 0 ?
		                        		window.payment.products.map((item,keyindex) =>
		                        			item.has_dsc_backo_tipo_2 &&
		                        			(
		                        				("producto" in item.descuentos) ||
		                        				("especial" in item.descuentos) 
		                        			)
		                        			 ?
			                        			
			                        				
			                        					Object.keys(item.descuentos).map(
			                        					(keyDesc, index) => 
			                        					keyDesc === "producto" || keyDesc === "especial" ?
			                        						item.descuentos[keyDesc].map(
				                        					(descpro, descindex) =>
				                        						descpro.tipo_adjustment === 2 ?
					                        						<tr className="tr-rm-main desc-cate" key={descindex}>
						                        							<td>{descpro.nombre_mostrar}</td>
						                        							<td>-S/{(descpro.monto_descuento) }</td>	                        							
						                        					</tr>																
																: null
				                        					)
			                        						: null
			                        					)
			                        				
			                        			
		                        			: null

		                        			)
		                        		: null
		                        	}

		                        	{
		                        		"descuentos_productos" in window.payment  ?
		                        				Object.keys(window.payment.descuentos_productos).map(
		                        					(keyDesc, index) => 
		                        					window.payment.descuentos_productos[keyDesc].map(
		                        						(descpro, descindex) =>
		                        						descpro.tipo_adjustment === 2 ?
			                        						<tr className="tr-rm-main desc-cate" key={descindex}>
			                        							<td>{descpro.nombre_mostrar}</td>
			                        							<td>-S/{(descpro.monto_a_descontar) }</td>	                        							
			                        						</tr>
		                        						: null
		                        					)
		                        				)
			                        		
		                        		:
		                        		null
		                        	}

		                        	<tr className="tr-rm-main tr-sub-total">
		                        		<td>Sub Total Productos</td>
		                        		<td className="ms-pr-color">S/{(window.payment.total_pedido_con_descuentos_productos) }</td>	                        							
		                        	</tr>


		                        	{
		                        		"infocupon" in window.payment && "nombre_mostrar" in  window.payment.infocupon && "tipo_adjustment" in  window.payment.infocupon && window.payment.infocupon.tipo_adjustment === "1" ?

		                        				<tr className="tr-rm-main">
		                        					<td>
		                        						<div className="block-resumen resumen-descuento-prod" >
											            	<span className="ms-grcolor">{window.payment.infocupon.nombre_mostrar}</span>
											                <span className="ms-pr-color">-S/{(window.payment.infocupon.monto_descuento) }</span>
											            </div>	
											        </td>	                        							
											    </tr>
		                        		:
		                        		null
		                        	}

		                        	{
		                        		"descuentos_globales" in window.payment  ?
		                        				Object.keys(window.payment.descuentos_globales).map(
		                        					(keyDesc, index) => 
		                        						<tr className="tr-rm-main desc-cate" key={index}>
		                        							<td>{window.payment.descuentos_globales[keyDesc].nombre_mostrar}</td>
		                        							<td>-S/{(window.payment.descuentos_globales[keyDesc].monto_a_descontar) }</td>	                        							
		                        						</tr>
		                        					
		                        				)
			                        		
		                        		:
		                        		null
		                        	}


				                    <tr>
				                      <td>Envío</td>
				                      <td>S/.{window.payment.envio}</td>
				                    </tr>
				                  </tbody>
				                  <tfoot>
				                    <tr>
				                      <td><span>Total(*)</span></td>
				                      <td><span className="ms-total">S/.{window.payment.total}</span></td>
				                    </tr>
				                  </tfoot> 
				                </table>
				                    <ul className="ms-sp-list">
				                      <li>(*) El costo total es estimado debido a que alguno de los productos solicitados tales como cárnicos, frutas o verduras se venden por peso pudiendo haber variaciones al momento del armado de su pedido. Rl costo exacto se reflejará en su recibo cuando su pedido sea despachado.</li>
				                      <li>Si usted eligió como medio de pago una tarjeta de crédito, el cargo (cobro) se realizará en el momento del despacho de su pedido.</li>
				                      <li>En éste momento sólo se ha hecho una reserva a su tarjeta por el monto estimado mas un adicional )10% en caso pueda existir alguna diferencia por pesaje. Cualquier diferencia entre lo reservado y el monto final a cobrar será imediatamente liberado.</li>
				                    </ul>
				                    <br/>
				                <p>NOTA: Tus comprobantes de pago (boletas/facturas) te llegaran desde un correo de Freshmart SAC. De igual manera; cualquier notificación de tu banco o tarjeta de crédito/debito por compras en Freshmart.pe, aparecerán a nombre de Freshmart SAC.</p>
				                <div className="ms-wrap-links">
				                  <a href="/">Iniciar otra compra</a> <a href="/mis-pedidos">Mis pedidos</a>
				                </div>
				              </div>
				            </div>
				          </div>

				          <div className="ms-lateral-b ms-despacho">
				            <div className="ms-wizard-item">
				              <div className="ms-wrap-item">
				                <div className="ms-table-item" >
				                  <div className="ms-icon-block">
				                    <i className="fa fa-lock" aria-hidden="true"></i>
				                    <span>PAGO SEGURO</span>
				                  </div>
				                  <p className="ms-small-text">Aceptamos pagos con tarjeta de crédito Visa, MasterCard, American Express y Diners Club.</p>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
			        :
				        <div className="ms-wizard-wrapper">
				          <div className="ms-lateral-a">
				            <div className="ms-wizard-item">
				              <h3 className="ms-form-title ms-bgt">Surgio un problema</h3>
				              <div className="ms-tab-content">
				                <b><p>{window.payment.message_niubiz}</p></b>
				                <br/>
				                <p>NOTA: Tus comprobantes de pago (boletas/facturas) te llegaran desde un correo de Freshmart SAC. De igual manera; cualquier notificación de tu banco o tarjeta de crédito/debito por compras en Ekonofresh.pe, aparecerán a nombre de Freshmart SAC.</p>
				                <div className="ms-wrap-links">
				                  <a href="/">Iniciar otra compra</a> <a href="/mis-pedidos">Mis pedidos</a>
				                </div>
				              </div>
				            </div>
				          </div>

				          <div className="ms-lateral-b ms-despacho">
				            <div className="ms-wizard-item">
				              <div className="ms-wrap-item">
				                <div className="ms-table-item">
				                  <div className="ms-icon-block">
				                    <i className="fa fa-lock" aria-hidden="true"></i>
				                    <span>PAGO SEGURO</span>
				                  </div>
				                  <p className="ms-small-text">Aceptamos pagos con tarjeta de crédito Visa, MasterCard, American Express y Diners Club.</p>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>  
			      }
			      </div>
			    </div>
			</main>





	        <Footer />

		</div>

	)
}
export default PageConfirmacion;