import React, { useRef } from 'react';
import { render } from 'react-dom';
//import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import EmailEditor from 'react-email-editor'

const PageEmailSignature = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
  const emailEditorRef = useRef("");

  const  exportHtml = () => {
    console.log(emailEditorRef.current.editor)
    const unlayer = emailEditorRef.current?.editor;    
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
    });

   }

	
  const onReady: EmailEditorProps['onReady'] = (unlayer) => { };


	return (
		<div id="ms-profile">

	        <div className="ms-section">
	        	<div className="ms-tab-profile">
				        <button onClick={exportHtml}>salida</button>
				        <EmailEditor ref={emailEditorRef}  onReady={onReady} />	        		        		
	        	</div>
            </div>

	        </div>
	)

}

export default PageEmailSignature;