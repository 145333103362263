const InputFiscal = ({label,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{

	const handleOnkeyPress = (event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
	};

	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
			
			{
				validated === "yes" ?
					rest.value.length === 11  ?
					<input className={clasInput} {...rest} id={rest.name} onKeyPress={handleOnkeyPress} />
					:
					<input className={clasInput+" disabled"} {...rest} id={rest.name}  onKeyPress={handleOnkeyPress} />
				:
				<input className={clasInput} {...rest} id={rest.name} onKeyPress={handleOnkeyPress} />
			}
			
		</div>
	)
}

export default InputFiscal