import { useState, useEffect } from 'react'

const TarjetasGuardadas = ({cards = [], synCards, getMyCards }) =>{
	const [showList, setShowList ] = useState(false)

	useEffect(() => {
		getMyCards()
	}, []);

	const mostrarMisTarjetas = () => {
		window.$("#modalMisTarjetas").modal("show");
	}

	return(
	<div>
		{
			cards.length > 0 ?
			<div className="fm-container-block">
	            <h3 className="ms-form-title">Pago con tarjetas de credito</h3>
	            <div className="ms-tab-content">
	                  <div className="ms-tab active" id="resument_payment">
	                     <div className="ms-item-addres">

	               	<ul className="lista-metodos">
		                           <li className="item-metodo js-contraentrega-2" >
		                              <input type="radio" className="js_metodo_pago" name="metodo_pago" id="metodo_pago_5" onClick={() => mostrarMisTarjetas() }  />
		                              <label htmlFor="metodo_pago_5">
		                              <img src="https://ekonofresh.pe/images/pago-tarjeta.png" />
		                              <span>Mis tarjetas guardadas</span>
		                              </label>
		                           </li>
		                        </ul>
		            	</div>
		            </div>
	            </div>
			</div>
			:
			null
		}
	</div>
	)

}

export default TarjetasGuardadas;