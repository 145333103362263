import MenuItem from './MenuItem'
const Menu = ({form,stats}) =>{
   const itemMenus =
      {
         "products" : 
          [
            {href:"/products",title:"Producto Activos"},
            {href:"/products/new",title:"Nuevo Producto"}
          ],
         "setting" : [
           {href:"/mi-perfil",title:"Mi perfil"},
           {href:"/mi-firma-sms",title:"Mi Firma SMS"},
           {href:"/mi-firma-email",title:"Mi Firma EMAIL"},
           {href:"/cambiar-clave",title:"Cambiar mi contraseña"}
         ],
      }

	return (
    <div className="menu-sidebar">
      <ul className="left-menu-list">
         
          {/*<MenuItem href="/" title="Dashboard" ico_class="ico-sell" counter={-1} /> */}


         {
          form.includes("orders")  ?
            <MenuItem href="/orders" title="Pedidos" ico_class="active ico-pedidos" counter={stats.sales} />   
          :
            <MenuItem href="/orders" title="Pedidos" ico_class="ico-pedidos" counter={stats.sales} />   
         }
          

         {
          form.includes("customer")  ?
            <MenuItem href="/customer" title="Mis Clientes" ico_class="active ico-client" counter={stats.customers} />
          :
            <MenuItem href="/customer" title="Mis Clientes" ico_class="ico-client" counter={stats.customers} />
         }
         

         {
          form.includes("team")  ?
            <MenuItem href="/team" title="Mi equipo" ico_class="active ico-team" counter={-1} />
         :
            <MenuItem href="/team" title="Mi equipo" ico_class="ico-team" counter={-1} />
          }

         {
          form.includes("provider")  ?
            <MenuItem href="/provider" title="Proveedores" ico_class="active ico-provider" counter={-1} />
            :
            <MenuItem href="/provider" title="Proveedores" ico_class="ico-provider" counter={-1} />
          }

         {
          form.includes("products")  ?
            <MenuItem href="/products" title="Mis Productos" ico_class="active ico-productos" counter={-1} />
            :
            <MenuItem href="/products" title="Mis Productos" ico_class="ico-productos" counter={-1} />
          }


         {
          (
            form.includes("mi-perfil") ||
            form.includes("mi-firma-sms") ||
            form.includes("mi-firma-email") ||
            form.includes("cambiar-clave")
          )
            ?
           <MenuItem title="Configuración" ico_class="active ico-settings"
              has_submenu={true}
              list_sub_menu={itemMenus["setting"]}
            />            
            :
             <MenuItem title="Configuración" ico_class="ico-settings"
                has_submenu={true}
                list_sub_menu={itemMenus["setting"]}
              />            
          }




         <li className="active-sync" id="sync-text">
            <div className="ms-float-modal-alert">
               <i className="active-sync-bullet"></i>
               <div className="ms-body">
               </div>
            </div>
         </li>
      </ul>
    </div>
	)
}

export default Menu