import BlockCart from './BlockCart'
import { useState } from 'react'

const Producto = ({prod,list_cart,showImage = false,methodShopping,template=null}) =>{
   const [cantidad,setCantidad] = useState(prod.cantidad_inicial)
   let msgDescuento = "";
   
   if( [2,"0",0].includes( prod.status_service ) ){
    msgDescuento = "disabled";
   }

   const handleAumentarCantidad = (cant) => {
      setCantidad(cant)
   }

    const handleAddCard = async () => {
        let distritoPay = parseInt(localStorage.distritoPay);
        if ( !isNaN(distritoPay) ) {

            window.$("."+prod.sku).addClass("addactive");

            if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );              
              
              const dataq = new FormData();
              dataq.append("type", "add" );
              dataq.append("action", "shop_add_cart" );
              dataq.append("id_producto", prod.id );
              dataq.append("cantidad", parseFloat(cantidad) );
              if (localStorage.methodshopping === "express" ) {
                dataq.append("is_express", "1" );
              }else{
                  dataq.append("is_express", "0" );
              }              
             const response = await fetch(window.path_service+"/api/add-cart",{method:'POST',body: dataq, headers: myHeaders })
             const data = await response.json()

             if (data.status) {
                //console.log("added success")
                if ( typeof fbq !== "undefined" ) { 
                  window.fbq('track', 'AddToCart',{
                      content_type: 'product',
                      content_ids: prod.sku
                  });
                }
             }

            }else{
              let ecart = JSON.parse(localStorage.getItem('my_shopping_car'));
              if( [null,"null",undefined,"undefined"].includes(localStorage.getItem('my_shopping_car')) ){
                 ecart = [];
              }
              
              let quantity_fin = prod.cantidad_inicial
              const existProduct = ecart.map(function(item){ return item['id']; }).indexOf( prod.id );

              if( existProduct === -1 ){
                 ecart.push({
                    id: prod.id,
                    quantity:parseFloat(cantidad)
                 });
              }else{         
                 quantity_fin = parseFloat(ecart[existProduct].quantity) + parseFloat(cantidad);
                 ecart[existProduct].quantity = quantity_fin;
              }

              localStorage.setItem('my_shopping_car', JSON.stringify(ecart) );
              //list_shopping_cart();      

            }
            //EFECTO ADD TO CART
            const cart = window.$('#cartList');
            if (cart.length) {
                const imgtodrag = window.$('#'+prod.sku);

                if (imgtodrag) {
                  const imgclone = imgtodrag.clone()
                            .offset({
                            top: imgtodrag.offset().top,
                            left: imgtodrag.offset().left
                        }).css({
                            'opacity': '0.5',
                                'position': 'absolute',
                                'height': '150px',
                                'width': '150px',
                                'z-index': '100'
                        })
                            .appendTo(window.$('body'))
                            .animate({
                            'top': cart.offset().top + 10,
                                'left': cart.offset().left + 10,
                                'width': 75,
                                'height': 75
                        }, 1000);
                        

                        imgclone.animate({
                            'width': 0,
                                'height': 0
                        }, function () {
                            window.$(this).detach()
                        });
                    }
            }else{
              window.modaLoadingcart = 
              window.Swal.fire({
                imageUrl: window.path_service+'/images/loading.png',
                title: 'añadiendo al carrito...',
                showConfirmButton: false,
                customClass: 'ms-loading-modal',
                allowOutsideClick: false
              });              
            }
            
                //EFECTO ADD TO CART

            list_cart()

        }else{
            window.$("#modalSelectionDistrito").modal("show")
        }
   };

  if ( template === "expand" ) {

    return(
      <li className="item-prod">
            <div className={"fm-item-prod "+prod.sku + " " + msgDescuento }>
              <div className="fm-features">           
                  { 
                    prod.tiene_descuento  === 1 ? 
                        <div className="ms-label-desc">{prod.mensaje_oferta_corto}</div>
                    :
                    null
                  }                
                <div className="fm-left-prod">
                  <a href={prod.link}>
                    {
                      showImage ?
                      <img className="fm_img" src={prod.image} alt={prod.name} id={prod.sku} />
                      :
                      <img className="fm_img shop-lazy-image" data-original={prod.image} alt={prod.name} id={prod.sku} />
                    }                    
                  </a>
                </div>              
                <div className="fm-rigth-prod">
                  { 
                    ( prod.tiene_descuento  === 1  && prod.mensaje_oferta !== "" && prod.mensaje_oferta !== null ) ? 
                        <div className="ms-offert-long">{prod.mensaje_oferta}</div>
                    :
                    null
                  }

                  <div className="prod-price">
                  <sub>s/.</sub>
                    {
                      prod.tiene_descuento  === 1 ?
                       prod.nuevo_precio  
                      :
                       prod.price  
                    }
                  </div>
                  <p className="fm-unidad-medida">{prod.unidad_medida}
                    {
                      prod.equivalencia_texto !== "" ?
                        ' ('+prod.equivalencia_texto+')'

                      :
                      null
                    }
                </p>
                  <a className="title" href={prod.link}>
                    <h3>{prod.name}</h3>
                  </a>
                </div>
              </div>

                    { 
                      (prod.has_stock === "true" && prod.has_express === "true" ) ?
                        <div className="icon-express">
                          {
                            showImage ?
                            <img src={window.path_service+"/images/express.svg?2"} className="img-express" alt="Express" />
                            :
                            <img data-original={window.path_service+"/images/express.svg?2"} className="img-express shop-lazy-image" alt="Express" />
                          }
                        </div>    
                      :
                      null
                    }
                                  
                    <div className="eko-block-add-cart">
                       <BlockCart 
                       methodShopping = {methodShopping}
                       prod = {prod}
                       handleAumentarCantidad = {handleAumentarCantidad}
                       />

                       {
                        prod.has_stock === "true" ?
                        <div className="js-eko-add-cart" onClick={handleAddCard}>
                            <button type="submit" className="shop-button  js-add-cart-block">Agregar al carrito</button>
                          </div>
                          :
                         <div className="js-eko-add-cart disabled">
                            <button disabled type="submit" className="shop-button  js-add-cart-block">No Disponible</button>
                         </div>
                       }
                    </div>

            </div>  
      </li>

    )

  }else if (template === "recomendados") {
    return(
      <li className="item-prod prod-recomendados">
            <div className={"fm-item-prod "+prod.sku + " " + msgDescuento }>
              <div className="fm-features">           
                  { 
                    prod.tiene_descuento  === 1 ? 
                        <div className="ms-label-desc">{prod.mensaje_oferta_corto}</div>
                    :
                    null
                  }                
                <div className="fm-left-prod">
                  <a href={prod.link}>
                    {
                      showImage ?
                      <img className="fm_img" src={prod.image} alt={prod.name} id={prod.sku} />
                      :
                      <img className="fm_img shop-lazy-image" data-original={prod.image} alt={prod.name} id={prod.sku} />
                    }                    
                  </a>
                </div>              
                <div className="fm-rigth-prod">
                  { 
                    ( prod.tiene_descuento  === 1  && prod.mensaje_oferta !== "" && prod.mensaje_oferta !== null ) ? 
                        <div className="ms-offert-long">{prod.mensaje_oferta}</div>
                    :
                    null
                  }

                  <div className="prod-price">
                  <sub>s/.</sub>
                    {
                      prod.tiene_descuento  === 1 ?
                       prod.nuevo_precio  
                      :
                       prod.price  
                    }
                  </div>
                  <p className="fm-unidad-medida">{prod.unidad_medida}
                    {
                      prod.equivalencia_texto !== "" ?
                        ' ('+prod.equivalencia_texto+')'

                      :
                      null
                    }
                </p>
                  <a className="title" href={prod.link}>
                    <h3>{prod.name}</h3>
                  </a>
                </div>
              </div>

                    { 
                      (prod.has_stock === "true" && prod.has_express === "true" ) ?
                        <div className="icon-express">
                          {
                            showImage ?
                            <img src={window.path_service+"/images/express.svg?2"} className="img-express" alt="Express" />
                            :
                            <img data-original={window.path_service+"/images/express.svg?2"} className="img-express shop-lazy-image" alt="Express" />
                          }
                        </div>    
                      :
                      null
                    }
                                  
                    <div className="eko-block-add-cart">
                       <BlockCart 
                       methodShopping = {methodShopping}
                       prod = {prod}
                       handleAumentarCantidad = {handleAumentarCantidad}
                       />

                       {
                        prod.has_stock === "true" ?
                        <div className="js-eko-add-cart" onClick={handleAddCard}>
                            <button type="submit" className="shop-button  js-add-cart-block">Agregar al carrito</button>
                          </div>
                          :
                         <div className="js-eko-add-cart disabled">
                            <button disabled type="submit" className="shop-button  js-add-cart-block">No Disponible</button>
                         </div>
                       }
                    </div>

            </div>  
      </li>

    )
  }else if (template === "recomendados_main") {
    return(
      <li className="item-prod prod-recomendados">
            <div className={"fm-item-prod "+prod.sku + " " + msgDescuento }>
              <div className="fm-features">           
                  { 
                    prod.tiene_descuento  === 1 ? 
                        <div className="ms-label-desc">{prod.mensaje_oferta_corto}</div>
                    :
                    null
                  }                
                <div className="fm-left-prod">
                  <a href={prod.link}>
                    {
                      showImage ?
                      <img className="fm_img" src={prod.image} alt={prod.name} id={prod.sku} />
                      :
                      <img className="fm_img shop-lazy-image" data-original={prod.image} alt={prod.name} id={prod.sku} />
                    }                    
                  </a>
                </div>              
                <div className="fm-rigth-prod">
                  { 
                    ( prod.tiene_descuento  === 1  && prod.mensaje_oferta !== "" && prod.mensaje_oferta !== null ) ? 
                        <div className="ms-offert-long">{prod.mensaje_oferta}</div>
                    :
                    null
                  }

                  <div className="prod-price">
                  <sub>s/.</sub>
                    {
                      prod.tiene_descuento  === 1 ?
                       prod.nuevo_precio  
                      :
                       prod.price  
                    }
                  </div>
                  <p className="fm-unidad-medida">{prod.unidad_medida}
                    {
                      prod.equivalencia_texto !== "" ?
                        ' ('+prod.equivalencia_texto+')'

                      :
                      null
                    }
                </p>
                  <a className="title" href={prod.link}>
                    <h3>{prod.name}</h3>
                  </a>
                </div>
              </div>

                    { 
                      (prod.has_stock === "true" && prod.has_express === "true" ) ?
                        <div className="icon-express">
                          {
                            showImage ?
                            <img src={window.path_service+"/images/express.svg?2"} className="img-express" alt="Express" />
                            :
                            <img data-original={window.path_service+"/images/express.svg?2"} className="img-express shop-lazy-image" alt="Express" />
                          }
                        </div>    
                      :
                      null
                    }

            </div>  
      </li>

    )
  }else{

    return(
           <div className="col-12 eko_product_item_block ">
              <div className={"eko-product-item "+prod.sku + " " +  msgDescuento }>
                 <div className="eko_card_img">
                    <div className="eko_thumb_img">
                       <a className="eko_link_img" href={prod.link}>
                          {
                            showImage ?
                          <img className="eko_img" src={prod.image} alt={prod.name} id={prod.sku} />
                          :
                          <img className="eko_img shop-lazy-image" data-original={prod.image} alt={prod.name} id={prod.sku} />
                          }
                        </a>
                    </div>
                    { 
                      prod.tiene_descuento  === 1 ?
                          <div className="ms-label-desc">{prod.mensaje_oferta_corto}</div>
                      :
                      null
                    }

                    { 
                      ( prod.tiene_descuento  === 1  && prod.mensaje_oferta !== "" && prod.mensaje_oferta !== null ) ? 
                          <div className="ms-offert-long">{prod.mensaje_oferta}</div>
                      :
                      null
                    }


                    { 
                      (prod.has_stock === "true" && prod.has_express === "true" ) ?
                        <div className="icon-express">
                          {
                            showImage ?
                            <img src={window.path_service+"/images/express.svg?2"} className="img-express" alt="Express" />
                            :
                            <img data-original={window.path_service+"/images/express.svg?2"} className="img-express shop-lazy-image" alt="Express" />
                          }
                        </div>    
                      :
                      null
                    }

                 </div>
                 <div className="product engoj_grid_parent relative">
                    <h4 className="des-font capital title-product mb-0 eko-title-block-product">
                       <a href={prod.link}>{prod.name}</a>
                    </h4>
                    <p className="price-product mb-0">
                    {
                      prod.tiene_descuento  === 1 ?
                      <span className="price">S/. {prod.nuevo_precio}</span>
                      :
                      <span className="price">S/. {prod.price}</span>
                    }
                       <span className="unidad"> {prod.unidad_medida}</span>
                    </p>


                    <div className="eko-block-add-cart">
                       <BlockCart 
                       methodShopping = {methodShopping}
                       prod = {prod}
                       handleAumentarCantidad = {handleAumentarCantidad}
                       />

                       {
                        prod.has_stock === "true" ?
                        <div className="js-eko-add-cart" onClick={handleAddCard}>
                            <button type="submit" className="shop-button  js-add-cart-block">Agregar al carrito</button>
                          </div>
                          :
                         <div className="js-eko-add-cart disabled">
                            <button disabled type="submit" className="shop-button  js-add-cart-block">No Disponible</button>
                         </div>
                       }
                    </div>


                 </div>
              </div>
           </div>
    )

  }
}
export default Producto