import { useState,useEffect } from 'react'
import Input from '../Input'
import InputFile from '../Input/InputFile'
import Select from '../Select'

window.xhrgallery = null;
window.xhrBanner = null;

const Category = ({type_page}) =>{
	const [listCat, setListCat ] = useState([])
	let xhrBanner = null;
	let modaLoading;
	const [value, setValue ] = useState( 
		{
			id : null,
			name:"",
			link:"",
			status:"1",
			keyword:"",		
			isotipo:"",
			imgBanner:"",
			categoryMain:"",
			posicion_busqueda : 99999
		} 
	)

	console.log(value)

	const urlFriendly = (value) => {
		return value == undefined ? '' : value.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
	}

	
	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))

	    if (e.target.name == "name") {
	    	
			setValue((state) => ({
		      ...state,
		      ["link"]: urlFriendly(e.target.value)
		    }))


	    }
	}

	const handleChangeFile = (e,keyname = null) => {
		if (e === null) {
			setValue((state) => ({
		      ...state,
		      [keyname]: ""
		    }))	

		}else{
			setValue((state) => ({
		      ...state,
		      [e.target.name]: e.target.files[0]
		    }))			
		}

	}

	const getApiData = async () => {
			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/category/show",{method:'POST',body: JSON.stringify({id:window.detailpath}), headers: myHeaders})
				const data = await response.json()
		       
		       if (data.status) {
					setValue({
						id : data.data.id,
						name: data.data.name,
						link: data.data.slug,
						status: String(data.data.status),
						keyword:"",		
						isotipo: ( !["",null].includes(data.data.image_home) ? data.data.image_home+"?"+Date.now() : "" ) ,
						imgBanner: ( !["",null].includes(data.data.image_banner) ? data.data.image_banner+"?"+Date.now() : "" ) ,
						categoryMain: ( !["",null].includes(data.data.id_parent) ? data.data.id_parent : "" ) ,
						posicion_busqueda : data.data.position
					} )					

		        }
		    }		

	};

	const listCategory = async() =>{

        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
			
			const dataq = new FormData();
			const response = await fetch(window.path_service+"/category/list",{method:'POST',body: JSON.stringify({status:1,id_parent:0}), headers: myHeaders })
			const data = await response.json()


			if ( data.hasOwnProperty("status") && data.status ) {
				setListCat(data.data)
			}
			
		}
		
	}

	useEffect(() => {

		window.xhrgallery = null;

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}else{
				listCategory()

				if ( !isNaN(window.detailpath) ) {
					getApiData();
				}				
			}
	}, []);

	const saveData = async(event) =>{
						if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
					        var myHeaders = new Headers();
					        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

						    value.birthday = value.year+"-"+value.month+"-"+value.day;

						    
						    let apiRequest = window.path_service+"/category/add";
							if ( type_page === "edit"  ) {
								apiRequest = window.path_service+"/category/update"
							}			    

							const response = await fetch(apiRequest,{method:'POST',body:  JSON.stringify(
								{
									id : value.id,
									name : value.name,
									link : value.link,
									status : value.status,
									idParent : value.categoryMain,
									position : value.posicion_busqueda,
									isotipo : value.isotipo,
									imgBanner : value.imgBanner
								}			    
								) , headers: myHeaders})
							const data = await response.json()

							modaLoading.close();			

							if ( data.hasOwnProperty("status") && data.status ) {
								window.Swal.fire({
						            icon: 'success',
						            title: data.message,
						            showConfirmButton: false,
						            timer: 4500
						        });         					
								
								if ( type_page === "new"  ) {
									setTimeout(() => {
									  window.location.href = "/category";
									}, 4500 );
								}

							}else{
							  	window.Swal.fire({
							          icon: 'error',
							          title: data.message != "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
							          showConfirmButton: false,
							          timer: 2500
							    }); 					
							}
						}	

	}

	const submit = async(event) =>{
		event.preventDefault();

		uploadFile()

		window.uploadStatusFiles = setInterval(function () {
                    if (
                        (typeof value.isotipo != 'object') &&
                        (typeof value.imgBanner != 'object')                        
                    ) {
                        clearInterval(window.uploadStatusFiles);
                        saveData()
                    }

        }, 1000);

			

	}


	const uploadFile = async() =>{
		//event.preventDefault();		

		let idfile = 1
		
		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

		window.uploadgallery = setInterval(function () {
			
	        if (value.isotipo !== null && value.isotipo !== '' && typeof value.isotipo === 'object') {
	            var formdata = new FormData();
	            formdata.append("type", value.isotipo);
	            formdata.append("id_file", idfile );
	            formdata.append("dir", 'category/');
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

	            if (window.xhrgallery === null) {
	                window.xhrgallery = window.$.ajax({
	                    url: window.path_service+"/category/upload_file",
	                    type: "POST",
	                    async: true,
	                    processData: false,
	                    contentType: false,
	                    data: formdata,
	                    dataType: "json",
	                    headers : {"Authorization": "Bearer "+ localStorage.token_authlead  }, 
	                    success: function (response1) {
	                        window.xhrgallery = null
	                        if ( response1.hasOwnProperty("status") ) {
	                        	if (response1.status) {
	                            	value.isotipo = response1.path_image;
	                        	}else{

									window.Swal.fire({
							            icon: response1.status?'success':'error',
							            title: response1.message,
							            showConfirmButton: false,
							            timer: 3500
							        });  	

	                        	}
								


	                        }
	                    }
	                });
	            }
	        }else{
	        	clearInterval(window.uploadgallery);
	        }
		
		} , 1500 );
	        


		window.uploadBanner = setInterval(function () {
	        if (value.imgBanner !== null && value.imgBanner !== '' && typeof value.imgBanner === 'object') {
	            var formdata = new FormData();
	            formdata.append("type", value.imgBanner);
	            formdata.append("id_file", idfile );
	            formdata.append("dir", 'category/');
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

	            if (window.xhrBanner === null) {
	                window.xhrBanner = window.$.ajax({
	                    url: window.path_service+"/category/upload_file",
	                    type: "POST",
	                    async: true,
	                    processData: false,
	                    contentType: false,
	                    data: formdata,
	                    dataType: "json",
	                    headers : {"Authorization": "Bearer "+ localStorage.token_authlead  }, 
	                    success: function (response2) {
	                        window.xhrBanner = null
	                        if ( response2.hasOwnProperty("status") ) {
	                        	if (response2.status) {
	                            	value.imgBanner = response2.path_image;
	                        	}else{

									window.Swal.fire({
							            icon: response2.status?'success':'error',
							            title: response2.message,
							            showConfirmButton: false,
							            timer: 3500
							        });  	                            

	                        	}
								


	                        }
	                    }
	                });
	            }

	        }else{
	        	clearInterval(window.uploadBanner);
	        }
		
		} , 1500 );
    

	}


	return (
		<div className="ms-form-payment-register">

		            <form className="fm-form-user" onSubmit={submit}>

					      <Input 
					        label ="Nombre de Categoria*"
					        validated="yes"
					        name="name"
					        value = {value.name}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label ="Link de Categoria*"
					        name="link"
					        value = {value.link}
					        readOnly
					        />

					        <Select
						        label = "Categoria*"
						        name="categoryMain"
						        value = {value.categoryMain}
						        onChange = {handleChangeForm}

					        >
			                  <option value="">Principal</option>
			                  {
			                  	listCat.map(x => 
			                  	<option value={x.id}>{x.name}</option>
			                  	)
			                  }
					        </Select>






<fieldset className="groupForm">
			              <div className="ms-item fm-block-genero">
			                  <div className="ms-item ms-checbox">
			                    <input type="radio" className="js-profile-gender" name="status" id="statusActive" value="1" 
			                    checked={value.status === "1"} onChange = {handleChangeForm}
			                      />
			                    <label htmlFor="statusActive">Activo en lista Compra</label>
			                  </div>

			                  <div className="ms-item ms-checbox">
			                    <input type="radio" className="js-profile-gender" name="status" id="statusInactive" value="2" 
			                    checked={value.status === "2"} onChange = {handleChangeForm}
			                      />
			                    <label htmlFor="statusInactive">Desactivado (No Visible)</label>
			                  </div>
			              </div>
        </fieldset>



 <fieldset className="groupForm">

					      <Input 
					        label = "Palabras Clave de Busqueda*"
					        validated="yes"
					        name="keyword"
					        value = {value.keyword}
					        onChange = {handleChangeForm}
					        />


					      <Input 
					        label = "Posición de Busqueda*"
					        validated="yes"
					        name="posicion_busqueda"
					        value = {value.posicion_busqueda}
					        onChange = {handleChangeForm}
					        />
					        

 </fieldset>

						    <InputFile
						        label = "Isotipo"
						        validated="yes"
						        name="isotipo"
						        valobj = {value.isotipo}
						        changeFiles = {handleChangeFile}
						        />


					      <InputFile
					        label = "Banner"
					        validated="yes"
					        name="imgBanner"
					        valobj = {value.imgBanner}
					        changeFiles = {handleChangeFile}
					        />

					        
					        <br/> 

		                   {
						    (
						    	value.name !== ""
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Cambios</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Cambios</button>
		                   </div>		                   
		                   }

		            </form>

		</div>
	)
}

export default Category;