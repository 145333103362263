import { useState, useEffect ,useRef } from 'react'
import Input from './Input'
import Select from './Select'

const FormPayu = ({showAddCard = false }) =>{
	const [showList, setShowList ] = useState(false)
	const [cards, setCards ] = useState([])

	const [months,setMonths] = useState([
	      {id:1,name:"Enero"},
	      {id:2,name:"Febrero"},
	      {id:3,name:"Marzo"},
	      {id:4,name:"Abril"},
	      {id:5,name:"Mayo"},
	      {id:6,name:"Junio"},
	      {id:7,name:"Julio"},
	      {id:8,name:"Agosto"},
	      {id:9,name:"Setiembre"},
	      {id:10,name:"Octubre"},
	      {id:11,name:"Noviembre"},
	      {id:12,name:"Diciembre"}
	  ])

	const yearNow= (new Date().getFullYear() );
	let Coyears = [];
	for (let i = (yearNow-2); i <= (yearNow+21) ; i++) {
		Coyears.push(i);
	}
	const years = Coyears;


	const [value, setValue ] = useState(
	{
		payu_titular:"",
		payu_numero_tarjeta:"",
		payu_expiracion_mes:"",
		payu_expiracion_anio:"",
		payu_codigo:"",
		save_cart : false
	}
		)

	useEffect(() => {
		getApiData()
	}, []);

	const handleShowAll = () =>{
		setShowList(!showList)
	}

	const paydinamic = async(event) =>{
		
		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

			if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
				var myHeaders = new Headers();
			    const dataq = new FormData();
				const datavalue = Array.from(new FormData(event.target))
				//console.log(Object.fromEntries(datav));
			    for(let [key, val] of datavalue) {
			    	dataq.append(key, val );
			    }
			    dataq.append("id_card_user", event.id );
			    dataq.append("id_orden", localStorage.id_orden );
			    dataq.append("id_comercio", localStorage.getItem('comercioId') );
			    
			    myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );
				const response = await fetch(window.path_service+"/metodo_pago/payment/dinamic/payu",{method:'POST',body:  dataq, headers: myHeaders })
				const data = await response.json()

				if ( data.hasOwnProperty("status") && data.status ) {
					const id_orden =localStorage.id_orden;
			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          });         	

			         if ( ![null,undefined,0].includes(localStorage.id_orden) ) {
						localStorage.setItem('my_shopping_car', JSON.stringify([]) );
						localStorage.setItem('paymnet_cupon','');
						localStorage.removeItem("id_orden");
						window.location.href = "/confirmacion?process=finish&checkout=simple&orden="+id_orden;
			         }

					modaLoading.close();
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 2500
				    }); 
				    modaLoading.close();					
				}
			}

	}

	const getApiData = async () => {
        if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined && !showAddCard ) {
	        var myHeaders = new Headers();
	        myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );
	        const response = await fetch(window.path_service+"/metodo_pago/mylist/cart",{method:'POST', headers: myHeaders })	  
	        const data = await response.json()
	        console.log(data)
	        
	        if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") && data.data.length > 0 ) {
			  	setCards(data.data)
			}

        }

	};

	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const submit = async(event) =>{
		event.preventDefault();

		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });
		  			


			if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
				var myHeaders = new Headers();
			    const dataq = new FormData();
				const datavalue = Array.from(new FormData(event.target))
				//console.log(Object.fromEntries(datav));
			    for(let [key, val] of datavalue) {
			    	dataq.append(key, val );
			    }
			    dataq.append("id_orden", localStorage.id_orden );
			    dataq.append("id_comercio", localStorage.getItem('comercioId') );
			    
			    myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );
				const response = await fetch(window.path_service+"/metodo_pago/add/cart",{method:'POST',body:  dataq, headers: myHeaders })
				const data = await response.json()

				if ( data.hasOwnProperty("status") && data.status ) {
					const id_orden =localStorage.id_orden;
			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          });         	

			         if ( ![null,undefined,0].includes(localStorage.id_orden) ) {
						localStorage.setItem('my_shopping_car', JSON.stringify([]) );
						localStorage.setItem('paymnet_cupon','');
						localStorage.removeItem("id_orden");
						window.location.href = "/confirmacion?process=finish&checkout=simple&orden="+id_orden;
			         }

					modaLoading.close();
				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 2500
				    }); 
				    modaLoading.close();					
				}
			}
	}

	return(
		<div className="fm-container-block">


		{
			!showAddCard ?
				cards.length > 0 ?
				<ul class="ms-addres-list js-list-cart">
				{
					cards.map(
								(xcard,xindex) =>
						          <li key={xindex}>
						              <label className="ms-item" for="addressitem">
						                <span>{xcard.card}</span>
						                <span>{xcard.brand}</span>
						                <span className="ms-radio-style">Tarjeta Activa</span>
						                </label>
						          </li>
								)
				}
				</ul>			
				:
				<p>No tienes tarjetas guardadas.</p>
			:
				null
		}

			
			{
				showAddCard ? 
		            <form className="form-payment-payu" onSubmit={submit}>
		                  <div className="visible">

		                     <div className="relative py-3">
							      <Input 
							        label = "Nombre del Titular"
							        validated="yes"
							        name="payu_titular"
							        value = {value.payu_titular}
							        onChange = {handleChangeForm}
							        />

							      <Input 
							        label = "Número de Tarjeta"
							        validated="yes"
							        name="payu_numero_tarjeta"
							        placeholder="**** **** **** ****"
							        value = {value.payu_numero_tarjeta}
							        onChange = {handleChangeForm}
							        />

							        <div className="block-m-a">

								        <Select
									        label = "Mes de expiración"
									        validated="yes"
									        name="payu_expiracion_mes"
									        value = {value.payu_expiracion_mes}
									        onChange = {handleChangeForm}
								        >
								        <option value="">Seleccione</option>
								        {
								        	months.map(
								        		mes =>
								        		<option key={mes.id} value={mes.id}>{mes.name}</option>
								        		)
								        }
								        </Select>				        

								        <Select
									        label = "Año de expiración"
									        validated="yes"
									        name="payu_expiracion_anio"
									        value = {value.payu_expiracion_anio}
									        onChange = {handleChangeForm}
								        >
								        <option value="">Seleccione</option>
								        {
								        	years.map(
								        	(year,index) =>
								        		<option key={index} value={year}>{year}</option>
								        	)
								       	}
								        </Select>

							        </div>

							      <Input 
							        label = "Codigo CVV"
							        validated="yes"
							        name="payu_codigo"
							        value = {value.payu_codigo}
							        onChange = {handleChangeForm}
							        />


						            <div className="form-check">
						                <input className="form-check-input" type="checkbox" id="save-cart" 
						                onChange={handleChangeForm} name='save_cart' checked={value.save_cart}
						                 />
						                <label htmlFor="save-cart" className="form-check-label check-label">
						                    Guardar Tarjeta
						                </label>
						            </div>

				                   {
								    (
								    	value.payu_titular !== "" && 
									    value.payu_numero_tarjeta !== "" && 
									    value.payu_numero_tarjeta !== "" && 
									    value.payu_codigo !== "" && 
									    value.payu_expiracion_anio !== "" 
									)
				                   	?
				                   <div className="fm-item-form">
				                     <button className="fm-btn-opera">PAGAR Y FINALIZAR</button>
				                   </div>
				                   :
				                   <div className="fm-item-form disabled">
				                     <button disabled className="fm-btn-opera">PAGAR Y FINALIZAR</button>
				                   </div>		                   
				                   }

				                </div>
		                  </div>
		            </form>
		        :
		        null
			}

		</div>
	)

}

export default FormPayu;