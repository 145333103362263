import { useState, useEffect ,useRef } from 'react'
import Input from './Input'
import Select from './Select'

const FormFilter = ({showAddCard = false }) =>{

	const [value, setValue ] = useState(
	{
		insearch:"",
		pricerange_min:0,
		pricerange_max:0,
		daterange_min: null,
		daterange_max: null,
		stockrange:null
	}
		)

console.log(value)
	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	useEffect(() => {
		
	}, []);

	const activeFilter = (e,filter) =>{
			window.$(".item-filter").removeClass("active");

		if (!window.$("."+filter).hasClass("active") ) {
			window.$("."+filter).addClass("active");
		}else{
			window.$(".item-filter").removeClass("active");
		}
	}

	const submit = async(event) =>{

		event.preventDefault();
		window.$(".item-filter").removeClass("active");

		var modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		 });
		  			


        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
			
			const dataq = new FormData();
			const datavalue = Array.from(new FormData(event.target))

			for(let [key, val] of datavalue) {
				dataq.append(key, val );
			}
			
			dataq.append("id_orden", localStorage.id_orden );
			dataq.append("id_comercio", localStorage.getItem('comercioId') );

			const response = await fetch(window.path_service+"/product/list",{method:'POST',body:  dataq, headers: myHeaders })
			const data = await response.json()


			if ( data.hasOwnProperty("status") && data.status ) {
				const id_orden =localStorage.id_orden;
			    window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			    });         	

			         if ( ![null,undefined,0].includes(localStorage.id_orden) ) {
						localStorage.setItem('my_shopping_car', JSON.stringify([]) );
						localStorage.setItem('paymnet_cupon','');
						localStorage.removeItem("id_orden");
						window.location.href = "/confirmacion?process=finish&checkout=simple&orden="+id_orden;
			         }

					modaLoading.close();
			}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message,
				          showConfirmButton: false,
				          timer: 2500
				    }); 
				    modaLoading.close();					
			}
		}
		
	}

	return(
        <div className="filter-container">
           <div className="ib-fheader">
              <div className="ib-fhpa">
                 <div className="ib-fhpa-minisearch">
                    <input id="ib-fmsearch-a" className="ib-fmsearch" autocorrect="off" autocomplete="disabled" 
                    type="text" placeholder="Ingresa el nombre producto, sku" 
                    name="insearch"
                    value={value.insearch}  
                    onChange = {handleChangeForm} />
                    <div className="ib-fmsubmit ib-icon-search ib-kw-tg-search">
                      <span className="fa fa-search"></span>
                    </div>
                 </div>

              </div>
              <div className="ib-fhpb">
                 <ul className="ib-fhpb-openers">
                    
                    <li className="item-filter range-price pricelist" onClick={(e)=>activeFilter(e,"pricelist") }>
                       <div className="ib-oiwrapper"><span className="ib-iotxt ib-lbl-price-ntf">$150K - Any Price</span></div>
                       <div className="minfil-range">
                          <div className="ib-fititle">Rango Precio Lista</div>
                          <div className="ib-ficontent ib-price-range-outer">
                             <div className="ib-price-range-wrap ib-price-range-wrap-sale" style={{display:"block"}}>
                                <div className="ib-wimputs">
                                   <input id="ib-ffrom-sale-mrs"  type="text" placeholder="min precio"
				                    name="pricerange_min"
				                    value={value.pricerange_min}  
				                    onChange = {handleChangeForm}
                                    />
                                   <span className="ib-iftxt">Hasta</span>
                                   <input id="ib-ffrom-sale-mxrs" type="text" placeholder="max precio"
				                    name="pricerange_max"
				                    value={value.pricerange_max}  
				                    onChange = {handleChangeForm}
                                    />
                                </div>
                                <div className="ib-wrange">
                                   <div className="ib-range ib-rprice-sale ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                                      <div className="ui-slider-range ui-corner-all ui-widget-header" ></div>
                                      <span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </li>

                    <li className="item-filter datefil" onClick={(e)=>activeFilter(e,"datefil") }>
                       <div className="ib-oiwrapper"><span className="ib-iotxt ib-lbl-price-ntf">Cualquier fecha</span></div>
                       <div className="minfil-range">
                          <div className="ib-fititle">Fecha</div>
                          <div className="ib-ficontent ib-price-range-outer">
                             <div className="ib-price-range-wrap ib-price-range-wrap-sale" style={{display:"block"}}>
                                <div className="ib-wimputs">
                                  <input type="datetime-local"  id="meeting-time-min"
                                  	min="1975-06-07T00:00"  
                                  	max="2024-06-14T00:00"
				                    name="daterange_min"
				                    value={value.daterange_min}  
				                    onChange = {handleChangeForm}                                  
                                   />
                                   <span className="ib-iftxt">Hasta</span>
                                   <input type="datetime-local"  id="meeting-time-max"
                                  	min="1975-06-07T00:00"  
                                  	max="2024-06-14T00:00"
				                    name="daterange_max"
				                    value={value.daterange_max}  
				                    onChange = {handleChangeForm}                                  
                                    />

                                </div>
                                <div className="ib-wrange">
                                   <div className="ib-range ib-rprice-sale ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                                      <div className="ui-slider-range ui-corner-all ui-widget-header"></div>
                                      <span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </li>

                    <li className="item-filter stockfil" onClick={(e)=>activeFilter(e,"stockfil") } >
                       <div className="ib-oiwrapper"><span className="ib-iotxt ib-lbl-bath-ntf">Cualquier Stocks</span></div>
                       <div className="minfil-range">
                          <div className="ib-fititle">Stock</div>
                          <div className="ib-ficontent">
                             <div className="ib-wrange">
                                <div className="ib-range ib-rbathrooms ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                                   <div className="ui-slider-range ui-corner-all ui-widget-header" ></div>
                                   <span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" ></span>
                                </div>
                                <ul className="ib-rmarkers">
                                   <input type="number" className="rangefilter" step="0.1" name="stockrange" max="99999" value={value.stockrange}onChange = {handleChangeForm}  min="0.1" />
                                </ul>
                             </div>
                          </div>
                       </div>
                    </li>

                    <li className="item-filter sended" onClick={(e)=>submit(e) } >
                       <div className="wrapper-filter"><a className="btn-filter">Buscar</a></div>
                    </li>

                 </ul>
              </div>
           </div>
        </div>
	)

}

export default FormFilter;