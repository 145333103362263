import { useState,useEffect } from 'react'
import Input from '../Input'
import InputPrice from '../Input/InputPrice'
import Select from '../Select'
import InputFile from '../Input/InputFile'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Product = ({type_page}) =>{
	const [listCat, setListCat ] = useState([])
	const [listSubcat, setListSubcat ] = useState([])
	let xhrgallery = null;
	let modaLoading;

	const [value, setValue ] = useState( 
		{
			id : null,
			name:"",
			slug:"",
			sku:"",
			status:"1",
			equivalencia_texto:"",
			palabras_clave:"",
			precio_costo:0,
			moneda : "",
			price:"",
			quantity_initial:0,
			quantity_max:0,	
			description:"",
			gallery:"",
			imgBanner:null
		} 
	)

	const getCurrencyinfo= (idmoneda) => {
		
		const infoCurrency = window.listMoneda.filter(function(item) {
		    return item.id==idmoneda
		})

		return (infoCurrency.length === 1) ? infoCurrency[0] : [];
	}

	const urlFriendly = (value) => {
		return value == undefined ? '' : value.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
	}

	const listCategory = async(vid_parent = 0) =>{

        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
			
			const dataq = new FormData();
			const response = await fetch(window.path_service+"/category/list",{method:'POST',body: JSON.stringify({status:1,id_parent:vid_parent}), headers: myHeaders })
			const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {
					
					if (vid_parent === 0 ) {
						setListCat(data.data)					
					}else{
						setListSubcat(data.data)
					}					
				}
			
		}
		
	}

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}else{
				listCategory()

				if ( !isNaN(window.detailpath) ) {
					getApiData();
				}

			}
	}, []);



	const handleChangeFile = (e,keyname = null) => {
		if (e === null) {
			setValue((state) => ({
		      ...state,
		      [keyname]: ""
		    }))	

		}else{
			setValue((state) => ({
		      ...state,
		      [e.target.name]: e.target.files[0]
		    }))			
		}

	}
	
	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	    
	    if (e.target.name == "name") {
	    	value.slug = urlFriendly(e.target.value)
	    }	    
	}

	const handleChangeFormCategory = (e) => {
		
		listCategory(e.target.value)

		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	const getApiData = async () => {
			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/products/show",{method:'POST',body: JSON.stringify({id:window.detailpath}), headers: myHeaders})
				const data = await response.json()
		       
		       if (data.status) {
		       		listCategory(data.data.code_categoria_principal)
					setValue({
						id : data.data.id,
						name: data.data.name,
						slug: data.data.slug,
						sku: data.data.sku,
						status: String(data.data.status),
						moneda: data.data.moneda,
						equivalencia_texto: data.data.equivalencia_texto,
						mensaje_oferta: data.data.mensaje_oferta,
						palabras_clave: data.data.palabras_clave,
						price: data.data.price,
						quantity_initial: data.data.quantity_initial,
						quantity_max: data.data.quantity_max,	
						description: data.data.description,
						gallery:(data.data.gallery !== null) ? data.data.gallery+"?"+Date.now() : ""
					} )					

		        }
		    }		

	};


	const submit = async(event) =>{
		event.preventDefault();

		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

			    let apiRequest = window.path_service+"/products/add";
				if ( parseInt(value.id) > 0  ) {
					apiRequest = window.path_service+"/products/update"
				}
				const response = await fetch(apiRequest,{method:'POST',body:  JSON.stringify(value) , headers: myHeaders})
				const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {

					if ( typeof value.gallery !== 'object') {
						window.Swal.fire({
				            icon: 'success',
				            title: data.message,
				            showConfirmButton: false,
				            timer: 3500
				        });         					
				        value.id = data.item_id;
						
						if ( type_page === "new"  ) {
							setTimeout(() => {
								window.location.href = "/products";
							}, 3500);
						}				        
					}
				        uploadFile(data.item_id);

				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 					
				}
				//reset();
			}
			if ( typeof value.gallery !== 'object') {
				modaLoading.close();			
			}
	}


	const uploadFile = async(idfile) =>{
//		uplloadGallery = null;
	    //var uplloadGallery = setInterval(function () {
	        if (value.gallery !== null && value.gallery !== '' && typeof value.gallery === 'object') {
	            var formdata = new FormData();
	            formdata.append("type", value.gallery);
	            formdata.append("id_file", idfile );
	            formdata.append("dir", 'products/');

		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

	            if (xhrgallery == null) {
	                xhrgallery = window.$.ajax({
	                    url: window.path_service+"/products/upload_file",
	                    type: "POST",
	                    async: true,
	                    processData: false,
	                    contentType: false,
	                    data: formdata,
	                    dataType: "json",
	                    headers : {"Authorization": "Bearer "+ localStorage.token_authlead  }, 
	                    success: function (response1) {
	                        if ( response1.hasOwnProperty("status") ) {
	                        	if (response1.status) {
	                            	value.gallery = response1.path;
	                        	}
								
								window.Swal.fire({
						            icon: response1.status?'success':'error',
						            title: response1.message,
						            showConfirmButton: false,
						            timer: 3500
						        });  	                            

								if ( type_page === "new"  ) {
									setTimeout(() => {
										window.location.href = "/products";
									}, 3500);
								}	

	                        }
	                        modaLoading.close();		
	                    }
	                });
	            }else{
	            	modaLoading.close();		
	            }
	        }
	        
	        
	}


	return (
		<div className="ms-form-payment-register">

		            <form className="fm-form-user" onSubmit={submit}>

					      <Input 
					        label ="Nombre de Producto*"
					        validated="yes"
					        name="name"
					        value = {value.name}
					        onChange = {handleChangeForm}
					        />

					      <Input 
					        label = "sku*"
					        validated="yes"
					        name="sku"
					        value = {value.sku}
					        onChange = {handleChangeForm}
					        />

					        <Select
						        label = "Moneda*"
						        validated="yes"
						        name="moneda"
						        value = {value.moneda}
						        onChange = {handleChangeForm}

					        >			        
						        <option value="">Seleccione un Tipo de Dirección</option>
						      	{
						      		window.listMoneda.map((entry,enkey) =>
						      			<option key={enkey} value={entry.id}>{entry.name}</option>
						      		)
						      	}
					        </Select>



					      <InputPrice 
					        label = "Precio*"
					        validated="yes"
					        name="price"
					        value = {value.price}
					        onChange = {handleChangeForm}
					        />


<fieldset className="groupForm">
			              <div className="ms-item fm-block-genero">
			                  <div className="ms-item ms-checbox">
			                    <input type="radio" className="js-profile-gender" name="status" id="statusActive" value="1" 
			                    checked={value.status === "1"} onChange = {handleChangeForm}
			                      />
			                    <label htmlFor="statusActive">Activo en lista Compra</label>
			                  </div>

			                  <div className="ms-item ms-checbox">
			                    <input type="radio" className="js-profile-gender" name="status" id="statusInactive" value="2" 
			                    checked={value.status === "2"} onChange = {handleChangeForm}
			                      />
			                    <label htmlFor="statusInactive">Desactivado (No Compran)</label>
			                  </div>
			              </div>
        </fieldset>


					      <Input 
					        label = "Cantidad Minima*"
					        type="number"
					        validated="yes"
					        name="quantity_initial"
					        value = {value.quantity_initial}
					        onChange = {handleChangeForm}
					        />


					      <Input 
					        label = "Cantidad Maxima*"
					        type="number"
					        validated="yes"
					        name="quantity_max"
					        value = {value.quantity_max}
					        onChange = {handleChangeForm}
					        />


 <fieldset className="groupForm">

					      <Input 
					        label = "Palabras Clave de Busqueda*"
					        validated="yes"
					        name="palabras_clave"
					        value = {value.palabras_clave}
					        onChange = {handleChangeForm}
					        />

						      <InputFile
						        label = "Img Principal"
						        validated="yes"
						        name="gallery"
						        valobj = {value.gallery}
						        changeFiles = {handleChangeFile}
						        />
					        


                <CKEditor
                    editor={ ClassicEditor }
                    data={value.description}
                    name="description"
                    
                    config={ {

                    } }

			        onChange={(event, editor) => {
			          const data = editor.getData();
			          //console.log({ event, editor, data });//
			          value.description=data
			        }}

                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log( 'Editor is ready to use!', editor );
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />


 </fieldset>
 
		                   {
						    (
						    	value.name !== "" && 
							    value.sku  !== "" && 
							    value.palabras_clave !== "" && 
								value.price >= 0 && 
								value.quantity_initial >= 0 && 
								value.quantity_max >= 0 
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Cambios</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Cambios</button>
		                   </div>		                   
		                   }

		            </form>

		</div>
	)
}

export default Product;