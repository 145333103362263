import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';

import AppPages from './AppPages';
import reportWebVitals from './reportWebVitals';

window.getCookie = function(name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

  Object.defineProperty(Array.prototype, 'agrupamiento', {
        value: function(chunkSize){
            var temporal = [];
            for (var i = 0; i < this.length; i+= chunkSize){
                temporal.push(this.slice(i,i+chunkSize));
            }
            return temporal;
        }
  });


window.path_service ='https://api.cambistape.com';
window.pagenow = window.document.location.pathname.replace("/","");
const listDetails = ["products/","category/","team/","customer/","provider/","orders/"];


  listDetails.forEach(function(pathde) {
    if (pathde === "orders/" ) {
      if (window.pagenow.includes(pathde) && window.pagenow.replace(pathde,"") ) {
        window.detailpath = window.pagenow.replace(pathde,"");
      }
    }else{      
      if (window.pagenow.includes(pathde) && !isNaN(window.pagenow.replace(pathde,"")) ) {
        window.detailpath = window.pagenow.replace(pathde,"");
      }
    }
  })  

//window.pagenow = "registro";
//window.pagenow ='freshclub-puntos-fresh';
//window.pagenow ='preguntas-activacion';
//window.pagenow = "terminos-condiciones";
//window.pagenow = "preguntas-frecuentes";
//window.pagenow = "quienes-somos";
//window.pagenow = "contacto";
//window.pagenow = "libro-reclamos";
//window.pagenow = "detail-blog";
//window.pagenow = "home";
//window.pagenow = "blog";
//window.pagenow = "register-mobile";
//window.pagenow = "login";
//window.pagenow = "registro";
//window.pagenow = "login-mobile";
//window.pagenow = "login-mobile";
//window.pagenow = "recuperar-contraseña-mobile";
//window.pagenow = "direccion_fiscal";
//window.pagenow = "direccion_shipping";
//window.pagenow = "mis-direcciones";
//window.pagenow = "user";
//window.pagenow = "category";
//window.pagenow = "transversal";
//window.pagenow = "carrito";
//window.pagenow = "despacho";
//window.pagenow = "payment";
//window.pagenow = "registro";
//window.pagenow = "mis-pedidos";
//window.pagenow = "mis-tarjetas";
//window.pagenow = "mis-direcciones";
//window.pagenow = "mi-perfil";
//window.pagenow = "detalle-producto";
//window.pagenow = "politica-de-privacidad";
//window.pagenow = "confirmacion";
//window.pagenow = "halloween";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppPages
    form = {window.pagenow}
     />
);

reportWebVitals();