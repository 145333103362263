import { useState,useEffect } from 'react'
import Input from '../Input'
import InputEmail from '../Input/InputEmail'
import InputPhone from '../Input/InputPhone'
import InputPassword from '../InputPassword'
import Contacto from '../Form/Contacto'
import CuentaBancaria from '../Form/CuentaBancaria'

import Select from '../Select'
import DireccionesShipping from '../DireccionesShipping'
import Tabform from '../Tabform'

const Provider = ({type_page}) =>{
	const [value, setValue ] = useState( 
		{
			id:null,
			name:"",
			id_tipo_documento:1,
			numero_documento:"",
			status:"1",
			address : [],
			persona_contacto:[],
			cuenta_bancaria : [],
			descripcion: ""
		} 
	)

	const range = (start, end, step = 1) => {
	  let output = [];
	  if (typeof end === 'undefined') {
	    end = start;
	    start = 0;
	  }
	  for (let i = start; i < end; i += step) {
	    output.push(i);
	  }
	  return output;
	};
	const year_now = new Date().getFullYear();
	const year_max = year_now-17;
	const  year_min = year_now-79;
	const years = range(year_min,year_max).reverse();
	const months = [
		{id:"01",name:"Enero"},
		{id:"02",name:"Febrero"},
		{id:"03",name:"Marzo"},
		{id:"04",name:"Abril"},
		{id:"05",name:"Mayo"},
		{id:"06",name:"Junio"},
		{id:"07",name:"Julio"},
		{id:"08",name:"Agosto"},
		{id:"09",name:"Setiembre"},
		{id:"10",name:"Octubre"},
		{id:"11",name:"Noviembre"},
		{id:"12",name:"Diciembre"}
	];

	
  const list_documentos = 
  [
    {id:1,name:"Ruc"},
    {id:2,name:"Pasaporte"},
    {id:3,name:"Dni"}
  ]


	const getDaysInMonth =() => {
		let month = parseInt(value.month);
		const year =  parseInt(value.year);

		if ( isNaN(month) || isNaN(year)  ) {
			return [];
		}

	    month = month -1;
	  var date = new Date(year, month, 1);
	  var days = [];
	  while (date.getMonth() === month) {
	    days.push(new Date(date).getDate());
	    date.setDate(date.getDate() + 1);
	  }
	  return days;
	}


	const handleAddress = (list) => {
		setValue((state) => (
		{
	      ...state,
	      address: list
	    }
	    ))	 		
	}

	const handleContact = (list) => {
		setValue((state) => (
		{
	      ...state,
	      persona_contacto: list
	    }
	    ))	 		
	}	

	const handleCuentaBancaria = (list) => {
		setValue((state) => (
		{
	      ...state,
	      cuenta_bancaria: list
	    }
	    ))	 		
	}		
	

	const handleChangePhone = (e) => {
		value.phone = e;
	}

	
	const tabChange = (tab) => {
		if ( !window.$("."+tab).hasClass("active") ) {
			window.$(".wrap-tab-master li").removeClass("active");
			window.$(this).addClass("active");
		}
	}

	
	const handleChangeForm = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
	}

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}else{

				if ( !isNaN(window.detailpath) ) {
					getApiData();
				}

			}
	}, []);


	const getApiData = async () => {
			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/provider/show",{method:'POST',body: JSON.stringify({id:window.detailpath}), headers: myHeaders})
				const data = await response.json()
		       

		       if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") ) {

				  	let vyear="", vmonth="", vday="",vbirthday = null;
				  	
				  	if ( !["",undefined,null].includes(data.data.birthday)  ) {
				  		const date = new Date(data.data.birthday);
				  		vyear = date.getFullYear();
				  		vday = date.getDate();
				  		vmonth = (date.getMonth()+1).toString().padStart(2,0);
				  	}
				  	
				  	vbirthday = value.year+"-"+value.month+"-"+value.day;

					setValue((state) => (
							{
						      ...state,
								id:data.data.id,
								name: data.data.name,
								id_tipo_documento: data.data.id_tipo_documento,
								numero_documento: data.data.numero_documento,
								status:String(data.data.status),
								persona_contacto:data.data.persona_contacto,
								address: data.data.direccion,
								cuenta_bancaria : data.data.cuenta_bancaria,
								descripcion: ""														      
						    }
					))
		        }
		    }		

	};


	const submit = async(event) =>{
		event.preventDefault();

		value.clave=value.dni;

		let modaLoading;
		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

			    let apiRequest = window.path_service+"/provider/add"
			    if ( type_page === "edit"  ) {			    	
			    	apiRequest = window.path_service+"/provider/update";
			    }

				const response = await fetch( apiRequest ,{method:'POST',body:  JSON.stringify(value) , headers: myHeaders})
				const data = await response.json()


				if ( data.hasOwnProperty("status") && data.status ) {
					window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			        });         					
					
						if ( type_page === "new"  ) {
							setTimeout(() => {
								window.location.href = "/provider";
							}, 3500);
						}	

				}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 					
				}
				//reset();
			}
			modaLoading.close();			
	}

	console.log(value)

	return (
		<div className="ms-form-payment-register">
			<Tabform tabs={[
						{id:1,title:"información",tabname:"tab-one",active: true},
						{id:2,title:"Dirección",tabname:"tab-two",active: false},
						{id:3,title:"Contactos",tabname:"tab-three",active: false},
						{id:4,title:"Cuentas Bancaria",tabname:"tab-four",active: false}, 
						//{id:3,title:"Telefono",tabname:"tab-three",active: false}
					]} />
			
		            	<div className="tab-two tab-content">
		            		<DireccionesShipping callRequest={"no"} litAddress={value.address} handleAddress={handleAddress} />
		            	</div>


		            	<div className="tab-three tab-content">
		            		<Contacto listContacts={value.persona_contacto} handleContact={handleContact} />
		            	</div>

		            	<div className="tab-four tab-content">
		            		<CuentaBancaria litCuenta={value.cuenta_bancaria} handleCuentaBancaria={handleCuentaBancaria} />
		            	</div>

		            	
		            	

		            
		            <form className="fm-form-user" onSubmit={submit}>

		            	<div className="fm-form-user tab-one tab-content active">

						      <Input 
						        label ="Nombre  o Razon social*"
						        validated="yes"
						        name="name"
						        value = {value.name}
						        onChange = {handleChangeForm}
						        />


							        <Select
								        label = "Tipo documento"
								        validated="yes"
								        name="id_tipo_documento"
								        value = {value.id_tipo_documento}
								        onChange = {handleChangeForm}
							        >
					                  {
					                  	list_documentos.map(
					                  	docu =>
					                  		<option key={docu.id} value={docu.id}>{docu.name}</option>
					                  	)
					                  }
							        </Select>

						      <Input 
						        label = "Número documento*"
						        validated="yes"
						        name="numero_documento"
						        value = {value.numero_documento}
						        onChange = {handleChangeForm}
						        />

				             <fieldset className="groupForm">
					              <div className="ms-item fm-block-genero">
					                  <div className="ms-item ms-checbox">
					                    <input type="radio" className="js-profile-gender" name="status" id="statusActive" value="1" 
					                    checked={value.status === "1"} onChange = {handleChangeForm}
					                      />
					                    <label htmlFor="statusActive">Activo</label>
					                  </div>

					                  <div className="ms-item ms-checbox">
					                    <input type="radio" className="js-profile-gender" name="status" id="statusInactive" value="2" 
					                    checked={value.status === "2"} onChange = {handleChangeForm}
					                      />
					                    <label htmlFor="statusInactive">Desactivado</label>
					                  </div>
					              </div>
				              </fieldset>

		            	</div>


		                   {
						    (
						    	value.name !== "" && 
							    value.id_tipo_documento !== "" && 
							    value.numero_documento !== "" 
							)
		                   	?
		                   <div className="fm-item-form">
		                     <button className="fm-btn-opera">Guardar Cambios</button>
		                   </div>
		                   :
		                   <div className="fm-item-form disabled">
		                     <button disabled className="fm-btn-opera">Guardar Cambios</button>
		                   </div>		                   
		                   }

		            </form>

		</div>
	)
}

export default Provider;