const RawPoliticasdecookies = () => {
return(
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <h2 className="title-block color-green fw-bolder mb-5">Políticas de Cookies</h2>
                        <p><u><b>Políticas de cookies de FRESHMART S.A.C. (“FRESHMART”)</b></u></p>
                        <p>
                            <b>FRESHMART</b> presenta las políticas de cookies que son utilizadas en su plataforma
                            web&nbsp; <a href="https://www.freshmart.pe">“https://www.freshmart.pe/”</a> (la “<u>Página
                            Web</u>”) con la finalidad de que todo aquel que ingrese a la Página Web (el “<u>Usuario</u>”),
                            se encuentre informado y se garantice su privacidad.
                        </p>
                        <p>Las cookies utilizadas en la Página Web son de terceros.</p>
                        <p>
                            La Página Web contiene cookies necesarias que permite el uso y garantiza que los Usuarios
                            puedan acceder a funcionalidades. Además, cuenta con cookies de preferencia que permite
                            saber determinados hábitos de los Usuarios al momento de utilizar la Página Web. Cookies de
                            marketing también son utilizados con la finalidad de publicitar productos ofrecidos a través
                            de la Página Web. Asimismo, la Página Web también incluye cookies relacionadas a temas
                            estadísticos que medirán el comportamiento de los Usuarios en la Página Web.
                        </p>
                        <p>
                            La Página Web tiene enlaces a las redes sociales Instagram y Facebook. Al respecto,
                            FRESHMART no tiene control sobre las cookies que son utilizadas en las plataformas web antes
                            señaladas. Por ello, el Usuario deberá informarse ingresando a las respectivas políticas de
                            cookies.
                        </p>
                        <p><u><b>¿Qué son las cookies y para qué sirven?</b></u></p>
                        <p>
                            Las cookies son herramientas necesarias en la prestación de servicios digitales, son piezas
                            de información que se almacena en el dispositivo móvil, ordenador u otro aparato electrónico
                            que tenga acceso a Internet. Reconocen los aparatos electrónicos de los Usuarios al ingresar
                            a la Página Web, recuerdan la configuración de navegación así como las preferencias de los
                            usuarios. Mejora la experiencia del usuario en la Página Web y permite recopilar datos
                            personales e información en general para fines publicitarios, de marketing, estadísticos,
                            entre otros, conforme se describe en la presente Política de Cookies. Además, algunas
                            cookies utilizadas en la Página Web son necesarias para su correcto funcionamiento.
                        </p>
                        <p><u><b>Acciones de los Usuarios</b></u></p>
                        <p>Los Usuarios de la Página Web podrán efectuar cualquiera de las siguientes acciones, según lo
                            consideren pertinente:</p>
                        <ol type="1">
                            <li>
                                Aceptar: el Usuario da consentimiento al respectivo cookie y no aparece durante la
                                permanencia en la Página Web.
                            </li>
                            <li>
                                Cerrar: deja de aparecer el aviso, pero si el Usuario vuelve a ingresar, le va a
                                aparecer nuevamente.
                            </li>
                            <li>
                                Cambiar la configuración: el Usuario puede cambiar la configuración de navegación en su
                                aparato electrónico, sin embargo, el aviso aparecerá nuevamente al ingresar a la Página
                                Web.
                            </li>
                        </ol>
                        <p><u><b>Clasificación de las cookies</b></u></p>
                        <ol type="1">
                            <li>
                                <p>Según la entidad que las gestiona:</p>
                                <ol type="a">
                                    <li>
                                        <u>Propias</u>: son las que se mandan al aparato electrónico del Usuario desde
                                        el sitio web administrado por el titular del propio sitio y desde el que se
                                        brindan los servicios al Usuario.
                                    </li>
                                    <li>
                                        <u>De terceros</u>: Son las que se mandan aparato electrónico del Usuario desde
                                        el sitio web pero que no es administrado por el titular del sitio, sino por otra
                                        empresa que realiza el tratamiento de los datos obtenidos a través de las
                                        cookies.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>Según el tiempo que permanecen activadas en el aparato electrónico del Usuario:</p>
                                <ol type="a">
                                    <li>
                                        <u>Sesión</u>: diseñadas para obtener datos, durante el tiempo en que el Usuario
                                        accede a la Página Web; este tipo de cookies no quedan almacenadas en el
                                        ordenador del usuario cuando la sesión caduca o cuando el usuario cierra el
                                        navegador.
                                    </li>
                                    <li>
                                        <u>Persistentes</u>: los datos se almacenan en el aparato electrónico
                                        del Usuario y en donde se puede acceder y tratar aun cuando el Usuario abandona
                                        la Página Web y se vuelve a conectar a esta. Puedes ser eliminados en cualquier
                                        momento por el Usuario.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>Según la finalidad:</p>
                                <ol type="a">
                                    <li>
                                        <u>De Registro</u>: son generadas una vez que el Usuario se ha registrado o
                                        posteriormente, se conecta a su cuenta en la Página Web. Tiene por finalidad
                                        identificar al Usuario para que, en caso una orden se encuentre pendiente de
                                        confirmación y cierra la Página Web, al ingresar nuevamente su pedido se
                                        encuentre guardado. La funcionalidad se suprime cuando el Usuario cierra sesión,
                                        eliminando la cookie.
                                    </li>
                                    <li>
                                        <u>Publicitaria</u>: este tipo de cookies no almacenan información personal; se
                                        basan en la identificación única de su navegador y/o dispositivo de acceso al
                                        Internet. Tiene como intención mostrar anuncios publicitarios personales o de
                                        terceros.
                                    </li>
                                    <li>
                                        <u>Publicidad comportamental</u>: permiten gestionar, los espacios publicitarios
                                        que, en su caso, el titular haya incluido en la página web desde el que brinda
                                        servicios. Almacenan información del comportamiento de los Usuario obtenida por
                                        la observación continuada de sus hábitos de navegación, lo que permite
                                        desarrollar un perfil específico para mostrar publicidad en función de éste.
                                    </li>
                                    <li>
                                        <u>Personalización</u>: posibilitan que el Usuario acceda a los servicios de las
                                        páginas web con determinadas características generales y predefinidas en base a
                                        criterios del aparato electrónico utilizado. Ejemplo: tipo de navegador
                                        utilizado, idioma, configuración regional desde donde accede al servicio, etc.
                                    </li>
                                    <li>
                                        <u>Técnicas</u>: imprescindibles y necesarias para que funcione en óptimas
                                        condiciones la página web y sus servicios. Son de este tipo, entre otras, las
                                        que sirven para el mantenimiento de la sesión, la gestión del tiempo de
                                        respuesta, rendimiento o validación de opciones, utilizar elementos de seguridad
                                        o compartir contenido con redes sociales.
                                    </li>
                                    <li>
                                        <u>De análisis</u>: permiten al responsable de las mismas, el seguimiento y
                                        análisis del comportamiento de los Usuario en las páginas web. Esta información
                                        tiene por finalidad medir la actividad de las páginas web y crear perfiles de
                                        navegación de los Usuario, con el fin de introducir mejoras en función del
                                        análisis de los datos de uso que hacen los Usuario del servicio.
                                    </li>
                                    <li>
                                        <u>Geo-localización</u>: verifican desde que país se requiere el servicio.
                                        Brinda datos de manera anónima, y sólo se utiliza para ayudar a orientar el
                                        contenido a su ubicación.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <p><u><b>Cookies utilizadas en la Página Web</b></u></p>
                        <p>
                            <b>FRESHMART</b> utiliza en la Página Web cookies que son de terceros. Todas las cookies
                            utilizadas en la Página Web son de permanencia temporal, de sesión.
                        </p>
                        <p>Las cookies utilizadas se detallan a continuación:</p>
                        <table className="table table-bordered">
                            <thead className="table-dark">
                            <tr>
                                <td style={{width: "25%"}}>Titular de la cookie</td>
                                <td style={{width: "25%"}}>Tipo de Cookie</td>
                                <td style={{width: "25%"}}>Finalidad</td>
                                <td style={{width: "25%"}}>Permanencia</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Sumo Group Inc.</td>
                                <td>Publicidad y de análisis.</td>
                                <td>
                                    A través de estos cookies se colocan pop ups y banners para comunicar novedades de
                                    los servicios. A través de estos cookies también se podrá recopilar los correos
                                    electrónicos de los Usuarios de la Página Web.
                                </td>
                                <td>De Sesión.</td>
                            </tr>
                            <tr>
                                <td>Invitereferrals</td>
                                <td>De registro.</td>
                                <td>
                                    Recopilan los datos personales para la creación de los Usuarios de la Página Web.
                                </td>
                                <td>De Sesión.</td>
                            </tr>
                            <tr>
                                <td>Hotjar Ltd</td>
                                <td>Publicidad comportamental y de personalización.</td>
                                <td>
                                    Estas cookies pueden recopilar datos personales u otra clase de información referida
                                    al sistema operativo, navegador, entre otros.
                                </td>
                                <td>De Sesión.</td>
                            </tr>
                            <tr>
                                <td>Onesignal</td>
                                <td>De personalización.</td>
                                <td>Solo recopila información relacionada al protocolo de Internet (IP).</td>
                                <td>De Sesión.</td>
                            </tr>
                            <tr>
                                <td>Google LLC</td>
                                <td>De análisis y de personalización.</td>
                                <td>Verifica el comportamiento de los Usuarios en la Página Web.</td>
                                <td>De Sesión.</td>
                            </tr>
                            <tr>
                                <td>Facebook, Inc.</td>
                                <td>De análisis y de personalización.</td>
                                <td>Verifica el comportamiento de los Usuarios en la Página Web.</td>
                                <td>De Sesión.</td>
                            </tr>
                            </tbody>
                        </table>
                        <p><u><b>Gestión de las cookies</b></u></p>
                        <p>
                            <b>FRESHMART </b>a través de la presente Política de Cookies busca brindar transparencia
                            e información a los Usuarios respecto de las cookies que son utilizadas en la Página Web,
                            asumiendo el compromiso de mantenerla actualizada. Así, a través de la Política de Cookies
                            explica qué tipo de cookies son utilizadas y con qué finalidad.
                        </p>
                        <p>
                            Al momento en que el Usuario aprueba las cookies utilizadas por la Página Web, permite que
                            tenga acceso a un servicio eficiente y personalizado, en óptimas condiciones.
                        </p>
                        <p>
                            Los Usuarios podrán configurar su navegador con la finalidad de fijar que sólo los sitios
                            web de confianza o las páginas por las que está navegando en determinado momento administren
                            las cookies, accediendo la elección de sus preferencias.
                        </p>
                        <p>
                            Los Usuario pueden no autorizar el tratamiento de sus datos personales, rechazando la
                            utilización de las cookies de la Página Web. Bajo dicho escenario, sólo se estarían
                            utilizando cookies técnicas, necesarias para la navegación por la Página Web. En este caso,
                            no almacenaríamos ninguna cookie. En el caso de seguir navegando por la Página Web sin
                            rechazar su autorización implica que brinda su consentimiento.
                        </p>
                        <p>
                            En caso borre o deniegue las cookies utilizadas por la Página Web, FRESHMART no tendrá la
                            posibilidad de guardar sus preferencias y algunas secciones de la Página Web no se
                            encontrarán habilitadas, ni brindar servicios personalizados. Cada vez que ingrese a la
                            Página Web se requerirá que brinde su consentimiento al uso de las cookies utilizadas en la
                            Página Web.
                        </p>
                        <p><u><b>Desactivar cookies de la Página Web</b></u></p>
                        <p>
                            En cualquier oportunidad, cuando el Usuario lo considere pertinente, podrá retirar el
                            consentimiento a la Política de Cookies de FRESHMART. Para dichos efectos, deberá eliminar
                            las cookies almacenadas en el aparato electrónico del Usuario, según se detalla a
                            continuación:
                            <ol type="1" className="mt-3">
                                <li className="mt-2">
                                    <u>Google Chrome</u>: Configuración &#8594; Mostrar opciones avanzadas &#8594;
                                    Privacidad &#8594; Configuración de contenido
                                </li>
                                <li className="mt-2">
                                    <u>Internet Explorer</u>: Herramientas &#8594; Opciones de Internet &#8594;
                                    Privacidad &#8594; Configuración
                                </li>
                                <li className="mt-2">
                                    <u>Safari</u>: Preferencias &#8594; Seguridad
                                </li>
                                <li className="mt-2">
                                    <u>Firefox</u>:
                                    Herramientas &#8594; Opciones &#8594; Privacidad &#8594; Historial &#8594; Configuración
                                    Personalizada
                                </li>
                            </ol>
                        </p>
                        <p><u><b>Cambios a la Política de Cookies</b></u></p>
                        <p>
                            <b>FRESHMART</b> podrá actualizar y/o ajustar esta Política de Cookies según sea requerido
                            bajo la legislación peruana. Para dichos efectos, <b>FRESHMART</b> publicará cualquier
                            modificación o actualización de la Política de Cookies en la Página Web. Recomendamos a los
                            Usuarios que revisen regularmente la Página Web.
                        </p>
                        <p><u><b>Otras consideraciones</b></u></p>
                        <p>
                            Si el Usuario cuenta con alguna duda sobre la Política de Cookies: podrá enviar un correo
                            electrónico a la siguiente dirección:&nbsp;
                            <a href="mailto:general@freshmart.pe">general@freshmart.pe</a>
                        </p>
                    </article>
                </div>
    )
}
export default RawPoliticasdecookies;