import { useState,useEffect } from 'react'
import Producto from './Producto'
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      className="next-slide slick-arrow"
      onClick={onClick}
    ><i className="fa fa-angle-right"></i></button>
  );
}

function SamplePrevArrow(props) {
  const {  onClick } = props;
  return (
    <button
      className="prev-slide slick-arrow"
      onClick={onClick}
    ><i className="fa fa-angle-left"></i></button>
  );
}

  var settings = {
                slidesToShow: 4,
                slidesToScroll: 2,
                speed: 900,
                autoplay: false,
                autoplaySpeed: 3500,
                dots: true,
                infinite: false,
                //PrevArrow:'<button type="button" className="prev-slide slick-arrow"><i className="fa fa-angle-left"></i> </button>',
                //NextArrow:'<button type="button" className="next-slide slick-arrow"><i className="fa fa-angle-right"></i></button>', 

			    nextArrow: <SampleNextArrow />,
			    prevArrow: <SamplePrevArrow />,

                responsive: [
                  {
                    breakpoint:930,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    }
                  },
                  {
                    breakpoint:768,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      dots: false,
                      arrows: true,
                    }
                  } ,
                  {
                    breakpoint:600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      dots: false,
                      arrows: true,
                    }
                  }            
                ]
              };



const SliderTopProducts = ({filter, limit, title,list_cart,methodShopping}) => {
	const [products, setProducts ] = useState([])
	const path_filter = "/c/"+filter;

  let is_express = 0;
  if (methodShopping === "express") {
    is_express = 1;
  }

	const getApiData = async () => {
    window.$("#"+filter).hide();
		if ( window.getCookie("commerce_lead_token") !== "" && window.getCookie("commerce_lead_token") !== undefined ) {
			const dataq = new FormData();
      var myHeaders = new Headers();
			dataq.append("id_comercio", localStorage.comercioId );
			dataq.append("is_express", is_express );
      myHeaders.append("Authorization", "Bearer "+ window.getCookie("commerce_lead_token") );
		  const response = await fetch(window.path_service+"/api/get/my_top_products_concurrents",{method:'POST',body: dataq, headers: myHeaders})
		  const data = await response.json()
		  setProducts(data.data)
      window.$("#"+filter).show();
		}
	};

	useEffect(() => {
		getApiData();
	}, [methodShopping]);

	return(
    <div className="js-slider-eko" id={filter}>
	  {
	  	products.length > 0 ? 
		   <div className="container eko-container-default">
		      <div className="text-left eko-slider-block-title">
		         <h3 className="title_heading mb-0 js-title-blobk">{title}&nbsp;</h3>
		      </div>
		      <Slider className="js-list-section-slider slick-dotted list-section-response-product" {...settings}>
			      	{ products.map(
				      	(x, index)  => 
				      	<Producto key={x.id} 
                methodShopping = {methodShopping}
	              prod = {x} 
	              list_cart = {list_cart}
	              />
				    ) }
		      </Slider>
		     </div>
	  	: null
	  }
	  </div>

	)

}
export default SliderTopProducts;