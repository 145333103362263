import { useState,useEffect } from 'react'
import useInfinityAutocomplete from '../useStatePerso/useInfinityAutocomplete'

const Autocomplete = ({getinforuser,label,validated,classWrap="ms-item",clasInput="fm-form-input",...rest}) =>{

	const [visible, setVisible ] = useState(false)
	const [search, setSearch ] = useState("")
	const [userselect, setUserselect ] = useState({})
	const [itemxhr, more, textSearch, clearList ] = useInfinityAutocomplete({search})

	const activeMenu = (e) =>{
   		setVisible(visible=>!visible)
   	}

	const getSelectUser = (item) => {
		clearListAutocomplete()
		let address = [];
		address.push(!["",undefined,"undefined","null",null].includes( item.calle ) ? item.calle : null);
		address.push(!["",undefined,"undefined","null",null].includes( item.num_lote ) ? item.num_lote : null);
		address.push(!["",undefined,"undefined","null",null].includes( item.address_type ) ? item.address_type : null);
		address.push(!["",undefined,"undefined","null",null].includes( item.optional_address ) ? item.optional_address: null);
		address.push(!["",undefined,"undefined","null",null].includes( item.distrito_name ) ? (","+item.distrito_name) : null);
		address.push(!["",undefined,"undefined","null",null].includes( item.urbanizacion ) ? (", "+item.urbanizacion) : null);			        							        	


		item.address = address.join(" ")
		setUserselect(item)
		getinforuser(item)
	}	

	const handleChange = (e) => {
		setSearch(e.target.value)
		if (e.target.value.length > 0 ) {
			setVisible(true)
			//more()
			if (search.length >=3) {
				textSearch(e.target.value)
			}
		}else{
			setVisible(false)
			//clearList()
		}
	}

	const clearListAutocomplete = () => {
		clearList()
		setVisible(false)
	}


	const EnterPress = (event) =>{
		if (event.charCode == 13 && search.length >=3 ) {
          //apiList()
          //more({search})
          textSearch(search)
        }
	}


	return (
		<div className={classWrap}>
		{
			label !== "" ?
			<label htmlFor={rest.name}>{label}</label>
			:null
		}
		
		<input 
		onKeyPress={EnterPress}
		onChange = {handleChange}
		className={clasInput}
		autocorrect="off" 
		autocomplete="off"
		id={rest.name} />

            {
            	search !== "" && itemxhr.length > 0 ? 

					<ul className="list-autocomplete">

						                  {
						                    itemxhr.map(
						                      item => 
						                      <li key={item.id} onClick={(e)=>getSelectUser(item) } >{item.name} {item.lastname}</li>						                      
						                    )
						                  }  
					</ul>	

            	:
            	null
            }
            {
            	search !== "" && itemxhr.length > 0 ? 
            	<i onClick={(e)=>clearList() } className="fc_close_auto fa fa-times" aria-hidden="true"></i>
            	:
            	<i onClick={(e)=>more() } className="fc_search_auto fa fa-search" aria-hidden="true"></i>
            }
			
		</div>
	)
}
export default Autocomplete;