import { useState } from 'react'

const Fiscal = ({direccion,handledeleteItem,handleChangeDefault}) =>{
      let ischecked = false

      if (direccion.is_default == 1) {
            ischecked = true;
      }
      
	return(
            		<li className="fm-block-item">
            			<input type="radio" className="js-address-item" name="addressitem" id={direccion.id}   checked={ischecked} readOnly value="on" />
            			<label className="fm-item-lb" htmlFor={direccion.id} 
            			onClick={() => handleChangeDefault(direccion) }
            			 >
            				<span className="fm-item-title">{direccion.razon_social}</span>
            				<span>{direccion.ruc}</span>
            				<span>{direccion.domicilio_fiscal}</span>
            				{ direccion.is_default == "1" ? 
            				<span className="ms-radio-style">Dirección Predeterminada</span>
            				:
            				<span className="ms-radio-style">Dirección Activa</span>
            				}
            			</label>
            			<a className="ms-delete-addres js-delete-address" onClick={() => handledeleteItem(direccion) } >Eliminar</a>
            		</li>		
	)
}
export default Fiscal;