import { useState, useEffect ,useRef } from 'react'
import Input from '../Input'
import Select from '../Select'


const FilterPedidos = ({showlist = 0,handleTeam }) =>{
  const [listCat, setListCat ] = useState([])
  const [listSubcat, setListSubcat ] = useState([])
  const [pagination, setPagination ] = useState({
    tot : 0,
    next : false,
    prev:false,
    text:""
  })

  const listsort = 
  [
    {label:"Pedido Pendiente",value:"id-asc"},
    {label:"Pedido Entregados",value:"id-desc"},
    {label:"Alfabético A-Z",value:"name-asc"},
    {label:"Alfabético Z-A",value:"name-desc"}
  ]

	const [value, setValue ] = useState(
	{
    	page :0,
		keyword:"",
		pricerange_min:0,
		pricerange_max:0,
	    role:"",
    	sort:"id-asc",
		stockrange:null
	}
		)


  useEffect(() => {
    submit();   
  }, [showlist]);


  const handleChangeFormCategory = (e) => {
    value.role = e.target.value
    setValue((state) => ({
        ...state,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      }))

    submit()
  }

  const fc_pagination = async(action="next") =>{
    if (action === "next") {
      value.page = parseInt(value.page) + 1;
    }

    if (action === "prev") {
      value.page = parseInt(value.page) - 1;
    }
    submit()
  }
  


	const handleChangeSort = (e) => {
		setValue((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
    value.sort = e.target.value
    submit()
	}

  const handleChangeForm = (e) => {
    setValue((state) => ({
        ...state,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      }))
    console.log(value)
  }

	const activeFilter = (e,filter) =>{
    /*
			window.$(".item-filter").removeClass("active");

		if (!window.$("."+filter).hasClass("active") ) {
			window.$("."+filter).addClass("active");
		}else{
			window.$(".item-filter").removeClass("active");
		}
    */
	}

  const submit = async() =>{
  //  showlist = false

    //event.preventDefault();
    window.$(".item-filter").removeClass("active");

    var modaLoading = 
      window.Swal.fire({
        imageUrl: window.path_service+'/images/loading.png',
        title: 'Cargando datos...',
        showConfirmButton: false,
        customClass: 'ms-loading-modal',
          allowOutsideClick: false
     });
            


        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );
            myHeaders.append("Content-Type", "application/json");        
      
      const response = await fetch(window.path_service+"/orders/list",{method:'POST',body: JSON.stringify(value), headers: myHeaders })
      const data = await response.json()


      if ( data.hasOwnProperty("status") && data.status ) {
        handleTeam(data.data)
        setPagination(
{
    tot : data.total,
    next : data.pagination.header.next,
    prev : data.pagination.header.previous,
    text:data.text_pag
  })
      }else{
            window.Swal.fire({
                  icon: 'error',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2500
            }); 
        
      }
      modaLoading.close();
    }
    
  }

	return(
        <div className="filter-container">
           <div className="ib-fheader">
              <div className="ib-fhpa">
                 <div className="ib-fhpa-minisearch">
                    <input id="ib-fmsearch-a" className="ib-fmsearch" autoCorrect="off" autoComplete="disabled" 
                    type="text" placeholder="Ingresa Nombre, dni, telefono, Correo" 
                    name="keyword"
                    autoComplete="off"
                    value={value.keyword}  
                    onChange = {handleChangeForm} />
                    <div className="ib-fmsubmit ib-icon-search ib-kw-tg-search" onClick={(e)=>submit() }>
                      <span className="fa fa-search"></span>
                    </div>
                 </div>

              </div>
              <div className="ib-fhpb">
                 <ul className="ib-fhpb-openers">

                    <li className="item-filter datefil">
                    </li>


                    <li className="item-filter sended">
                       <div className="wrapper-filter"><a href="/orders/new" className="btn-filter">Nuevo</a></div>
                    </li>


                 </ul>
              </div>
           </div>

          <div className="wrap-fil-more">
              <div className="wrap-pag"> 
                <span> {pagination.text}</span>

                {
                  pagination.prev ?
                    <button onClick={(e)=>fc_pagination("prev") } className="st-button st-prev"></button>  
                  :
                    <button disabled className="st-button st-prev disabled"></button>  
                }
  
                {
                  pagination.next ?
                    <button onClick={(e)=>fc_pagination("next") } className="st-button st-next"></button> 
                  :
                    <button disabled className="st-button st-next disabled"></button> 
                }
                
                
              </div>

              <div className="wrap-sort">
                  <Select
                  name="sort"
                  onChange = {handleChangeSort}
                  value={value.sort}
                  className="sort-input">
                  {
                    listsort.map(
                      item => 
                          <option key={item.value} value={item.value}>{item.label}</option>
                      
                    )
                  }
                  </Select>
              </div>                        
          </div> 

        </div>
	)

}

export default FilterPedidos;