import Header from './Header'
import Footer from './Footer'

const PageFreshclubPuntosfresh = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>
            <Header
                user = {user}
                myLazyLoad = {myLazyLoad}
                loadMenu = {loadMenu}
                loadTransversal = {loadTransversal}
                menus = {menus}
                transversal = {transversal}
                changeHasExpress = {changeHasExpress}
                changeMethodShopping = {changeMethodShopping}
                methodShopping = {methodShopping}
                expressActive = {expressActive}
                subTotal = {subTotal}
                miproductos = {miproductos}
                list_cart = {myListCart}
                handleMiproductos = {handleMiproductos}
                handleSubTotal = {handleSubTotal}
                removeProduct = {removeProduct}
            />
            
            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm">
                                <a className="links-breadcrumb" href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                FRESHCLUB / PUNTOS FRESH – Programa de beneficios mensual
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                    <h1 
                    style={{fontSize: 24, textAlign: "center" , padding: "20px 0px"}} className="title-block color-green">FRESHCLUB / PUNTOS FRESH – Programa de beneficios mensual:</h1>
          <ol start={1} style={{marginBottom: '0cm', listStyleType: 'lower-alpha'}}>
            <li>¿Qué es Fresh Club?
<br/>
<p>Es un programa de beneficios exclusivos para clientes que realizan sus compras mensuales por la web o app de Freshmart.</p>
            </li>
            <li>¿Qué beneficios obtengo al ser parte de Fresh Club?
              <ul>
                <li>Despachos gratis en nuestros servicios de delivery Programado, Express o Mismo día.</li>
                <li>Descuentos para siguientes compras dependiendo el nivel de Puntos Fresh.</li>
                <li>Regalos exclusivos para clientes Extra y Súper Fresh.</li>
                <li>Descuentos en establecimientos de moda, belleza, educación, entretenimiento y más.</li>
              </ul><br/>
            </li>
            <li>¿Cómo ingreso a Fresh Club?
            <p><span>Solo tienes que realizar tus compras en nuestra web o app, acumular un monto mínimo de S/400 durante el mes y ya estarías ingresando al primer nivel de Freshclub.</span>
            <br/>*Se acredita 1 punto por cada sol consumido/comprado en el mes en la web o app.&nbsp;
            </p>

              <p><strong>Niveles Fresh:</strong><span style={{color: 'black'}}><br /></span></p>
              <p>El status se logra al cierre del mes anterior. Ejemplo: obtienes 400 puntos al 31 de enero y tus beneficios de ingresar al nivel Light se activan a partir del día 1 de febrero. </p>
              <p>Niveles: (Revisar el detalle en el punto F)</p>        
              <ol type={1}>
                <li>Light: 400 – 799 puntos.</li>
                <li>Fresh: 800 – 1299 puntos.</li>
                <li>Extra Fresh: 1300 – 1799 puntos.</li>
                <li>Súper Fresh: 1800 puntos a más.</li>
              </ol>
              <p><br/>*Los descuentos a través de compras con cupones no suman para obtener Puntos Fresh</p>
            </li>

            <li>¿Cómo me mantengo activo en Fresh Club?
            <p>Realizando tus compras semanales, quincenales o mensuales. El puntaje acumulado del mes actual se contabiliza para ingresar al inicio del siguiente mes.</p>
            </li>

            <li>¿Cómo reviso mis puntos y mi nivel actual?
            <p>Ingresando a la web o app en la sección “Mi cuenta” y luego dando clic en el botón “Mis Puntos Fresh” y/o en el botón “Freshclub”, luego “Usuario”, “Tipo de cliente” y la opción resaltada de color naranja es el nivel en el que te encuentras actualmente.</p>
            </li>

            <li>Niveles:
            <p>Desde el mes de enero de 2021 en adelante, por cada S/1 que compres en la web o app, acumulas 01 Punto Fresh. Obtén desde 400 Puntos Fresh durante el mes y recibirás tus beneficios el siguiente mes, según el nivel alcanzado.</p>

              <div>
                <ol style={{listStyleType: 'lower-roman'}}>
                  <li><strong><span>Nivel: Light</span></strong>
                  <p>Para llegar a este nivel, acumula entre 400-799 Puntos Fresh al mes.
                  <ul><li>Código de 01 solo uso con S/ 15 de descuento. El código será válido para 01 uso y el 100% será cargado en el pedido. </li></ul>
                  </p>
                  </li>
                  <li><strong><span>Nivel: Fresh</span></strong>
                  <p>Para llegar a este nivel, acumula entre 800-1299 Puntos Fresh al mes.
                  <ul>
                    <li><strong>Código de 01 solo uso con S/ 30 de descuento.</strong> El código será válido para 01 uso y el 100% será cargado el pedido.</li>
                    <li><strong>04 envíos gratis al mes.</strong> Es válido para envíos NextDay, SameDay & Express.</li>
                    <li><strong>Opción a regalos especiales.</strong> Este beneficio se entregará cada cierto tiempo a clientes dentro del nivel Fresh. Para poder acceder al regalo especial, el cliente deberá encontrarse dentro del nivel en el momento que el equipo de Freshmart decida realizar la entrega de los regalos especiales.</li>
                  </ul>
                  </p>
                  </li>

                  <li><strong><span>Nivel: Extra Fresh</span></strong>
                  <p>Para llegar a este nivel, acumula entre 1300-1799 Puntos Fresh al mes.

                  <ul>
                    <li><strong>Código de 02 usos con S/ 30 de descuento c/u (total S/60).</strong> El código será válido para 02 usos y el 100% de cada uno será cargado en cada pedido.<strong>No pueden utilizarse los dos códigos en un mismo día.</strong></li>
                    <li><strong>04 envíos gratis al mes.</strong> Es válido para envíos NextDay, SameDay & Express.</li>
                    <li><strong>Opción a regalos especiales.</strong> Este beneficio se entregará cada cierto tiempo a clientes dentro del nivel Extra Fresh. Para poder acceder al regalo especial, el cliente deberá encontrarse dentro del nivel en el momento que el equipo de Freshmart decida realizar la entrega de los regalos especiales.</li>
                  </ul>
                  </p></li>

                  <li><strong><span>Nivel: Súper Fresh</span></strong>
                  <p>Para llegar a este nivel, acumula entre 1800 o más Puntos Fresh al mes.

                    <ul>
                      <li><strong>Código de 02 usos con S/ 50 de descuento c/u (total S/100).</strong> El código será válido para 02 usos y el 100% de cada uno será cargado en cada pedido.<strong>No pueden utilizarse los dos códigos en un mismo día.</strong></li>
                      <li><strong>04 envíos gratis al mes.</strong> Es válido para envíos NextDay, SameDay & Express.</li>
                      <li><strong>Opción a regalos especiales.</strong> Este beneficio se entregará cada cierto tiempo a clientes dentro del nivel Super Fresh. Para poder acceder al regalo especial, el cliente deberá encontrarse dentro del nivel en el momento que el equipo de Freshmart decida realizar la entrega de los regalos especiales.</li>
                    </ul>

                  </p>
                  </li>

                  <p><strong>Términos & condiciones:</strong>

                    <ul style={{marginBottom: '0cm'}} type="disc">
                      <li >Cada S/1.00 de compras en Freshmart.pe es 01 Punto Fresh.</li>
                      <li >Los beneficios se envían <strong>exclusivamente a través de correo electrónico el&nbsp;primer y segundo día hábil del siguiente&nbsp;mes.</strong></li>
                      <li >Pedido mínimo de S/150 para utilizar los cupones de descuento en pedidos respecto a los beneficios de puntos fresh.</li>
                      <li >Los <strong>Puntos Fresh</strong> se contabilizan para pedidos <strong>creados y</strong> <strong>entregados desde el día 1 hasta el último día de cada mes.</strong>&nbsp;</li>
                      <li >Los beneficios de los niveles Light, Fresh, Extra Fresh &amp; Súper Fresh <strong>se otorgan el siguiente mes en el que se acumularon los Puntos Fresh</strong> para utilizarlos durante este periodo.</li>
                      <li >Los Puntos Fresh NO son acumulables de un mes a otro. El conteo de Puntos Fresh empieza desde cero nuevamente cada mes.</li>
                      <li >Si el cliente desea mantener su nivel Fresh, Extra Fresh y Súper Fresh es necesario que acumule nuevamente el puntaje durante el mes en el que utiliza sus beneficios.</li>
                      <li >Los descuentos <strong>a través de cupones no suman para obtener Puntos Fresh.</strong></li>
                      <li >Los Puntos Fresh se acumulan dentro del mes en la dirección de correo electrónico con el que se realiza el pedido <strong>y no son transferibles.&nbsp;</strong></li>
                      <li >Los beneficios son exclusivos para clientes Freshmart. No aplica para clientes B2B, ni plataformas amigas.</li>
                      <li >Cupones solo se harán efectivos si se introducen al momento de la compra. <strong>Bajo ninguna circunstancia se harán al momento de la entrega del pedido</strong>.</li>
                      <li >El cliente deja de ser parte del programa de beneficios si no cumple con el pedido mínimo para ingresar al primer nivel.</li>
                    </ul>

                  </p>

                </ol>
              </div>
            </li>

          </ol>


                    </article>
                </div>
            </main>
            <Footer/>
        </div>
    )
}

export default PageFreshclubPuntosfresh;
