import Header from './Header'
import Footer from './Footer'

const PageDetailblog = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

	const addCart = async(list) => {
		let modaLoading;
		modaLoading = 
		  window.Swal.fire({
		    imageUrl: window.path_service+'/images/loading.png',
		    title: 'Cargando datos...',
		    showConfirmButton: false,
		    customClass: 'ms-loading-modal',
		      allowOutsideClick: false
		  });

	    const dataq = new FormData();
	    dataq.append("skus", JSON.stringify(window.detail_blog.blog_carrito) );		
	    
	    const response = await fetch(window.path_service+"/api/pedido/add_product",{method:'POST',body:  dataq })	  
	    const data = await response.json()
	    modaLoading.close();
		if ( data.hasOwnProperty("success") && data.success ) {
			        window.Swal.fire({
			            icon: 'success',
			            title: data.message,
			            showConfirmButton: false,
			            timer: 2500
			          }); 		
		  	window.$("#modalListOrdenPedido").modal("hide")
			myListCart();
		}else{
				  	window.Swal.fire({
				          icon: 'error',
				          title: "Surgio un error inesperado, intentalo nuevamente",
				          showConfirmButton: false,
				          timer: 2500
				    }); 			
		}
	}
	

    return (
        <div>
            <Header
                user = {user}
                myLazyLoad = {myLazyLoad}
                loadMenu = {loadMenu}
                loadTransversal = {loadTransversal}
                menus = {menus}
                transversal = {transversal}
                changeHasExpress = {changeHasExpress}
                changeMethodShopping = {changeMethodShopping}
                methodShopping = {methodShopping}
                expressActive = {expressActive}
                subTotal = {subTotal}
                miproductos = {miproductos}
                list_cart = {myListCart}
                handleMiproductos = {handleMiproductos}
                handleSubTotal = {handleSubTotal}
                removeProduct = {removeProduct}
            />

            <main className="wrap-content page-interna mb-4 blog">
                    {
                    	"detail_blog" in window && "titulo" in window.detail_blog ?
                <div className="container-interna pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm">
                                <a className="links-breadcrumb" href="/">Inicio</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                <a className="links-breadcrumb" href="/blog">Blog</a>
                            </li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
                                {window.detail_blog.titulo}
                            </li>
                        </ol>
                    </nav>

					<div className="detail-blog">
						<div className="section-img">
							<img src={window.detail_blog.image} className="img-detail-blog" alt={window.detail_blog.titulo} />
							<div className="img-title-blog">
								<h2>{window.detail_blog.titulo}</h2>
							</div>
						</div>
						<div className="section-description">
							<p dangerouslySetInnerHTML={{ __html: window.detail_blog.contenido }} />
							{
								window.detail_blog.blog_carrito.length > 0 ?
								<button className="btn btnaddCartBlog" onClick={addCart}>
									<span>Agrega productos al carrito!</span>
								</button>
								:
								 null
							}					
						</div>
					</div>
                </div>
                    	:

			                <div className="container-interna pt-3">
			                    <nav aria-label="breadcrumb">
			                        <ol className="breadcrumb">
			                            <li className="breadcrumb-item breadcrumb-item-fm">
			                                <a className="links-breadcrumb" href="/">Inicio</a>
			                            </li>
			                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">
			                                <a className="links-breadcrumb" href="/blog">Blog</a>
			                            </li>
			                        </ol>
			                    </nav>

								<div className="detail-blog">
									<div className="section-description">
									<p>Blog no encontrado...</p>														
									</div>
								</div>
			                </div>
                    }


                
            </main>
            <Footer/>
        </div>
    )
}

export default PageDetailblog;
