import { useState } from 'react'
import TabformItem from './TabformItem'
const Tabform = ({tabs}) =>{
	const [listform, setListform ] = useState(tabs)

    const handleChange = (data,vid) =>{
    	window.$(".tab-content").removeClass("active");

		listform.forEach(function(item,index){
			let vactive = false;
			if (vid === index ) {
				vactive = !item.active
				window.$("."+item.tabname).addClass("active");
			}
			listform[index].active = vactive;
		});

		setListform([...listform ])
    }

	return (
		<ul className="wrap-tab-master" >
		{
			listform.map(
				(item, index) =>
				<TabformItem key={item.id} handleChange={handleChange} id={index} title={item.title} extra_class={item.tabname} isactive={item.active} />
			)
		}
		</ul>
	)
}

export default Tabform