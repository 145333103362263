import Header from './Header'
import Footer from './Footer'
import FormContacto from "./FormContacto"


const PageContacto  = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{

    return (
        <div>
            <Header
                user = {user}
                myLazyLoad = {myLazyLoad}
                loadMenu = {loadMenu}
                loadTransversal = {loadTransversal}
                menus = {menus}
                transversal = {transversal}
                changeHasExpress = {changeHasExpress}
                changeMethodShopping = {changeMethodShopping}
                methodShopping = {methodShopping}
                expressActive = {expressActive}
                subTotal = {subTotal}
                miproductos = {miproductos}
                list_cart = {myListCart}
                handleMiproductos = {handleMiproductos}
                handleSubTotal = {handleSubTotal}
                removeProduct = {removeProduct}
            />

            <main className="wrap-content page-interna mb-4">
                <div className="container pt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item breadcrumb-item-fm"><a className="links-breadcrumb" href="/">Inicio</a></li>
                            <li className="breadcrumb-item breadcrumb-item-fm active" aria-current="page">Contacto
                            </li>
                        </ol>
                    </nav>
                </div>


                <div className="ms-page-informative container mt-3 pb-2" style={{background: "#fff"}}>
                    <article className="flex-block-description justify">
                        <FormContacto />
                    </article>
                </div>
            </main>
            <Footer/>
        </div>
    )
}

export default PageContacto;