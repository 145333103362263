import { useState,useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import FilterCliente from '../Filtros/FilterCliente'

const PageClient = ({user,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	const [showlist,setShowlist] = useState(0)
	const [team, setTeam ] = useState([])

	const [landing,setLanding] = useState([])

    const handleTeam = (data) =>{
        setTeam(data)
    }

    const editItem = (data) =>{
        window.location.href = "/customer/"+data;
    }

    const confirmdeleteItem = (data) =>{
        window.Swal.fire({
          title: 'Estas Seguro de eliminar?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: "#666",
          confirmButtonColor: "#13a538",
          confirmButtonText: 'Si',
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            deleteItem(data)
          }
        })        
    }    
    
    const deleteItem = async(vdata) =>{
      let modaLoading;
      modaLoading = 
        window.Swal.fire({
          imageUrl: window.path_service+'/images/loading.png',
          title: 'Cargando datos...',
          showConfirmButton: false,
          customClass: 'ms-loading-modal',
            allowOutsideClick: false
        }); 
        

        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

          let apiRequest = window.path_service+"/customer/delete";
          const response = await fetch(apiRequest,{method:'POST',body:  JSON.stringify({id:vdata}) , headers: myHeaders})
          const data = await response.json()


          if ( data.hasOwnProperty("status") && data.status ) {
            window.Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 3500
                });                   
            setShowlist(showlist+1)
          }else{
              window.Swal.fire({
                    icon: 'error',
                    title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
                    showConfirmButton: false,
                    timer: 2500
              });           
          }
        }
        
        modaLoading.close();        
    } 

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}
	}, []);

    return (
	    <div className="page-content-detailt">

       <FilterCliente showlist ={showlist} handleTeam={handleTeam}/>

        <div className="list-products">
          <h5>Mis Clientes</h5>
          <table className="table" id="myFilters">
             <thead>
					      <tr>
					         <th className="ms-item-1">
					            <input type="checkbox" id="checkall" className="crm-lead-row" />
					            <label for="checkall">Seleccionar Todo</label>
					         </th>
					         <th className="ms-item-11">DNI</th>
					         <th className="ms-item-2">REGISTRADO</th>
					         <th className="ms-item-2">FECHA NACIMIENTO</th>
					         <th className="ms-item-5">TELEFONO</th>
					         <th className="ms-item-6">CORREO</th>
					         <th className="ms-item-7">DIRECCIÓN</th>
					         <th className="ms-item-7">PEDIDO PENDIENTE</th>
					         <th className="ms-item-7">PEDIDOS ENTREGADOS</th>
					         <th className="ms-item-7">PEDIDOS CANCELADOS</th>
					         <th className="ms-item-9">Editar</th>
					         <th className="ms-item-10">Eliminar</th>
					      </tr>

             </thead>
             <tbody className="js-list-team">
					{team.map(
						person => 
                <tr id={"item_"+person.id}>
	                <td className="filterMore ms-item-1 ico-my-buildings ms-icon no-border -chk-active">
	                {
	                	person.role ==="superadmin" ?
	                		<a className="ms-sc-title">{person.name+" "+person.lastname}</a>
	                	:
	                		<a onClick={() => editItem(person.id)} className="ms-sc-title">{person.name+" "+person.lastname}</a>
	                }
	                	
	                	<button className="ms-show-more-info">Show more</button>
	                	<div className="ms-chk">
	                	<input type="checkbox" id={"chk_"+person.id} name="item" className="crm-lead-row" /><label for={"chk_"+person.id}></label></div>
					</td>
					<td className="ms-item-11 ms-full"><span>DNI</span>{person.dni}</td>
 					<td className="ms-item-2 ms-md"><span>REGISTRADO</span>{person.created_on}</td>
					<td className="ms-item-5 ms-md"><span>FECHA NACIMIENTO</span>{person.birthday}</td>
					<td className="ms-item-5 ms-md"><span>TELEFONO</span>{person.phone}</td>
					<td className="ms-item-6 ms-md"><span>CORREO</span>{person.email}</td>
					<td className="ms-item-7 ms-md"><span>DIRECCIÓN</span>{
						![null,undefined,"undefined",""].includes(person.num_lote) && 
						![null,undefined,"undefined",""].includes(person.calle) && 
						![null,undefined,"undefined",""].includes(person.name_distrito) 
						?
							person.calle+" "+person.num_lote+", "+person.name_distrito
						:
							null
					}</td>
					<td className="ms-item-7 ms-md"><span>PEDIDO PENDIENTE</span>SI</td>
					<td className="ms-item-7 ms-md"><span>PEDIDOS ENTREGADOS</span>0</td>
					<td className="ms-item-7 ms-md"><span>PEDIDO CANCELADOS</span>0</td>
					<td className="ms-item-9"><span>Edit</span>
					
					{
						person.role ==="superadmin" ?
						null
						:
						<a onClick={() => editItem(person.id)} title="Edit" className="ms-edit-mx"><span>Edit</span></a>
					}					
					</td>
					<td className="ms-item-10 no-border"><span>Delete</span>
					{
						person.role ==="superadmin" ?
							null
						:
							<a href="#" onClick={() => confirmdeleteItem(person.id)} className="ms-delete-mx flex-filter-confirm-delete" data-toggle="modal" data-target="#modal-delete"><span>Delete</span></a>
					}
					</td>
				</tr>

					)}
             </tbody>
          </table>
        </div>
        

	    </div>
    )
}

export default PageClient;