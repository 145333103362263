import { useState,useEffect } from 'react'
import FilterProduct from '../Filtros/FilterProduct'


const PageMisProductos = ({user,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	const [product,setProduct] = useState([])
  const [showlist,setShowlist] = useState(0)

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}
	}, []);

  
    const handleProducts = (data) =>{
        setProduct(data)
    }

    const editItem = (data) =>{
        window.location.href = "/products/"+data;
    }

    const confirmdeleteItem = (data) =>{
        window.Swal.fire({
          title: 'Estas Seguro de eliminar?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: "#666",
          confirmButtonColor: "#13a538",
          confirmButtonText: 'Si',
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            deleteItem(data)
          }
        })        
    }    
    
    const deleteItem = async(vdata) =>{
      let modaLoading;
      modaLoading = 
        window.Swal.fire({
          imageUrl: window.path_service+'/images/loading.png',
          title: 'Cargando datos...',
          showConfirmButton: false,
          customClass: 'ms-loading-modal',
            allowOutsideClick: false
        }); 
        

        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

          let apiRequest = window.path_service+"/products/delete";
          const response = await fetch(apiRequest,{method:'POST',body:  JSON.stringify({id:vdata}) , headers: myHeaders})
          const data = await response.json()


          if ( data.hasOwnProperty("status") && data.status ) {
            window.Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 3500
                });                   
            setShowlist(showlist+1)
          }else{
              window.Swal.fire({
                    icon: 'error',
                    title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
                    showConfirmButton: false,
                    timer: 2500
              });           
          }
        }
        
        modaLoading.close();        
    }    

    return (
	    <div className="page-content-detailt">

      <FilterProduct showlist ={showlist} handleProducts={handleProducts}/>

        <div className="list-products">
          <div className="wrap-info-filter">
            
            <div className="info-left">
              <h5>Mis Productos</h5>          
            </div>
          </div>

          <table className="table" id="myFilters">
             <thead>
                <tr>
                   <th className="ms-item-1">
                   </th>
                   <th className="ms-item-11">SKU</th>
                   <th className="ms-item-5">Creado</th>
                   <th className="ms-item-7">Venta</th>
                   <th className="ms-item-9">Editar</th>
                   <th className="ms-item-10">Eliminar</th>
                </tr>
             </thead>
             <tbody className="js-list-building">
                {product.map(
                  person => 

                <tr id={"item_"+person.id}>
                   <td className="filterMore ms-item-1 ico-my-buildings ms-icon no-border -chk-active">
                          <a onClick={() => editItem(person.id)} className="js-get-building-detail ms-sc-title">{person.name}</a><button className="ms-show-more-info">Show more</button>
                   </td>
                   <td className="ms-item-11 ms-full"><span>SKU</span>{person.sku}</td>
                   <td className="ms-item-2 ms-md"><span>CREADO</span>{person.created_on}</td>
                   <td className="ms-item-5 ms-md"><span>PRECIO</span>{window.currencyFormat(person.price,person.moneda)}</td>
                   <td className="ms-item-9"><span>Edit</span><a title="Edit" className="ms-edit-mx" onClick={() => editItem(person.id)}><span>Edit</span></a></td>
                   <td className="ms-item-10 no-border"><span>Delete</span><a href="#" className="ms-delete-mx flex-filter-confirm-delete" data-toggle="modal" data-target="#modal-delete" onClick={() => confirmdeleteItem(person.id)}><span>Delete</span></a></td>
                </tr>

                )}
             </tbody>
          </table>
        </div>
        

	    </div>
    )
}

export default PageMisProductos;