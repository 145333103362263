import Category from '../Form/Category'

const PageCategoryEdit = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
  
  return (
    <div id="ms-profile">

          <div className="ms-section">
            <div className="ms-tab-profile ms-payment">
              <div className="ms-lateral-b">
                <div className="ms-body-menu-profile">
                  <div className="ms-info-menu-selected">
                    <div className="ms-title">Edición de Categoria</div>
                    <Category type_page="edit" user={user} />
                  </div>            
                </div>
            </div>
              
            </div>
            </div>

          </div>
  )

}

export default PageCategoryEdit;