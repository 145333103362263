import { useState,useEffect } from 'react'
import MapaLeaflet from '../MapaLeaflet'
import InputFile from '../Input/InputFile'

const PagePedidoView = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	
	const [info, setInfo ] = useState(null)
	const [constanciatransferencia, setConstanciatransferencia] = useState("")   
	const [constanciaPago, setConstanciaPago] = useState("")   

	let xhrconstanciapago = null;
	let xhrconstanciaEnvio = null;
	let modaLoading;
	let xhrIntervalConstancia = null;

	console.log(info)

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}else{

				if ( window.detailpath.length > 0 ) {
					getApiData();
				}

			}
	}, []);


	const fcData = (method) =>{
        window.Swal.fire({
          title: 'Estas Seguro de Guardar los cambios?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: "#666",
          confirmButtonColor: "#13a538",
          confirmButtonText: 'Si',
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            //deleteItem(data)
            save()
          }
        }) 
	}	

	const getApiData = async () => {
			if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

				const response = await fetch(window.path_service+"/orders/show",{method:'POST',body: JSON.stringify({code:window.detailpath}), headers: myHeaders})
				const data = await response.json()

		       if ( data.hasOwnProperty("status") && data.status && data.hasOwnProperty("data") ) {
		       		data.data.constancia_envio = [null,"null",undefined].includes(data.data.constancia_envio) ? "" : data.data.constancia_envio;
		       		data.data.constancia_pago = [null,"null",undefined].includes(data.data.constancia_pago) ? "" : data.data.constancia_pago;
		       		setInfo(data.data)
		        }
		    }		

	};

	const handleChangeForm = (e) => {
		setInfo((state) => ({
	      ...state,
	      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
	    }))
		/*
	    */
	    //setInfo()
	}

/*
	const handleChangeFile = (e,keyname = null) => {
		if (e === null) {
			setInfo((state) => ({
		      ...state,
		      [keyname]: ""
		    }))	

		}else{
			setInfo((state) => ({
		      ...state,
		      [e.target.name]: e.target.files[0]
		    }))			
		}

	}

*/
const handleChangeFileEnvio = (e,keyname = null) => {
		if (e === null) {
			setConstanciatransferencia("")
		}else{
			setConstanciatransferencia(e.target.files[0])
		}
	}

const isValidUrl = urlString=> {
	  	var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	  return !!urlPattern.test(urlString);
}



const handleChangeFile = (e,keyname = null) => {
		if (e === null) {
			setConstanciaPago("")
		}else{
			setConstanciaPago(e.target.files[0])
		}
	}


	const save = async(item,xcard = null) =>{

	  modaLoading = 
	  window.Swal.fire({
	  		imageUrl: window.path_service+'/images/loading.png',
	  		title: 'Cargando datos...',
	      showConfirmButton: false,
	      customClass: 'ms-loading-modal',
	      allowOutsideClick: false
	  });

		if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
		        var myHeaders = new Headers();
		        myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

			const response_service = await fetch(window.path_service+"/orders/update", { method:'POST',dataType: "json",body:  
			JSON.stringify(info), headers: myHeaders })
		   	const response = await response_service.json()
		   if (response.status) {

		   	if ( typeof constanciaPago !== 'object') {
		   		window.Swal.fire({
					        icon: 'success',
					        title: response.message,
					        showConfirmButton: false,
					        timer: 3500
					   });         					
					}
					uploadFile(info.code_operacion);

		   		
		   		
		   }
      }

	}

	const uploadFile = async(idfile,typeConstancia) =>{
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

	        if (constanciaPago !== null && constanciaPago !== '' && typeof constanciaPago === 'object') {
	            var formdata = new FormData();
	            formdata.append("type", constanciaPago );
	            formdata.append("id_file", idfile );
	            formdata.append("constancia", "pago" );


	            if (xhrconstanciapago == null) {
	                xhrconstanciapago = window.$.ajax({
	                    url: window.path_service+"/orders/upload_file",
	                    type: "POST",
	                    async: true,
	                    processData: false,
	                    contentType: false,
	                    data: formdata,
	                    dataType: "json",
	                    headers : {"Authorization": "Bearer "+ localStorage.token_authlead  }, 
	                    success: function (response1) {
	                        if ( response1.hasOwnProperty("status") ) {
	                        	if (response1.status) {
	                        		setConstanciaPago(response1.path)
	                        	}
								
								window.Swal.fire({
						            icon: response1.status?'success':'error',
						            title: response1.message,
						            showConfirmButton: false,
						            timer: 3500
						        });  	                            

	                        }
	                        
	                    }
	                });
	            }
	        }
	        

	        if (constanciatransferencia !== null && constanciatransferencia !== '' && typeof constanciatransferencia === 'object') {
	            var formdata2 = new FormData();
	            formdata2.append("type", constanciatransferencia );
	            formdata2.append("id_file", idfile );
	            formdata2.append("constancia", "envio" );

	            if (xhrconstanciaEnvio == null) {
	                xhrconstanciaEnvio = window.$.ajax({
	                    url: window.path_service+"/orders/upload_file",
	                    type: "POST",
	                    async: true,
	                    processData: false,
	                    contentType: false,
	                    data: formdata2,
	                    dataType: "json",
	                    headers : {"Authorization": "Bearer "+ localStorage.token_authlead  }, 
	                    success: function (response1) {
	                        if ( response1.hasOwnProperty("status") ) {
	                        	if (response1.status) {
	                        		setConstanciatransferencia(response1.path)
	                        	}
								
	                        }
	                    }
	                });
	            }
	        }

			  xhrIntervalConstancia = setInterval(function(params) {
			  	if (
			  			(constanciaPago !== null && constanciaPago !== '' && typeof isValidUrl(constanciaPago) ) && 
			  			(constanciatransferencia !== null && constanciatransferencia !== '' && isValidUrl(constanciatransferencia) )
			  	)	{
			  		modaLoading.close();		
			  		clearInterval(xhrIntervalConstancia)
			  			window.Swal.fire({
			  				icon: 'success',
			  				title: "Se Guardo Correctamente los cambios",
						    showConfirmButton: false,
						    timer: 3500
						});  	  
			  	}
			  	

			  }, 1500);
	        
	}

console.log(constanciatransferencia,constanciaPago)

	return (
		<div className="container-interna ms-payment ms-carrito">
				{
					info !== null ?

				<div className="view-information layout">
				   <div className="headerinfo">
				   		<p className="main-title">Ver Pedido Número : {info.id}</p>
				   </div>
				   <div className="sidebar">

												        {
												        !["",undefined,"undefined","null",null].includes( info.infouser.lat )  && 
												        !["",undefined,"undefined","null",null].includes( info.infouser.lng )   
												       ?
											                 <div className="ms-item ms-full">              
											                 <label htmlFor="mapGoogle">Ubición de la dirección por mapa.</label>
											                   <MapaLeaflet
											                   handleLocation ={() =>{} }
											                   vlat={info.infouser.lat}
											                   vlng={info.infouser.lng}
											                   showbutonLookup = "no"
											                   vmarker={"yes"}
											                    />
											                    </div>

												        	:
											                 <div className="ms-item ms-full" style={{display:"none"}}>              
											                   <label htmlFor="mapGoogle">Ubición de la dirección por mapa.</label>
											                   <MapaLeaflet
											                   showbutonLookup = "no"
											                    />
											                    </div>
												        }



				   </div>
				   <div className="main">
				   	<ul className="main-info">
				   		<li className="info-item"> <i className="fa fa-user" aria-hidden="true"></i> {info.infouser.name+" "+info.infouser.lastname}</li>
				   		<li className="info-item"> <i className="fa fa-address-card-o" aria-hidden="true"></i> {info.infouser.dni}</li>
						<li className="info-item"> <i className="fa fa-phone" aria-hidden="true"></i> <a href={"tel:"+info.infouser.phone}>{info.infouser.phone}</a> </li>
						<li className="info-item"> <i className="fa fa-envelope" aria-hidden="true"></i><a href={"mailto:"+info.infouser.email}>{info.infouser.email}</a></li>
						<li className="info-item"> <i className="fa fa-map-marker" aria-hidden="true"></i> {info.infouser.address}</li>
						<li className="info-item">
					   		<select
					   			value = {info.status}
					   			name="status"
					   			onChange = {handleChangeForm}
					   		>
					   			<option value="2">No (Pagado y Atendido)</option>
					   			<option value="0">Pagado, No Atendido</option>
					   			<option value="1">Pagado y Atendido</option>
					   			<option value="3">Cancelado</option>
					   		</select>
						</li>
				   	</ul>

				   </div>
				   <div className="widget">

				   			{
				   				!["",undefined,"undefined",null].includes( info.constancia_pago )  ?

							   		<div className="get-currency">
						   				
						   				<InputFile
						   					label = {"Cliente Pago "+ ( info.productos.rate.label === "venta" ? window.currencyFormat(info.productos.envio,1) : window.currencyFormat(info.productos.envio,2) ) }
						   					validated="yes"
											name="constanciaPago"
											valobj = {info.constancia_pago}
											changeFiles = {handleChangeFile}
										/>				   				
				   						
				   					</div>


				   				:
				   				<InputFile
				   					label = {"Cliente Pago "+ ( info.productos.rate.label === "venta" ? window.currencyFormat(info.productos.envio,1) : window.currencyFormat(info.productos.envio,2) ) }
				   					validated="yes"
									name="constanciaPago"
									valobj = {constanciaPago}
									changeFiles = {handleChangeFile}
								/>				   				
				   			}


					</div>
				   <div className="statistics">
				   			{
				   				!["",undefined,"undefined",null].includes( info.constancia_envio )  ?

							   		<div className="get-currency">
						   				
						   				<InputFile
						   					label = {"Transferimos "+ (info.productos.rate.label === "venta" ? window.currencyFormat(info.productos.recibo,2) : window.currencyFormat(info.productos.recibo,1) ) }
						   					validated="yes"
											name="constanciatransferencia"
											valobj = {info.constancia_envio}
											changeFiles = {handleChangeFileEnvio}
										/>				   				
				   						
				   					</div>


				   				:
				   				<InputFile
				   					label = {"Transferimos "+ (info.productos.rate.label === "venta" ? window.currencyFormat(info.productos.recibo,2) : window.currencyFormat(info.productos.recibo,1) ) }
				   					validated="yes"
									name="constanciatransferencia"
									valobj = {constanciatransferencia}
									changeFiles = {handleChangeFileEnvio}
								/>				   				
				   			}

				   		
				   </div>
				   <div className="footer">
				   		<div className="wrap-btn">
					   		<button onClick={() => fcData("save")  } className="ms-btn green js-btn-next-payment">Actualizar</button>
					   		<button onClick={() => fcData("save_boleta")  } className="ms-btn green js-btn-next-payment">Generar Boleta</button>
				   		</div>
				   </div>
				</div>
				   :
				   null
				}
		</div>
	)

}

export default PagePedidoView;