import { useState,useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import FilterCategory from '../Filtros/FilterCategory'


const PageCategory = ({user,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	const [category,setCategory] = useState([])
  const [showlist,setShowlist] = useState(0)

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}
	}, []);


    const handleCategory = (data) =>{
        setCategory(data)
    }

    const editItem = (data) =>{
        window.location.href = "/category/"+data;
    }

    const confirmdeleteItem = (data) =>{
        window.Swal.fire({
          title: 'Estas Seguro de eliminar?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: "#666",
          confirmButtonColor: "#13a538",
          confirmButtonText: 'Si',
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            deleteItem(data)
          }
        })        
    }    

    const deleteItem = async(vdata) =>{
      let modaLoading;
      modaLoading = 
        window.Swal.fire({
          imageUrl: window.path_service+'/images/loading.png',
          title: 'Cargando datos...',
          showConfirmButton: false,
          customClass: 'ms-loading-modal',
            allowOutsideClick: false
        }); 
        

        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

          let apiRequest = window.path_service+"/category/delete";
          const response = await fetch(apiRequest,{method:'POST',body:  JSON.stringify({id:vdata}) , headers: myHeaders})
          const data = await response.json()


          if ( data.hasOwnProperty("status") && data.status ) {
            window.Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 3500
                });                   
            setShowlist(showlist+1)
          }else{
              window.Swal.fire({
                    icon: 'error',
                    title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
                    showConfirmButton: false,
                    timer: 2500
              });           
          }
        }
        
        modaLoading.close();        
    }    

    return (
	    <div className="page-content-detailt">

      <FilterCategory showlist ={showlist} handleCategory={handleCategory} />

        <div className="list-products">
          <h5>Mis Categorias</h5>
          <table className="table" id="myFilters">
             <thead>
                <tr>
                   <th className="ms-item-1">
                      <input type="checkbox" id="checkall" className="crm-lead-row" />
                      <label htmlFor="checkall">Seleccionar Todo</label>
                   </th>
                   <th className="ms-item-11">link</th>
                   <th className="ms-item-2">status</th>
                   <th className="ms-item-2">Relacionado</th>
                   <th className="ms-item-9">Editar</th>
                   <th className="ms-item-10">Eliminar</th>
                </tr>
             </thead>
             <tbody className="js-list-building">
                {category.map(
                  person => 
                      <tr id={"item_"+person.id}>
                        <td className="filterMore ms-item-1 ico-my-buildings ms-icon no-border -chk-active">
                          <a onClick={() => editItem(person.id)} className="ms-sc-title">{person.name}</a><button className="ms-show-more-info">Show more</button>
                          <div className="ms-chk">
                          <input type="checkbox" id={"chk_"+person.id} name="item" className="crm-lead-row" /><label for={"chk_"+person.id}></label></div>
                </td>
                <td className="ms-item-11 ms-full"><span>LINK</span>{person.slug}</td>
                <td className="ms-item-2 ms-md"><span>STATUS</span>{
                    person.status === 0 ?
                    "INACTIVO": "ACTIVO"
                   }
                   </td>
                <td className="ms-item-2 ms-md"><span>RELACONADO</span>{person.maincat_name}</td>
                <td className="ms-item-9"><span>Edit</span><a title="Edit" className="ms-edit-mx" onClick={() => editItem(person.id)}><span>Edit</span></a></td>
                <td className="ms-item-10 no-border"><span>Delete</span><a href="#" className="ms-delete-mx flex-filter-confirm-delete" data-toggle="modal" data-target="#modal-delete" onClick={() => confirmdeleteItem(person.id)}><span>Delete</span></a></td>

              </tr>

                )}
             </tbody>
          </table>
        </div>
        

	    </div>
    )
}

export default PageCategory;