import { useState,useEffect } from 'react'
import FilterPedidos from '../Filtros/FilterPedidos'

const PagePedido = ({user,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
	const [showlist,setShowlist] = useState(0)
	const [team, setTeam ] = useState([])

    const handleTeam = (data) =>{
        setTeam(data)
    }

    const editItem = (data) =>{
        return "/orders/"+data;
    }

    const confirmdeleteItem = (data) =>{
        window.Swal.fire({
          title: 'Estas Seguro de eliminar?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: "#666",
          confirmButtonColor: "#13a538",
          confirmButtonText: 'Si',
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            deleteItem(data)
          }
        })        
    }    
    
    const deleteItem = async(vdata) =>{
      let modaLoading;
      modaLoading = 
        window.Swal.fire({
          imageUrl: window.path_service+'/images/loading.png',
          title: 'Cargando datos...',
          showConfirmButton: false,
          customClass: 'ms-loading-modal',
            allowOutsideClick: false
        }); 
        

        if ( ![undefined,"undefined",""].includes(localStorage.token_authlead) ) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+ localStorage.token_authlead );

          let apiRequest = window.path_service+"/orders/delete";
          const response = await fetch(apiRequest,{method:'POST',body:  JSON.stringify({id:vdata}) , headers: myHeaders})
          const data = await response.json()


          if ( data.hasOwnProperty("status") && data.status ) {
            window.Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 3500
                });                   
            setShowlist(showlist+1)
          }else{
              window.Swal.fire({
                    icon: 'error',
                    title: data.message !== "" ? data.message : "Surgio un error inesperado, intentalo nuevamente",
                    showConfirmButton: false,
                    timer: 2500
              });           
          }
        }
        
        modaLoading.close();        
    } 

	useEffect(() => {

			if ( 
				["",undefined,"undefined"].includes( localStorage.token_authlead )
				) {
				window.location.href = "/login";
			}
	}, []);

    return (
	    <div className="page-content-detailt">

       <FilterPedidos showlist ={showlist} handleTeam={handleTeam}/>

        <div className="list-products">
          <h5>Mis Pedidos</h5>
          <table className="table" id="myFilters">
             <thead>
					      <tr>
					         <th className="ms-item-1">
					         </th>
					         <th className="ms-item-11">DNI</th>
					         <th className="ms-item-2">FECHA PEDIDO</th>
					         <th className="ms-item-2">FECHA DESPACHO</th>
					         <th className="ms-item-5">TELEFONO</th>
					         <th className="ms-item-6">CORREO</th>
					         <th className="ms-item-7">DIRECCIÓN</th>
					         <th className="ms-item-7">PEDIDO PENDIENTE</th>
					         <th className="ms-item-7">TOTAL RECIBIO</th>
					         <th className="ms-item-7">TOTAL PAGO</th>
					         <th className="ms-item-9">Editar</th>
					         <th className="ms-item-10">Eliminar</th>
					      </tr>

             </thead>
             <tbody className="js-list-team">
					{team.map(
						person => 
                <tr key={person.id} id={"item_"+person.id}>
	                <td className="filterMore ms-item-1 ico-my-buildings ms-icon no-border -chk-active">
	                {
	                	person.role ==="superadmin" ?
	                		<a className="ms-sc-title">{person.name+" "+person.lastname}</a>
	                	:
	                		<a href={editItem(person.code_operacion)} className="ms-sc-title">{person.name+" "+person.lastname}</a>
	                }
	                
					</td>
					<td className="ms-item-11 ms-full"><span>DNI</span>{person.dni}</td>
 					<td className="ms-item-2 ms-md"><span>REGISTRADO</span>{person.created_on}</td>
					<td className="ms-item-5 ms-md"><span>FECHA DESPACHO</span>{person.datos_envio.fecha}</td>
					<td className="ms-item-5 ms-md"><span>TELEFONO</span>{person.phone}</td>
					<td className="ms-item-6 ms-md"><span>CORREO</span>{person.email}</td>
					<td className="ms-item-7 ms-md"><span>DIRECCIÓN</span>{
						person.infouser.hasOwnProperty("address") && ![null,undefined,"undefined",""].includes(person.infouser.address) ?
							person.infouser.address
						:
							null
					}</td>
					<td className="ms-item-7 ms-md"><span>PEDIDO PENDIENTE</span>{person.status === 0 ? "SI" : "NO" }</td>
					<td className="ms-item-7 ms-md"><span>TOTAL PROODUCTOS</span>{person.productos.rate.label === "venta" ? window.currencyFormat(person.productos.recibo,2) : window.currencyFormat(person.productos.recibo,1) }</td>
					<td className="ms-item-7 ms-md"><span>PEDIDO CANCELADOS</span>

					{person.productos.rate.label === "venta" ? window.currencyFormat(person.productos.envio,1) : window.currencyFormat(person.productos.envio,2) }</td>
					<td className="ms-item-9"><span>Edit</span>
					
					{
						person.role ==="superadmin" ?
						null
						:
						<a href={editItem(person.code_operacion)} title="Edit" className="ms-edit-mx"><span>Edit</span></a>
					}					
					</td>
					<td className="ms-item-10 no-border"><span>Delete</span>
					{
						person.role ==="superadmin" ?
							null
						:
							<a href="#" onClick={() => confirmdeleteItem(person.id)} className="ms-delete-mx flex-filter-confirm-delete" data-toggle="modal" data-target="#modal-delete"><span>Delete</span></a>
					}
					</td>
				</tr>

					)}
             </tbody>
          </table>
        </div>
        

	    </div>
    )
}

export default PagePedido;