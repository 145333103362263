import { useEffect } from 'react'
import FormRegister from "./FormRegister"
import Header from './Header'
import Footer from './Footer'
//import PhoneInput from 'react-phone-input-2'

const PageRegistro = ({user,validateproductos,itemshopping,resumen,removeProduct,subTotal,miproductos,expressActive,changeHasExpress,changeMethodShopping,methodShopping,loadMenu,loadTransversal,transversal,menus,myLazyLoad,myListCart,handleMiproductos,handleSubTotal}) =>{
  useEffect(() => {
        localStorage.clear()
      window.$("body").addClass("page-login")
  }, []);

  return(

<div className="wrapper">
        <div className="logo">
            <img src="-png-logo-0.png" alt="" />
        </div>
        <div className="text-center mt-4 name">
            REGISTRACIÓN
        </div>
        <FormRegister />

        <div className="text-center fs-6">
            <a href="/login">Iniciar sesión?</a>
        </div>
    </div>

  )

}

export default PageRegistro;